import React from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Area from "./area/Area";
import Bonus from "./bonus/Bonus";
import Positions from "./position/Positions";
import Users from "./users/Users";

export default function Admin() {
  const dispatch = useAppDispatch();
  const { adminComponent }: any = useAppSelector((state) => state.operatives);

  switch (adminComponent) {
    case "users":
      return <Users />;
    case "bonus":
      return <Bonus />;
    case "position":
      return <Positions />;
    case "area":
      return <Area />;
    default:
      return <Users />;
  }
}
