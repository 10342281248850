import React, { useState, useEffect } from "react";
import { createWell, updateWell } from "../../../graphql/mutations";
import { getWell, listJobNumbers } from "../../../graphql/queries";
import { countWell, wellIdOfEdit } from "../../../redux/features/operative";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getById,
  globalAlert,
  mutationQuery,
  queryConsult,
} from "../../../utils/commonFuntions/commonFuntions";
import { validateWeelName } from "../../../utils/specifictQueries";
import styles from "../../../styles/common.module.css";

export default function AddUpdateWells() {
  const dispatch = useAppDispatch();
  const { newWell, wellIdEdit }: any = useAppSelector(
    (state) => state.operatives
  );
  const [jobName, setJobName] = useState("Job number");
  const [dataWellForm, setDataWellForm] = useState({
    name: "",
    isUltrabasic: false,
    jobNumberWellsId: "",
  });
  const { name, isUltrabasic, jobNumberWellsId } = dataWellForm;
  const [jobNumberList, setJobNumberList] = useState([]) as [
    Array<any>,
    CallableFunction
  ];

  const addWell = async () => {
    const valName: Array<any> = await queryConsult(
      validateWeelName(name.trim())
    );
    if (valName.length > 0) {
      globalAlert("error", "Pozo", "Este pozo ya existe.");
    } else {
      const resp: any = await mutationQuery(createWell, dataWellForm);
      if (resp.id) {
        globalAlert("success", "Pozo", "Pozo creado con éxito.");
        dispatch(countWell(newWell + 1));
        clearForm();
      }
    }
  };

  const updateWellData = async () => {
    let tempObj: any = dataWellForm;
    tempObj.id = wellIdEdit;
    const resp: any = await mutationQuery(updateWell, tempObj);
    if (resp.id) {
      globalAlert("success", "Pozo", "Pozo actualizado con éxito");
      dispatch(countWell(0));
      dispatch(wellIdOfEdit(""));
      clearForm();
    }
  };

  const clearForm = () => {
    setDataWellForm({
      ...dataWellForm,
      name: "",
      isUltrabasic: false,
      jobNumberWellsId: "",
    });
    setJobName("Job number");
    dispatch(wellIdOfEdit(""));
  };

  useEffect(() => {
    (async () => {
      const resp: Array<any> = await queryConsult(listJobNumbers);
      if (resp.length > 0) {
        resp.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          } else {
            return 0;
          }
        });
        setJobNumberList(resp);
      }
    })();
    const getWellData = async () => {
      const resp: any = await getById(getWell, wellIdEdit);
      setDataWellForm({
        ...dataWellForm,
        name: resp?.name,
        isUltrabasic: resp?.isUltrabasic,
        jobNumberWellsId: resp?.jobNumber?.id,
      });
      setJobName(resp?.jobNumber?.name);
    };
    if (wellIdEdit !== "") {
      getWellData();
    }
  }, [wellIdEdit]);

  return (
    <>
      <div className="row col-md-12 justify-content-between">
        <div className="col-6">
          <label className="form-label">Nombre del pozo</label>
          <input
            id="name"
            name="name"
            type="text"
            className="form-control"
            onChange={(e) =>
              setDataWellForm({
                ...dataWellForm,
                name: e.target.value.toUpperCase(),
              })
            }
            value={name.toUpperCase()}
          />
        </div>
        <div className="col-6">
          <label className="form-label">Job number</label>
          <div className="col-12 btn-group">
            <button
              type="button"
              className="btn btn-outline-primary dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {jobName}
            </button>
            <ul
              className={`col-md-12 dropdown-menu ${styles.dropdownContainer}`}
            >
              {jobNumberList.map((pos: any, index: number) => {
                return (
                  <li key={index} className="col-12">
                    <button
                      id="jobNumberWellsId"
                      name="jobNumberWellsId"
                      type="button"
                      className="col-12 btn btn-light"
                      onClick={() => {
                        setDataWellForm({
                          ...dataWellForm,
                          jobNumberWellsId: pos.id,
                        });
                        setJobName(pos?.name);
                      }}
                      value={pos?.name}
                    >
                      {pos?.name}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="row col-md-12 justify-content-center">
        <div className="col-12">
          <label className="form-label">¿Es ultra básico?</label>
          <div className="form-check">
            <input
              id="isUltrabasic"
              name="isUltrabasic"
              type="checkbox"
              className="form-check-input"
              checked={isUltrabasic}
              onChange={() =>
                setDataWellForm({
                  ...dataWellForm,
                  isUltrabasic: !isUltrabasic,
                })
              }
            />
            <label className="form-check-label">
              Seleccione el check si es ultra básico
            </label>
          </div>
        </div>
      </div>
      <div className="row col-12 pt-2 justify-content-around">
        <button
          type="button"
          className="col-5 btn btn-outline-danger"
          onClick={() => clearForm()}
        >
          Limpiar formulario
        </button>
        <button
          type="button"
          disabled={name == "" || jobNumberWellsId == ""}
          className={`col-5 btn btn-outline-${
            wellIdEdit ? "warning" : "success"
          }`}
          onClick={() => {
            wellIdEdit ? updateWellData() : addWell();
          }}
        >
          {wellIdEdit ? "Actualizar" : "Agregar"}
        </button>
      </div>
    </>
  );
}
