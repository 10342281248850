import axios from "axios";

const api = process.env.REACT_APP_API_URL;

export const fetchApi = async (
  endpoint: string,
  method: string,
  token?: string,
  data?: any
) => {
  const url = `${api}/${endpoint}`;
  console.log(url);

  const resp = await axios(url, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token ? token : "",
    },
    data: data,
  });
  return resp;
};

export default fetchApi;
