import "bootstrap/dist/css/bootstrap.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Admin from "./components/admin/Admin";
import Operator from "./components/operator/Operator";
import AuthComponent from "./components/AuthComponent";
import Home from "./components/Home";
import Layout from "./components/Layout";
import Login from "./components/Login";
import Operations from "./components/operations/Operations";
import Reports from "./components/operations/Reports";
import ValidateReports from "./components/operations/ValidateReports";
import JobNumber from "./components/operations/jobNumber/JobNumber";
import Wells from "./components/operations/wells/Wells";
import Nominee from './components/nominee/Nominee';
import ListReports from "./components/operations/ListReports";

function App() {
  return (
    <AuthComponent>
      <Layout>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/operator" element={<Operator />} />
          <Route path="/Operations" element={<Operations />} />
          <Route path="/Operations/JobNumber" element={<JobNumber />} />
          <Route path="/Operations/Wells" element={<Wells />} />
          <Route path="/Operations/:idUser" element={<Reports />} />
          <Route path="/Operations/report/:idUser" element={<ListReports />} />
          <Route path="/Operations/validate/:idUser" element={<ValidateReports />} />
          <Route path="/Nominee" element={<Nominee />} />
          <Route path="*" element={<Navigate replace to="/login" />} />
        </Routes>
      </Layout>
    </AuthComponent>
  );
}

export default App;
