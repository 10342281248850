import React, { useEffect, useState } from "react";
import { citiesAndDepartment } from "../../../utils/cities";
import { GENDER, RH, ROL, STATUS, TYPEDOCUMENT } from "../../../utils/enums/enum";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as queries from "../../../graphql/queries";
import {
  dateFormatMDY,
  globalAlert,
  mutationQuery,
  queryConsult,
  restDays,
} from "../../../utils/commonFuntions/commonFuntions";
import { getIdByEmail, listByDocNumber } from "../../../utils/specifictQueries";
import { createOperator, updateOperator } from "../../../graphql/mutations";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getById, currentDateFormatYMD } from '../../../utils/commonFuntions/commonFuntions';
import { changeUserShowComponent } from "../../../redux/features/operative";
import styles from "../../../styles/users.module.css";

export default function AddUpdateUsers() {
  const dispatch = useAppDispatch();
  const [spinner, setSpinner] = useState(false);
  const [updateEmail, setUpdateEmail] = useState(false);
  const [epsCode, setEpsCode] = useState("");
  const [afpCode, setAfpCode] = useState("");
  const { userIdEdit }: any = useAppSelector((state) => state.operatives);
  const [listDocPlaceIssuance, setListDocPlaceIssuance] = useState([]) as [
    Array<string>,
    CallableFunction
  ];
  const [listBirthCity, setListBirthCity] = useState([]) as [
    Array<string>,
    CallableFunction
  ];
  const [listMainDepart, setListMainDepart] = useState([]) as [
    Array<string>,
    CallableFunction
  ];
  const [listSecondaryDepart, setListSecondaryDepart] = useState([]) as [
    Array<string>,
    CallableFunction
  ];
  const typeDoc = Object.values(TYPEDOCUMENT);
  const rhList = Object.values(RH);
  const genderList = Object.values(GENDER);
  const rolList = Object.values(ROL);
  const [epsList, setEpsList] = useState([]);
  const [afpList, setAfpList] = useState([]);
  const [areasList, setAreasList] = useState([]);
  const [positionList, setPositionList] = useState([]) as [Array<any>, CallableFunction];
  const [area, setArea] = useState("Área");
  const [position, setPosition] = useState("Cargo");
  const [eps, setEps] = useState("EPS");
  const [afp, setAfp] = useState("AFP");
  const [dataUsersForm, setDataUserForm] = useState({
    docType: "Documento",
    docNumber: "",
    docDateIssuance: new Date(),
    docPlaceIssuance: "",
    docPlaceIssuanceCity: "",
    name: "",
    surname: "",
    location: "",
    reportTo: "",
    contractType: "",
    entryDate: new Date(),
    birthDate: new Date(),
    birthCity: "",
    birthDepart: "",
    rhesus: "RH",
    gender: "Género",
    title: "",
    institution: "",
    gradeDate: new Date(),
    professionalCard: "",
    epsAffiliationDate: new Date(),
    afpAffiliationDate: new Date(),
    mainAddress: "",
    mainNeighborhood: "",
    mainCity: "",
    mainDepartment: "",
    secondaryAddress: "",
    secondaryNeighborhood: "",
    secondaryCity: "",
    secondaryDepartment: "",
    mainPhone: "",
    secondaryPhone: "",
    landline: "",
    corporateEmail: "",
    personalEmail: "",
    arl: new Date(),
    cashRegisterAffiliation: new Date(),
    emergencyContactName: "",
    emergencyContactPhone: "",
    emergencyContactAddress: "",
    status: STATUS.ACTIVE,
    rol: "Seleccione el rol",
    operatorAreaId: "",
    operatorPositionId: "",
    operatorEpsId: "",
    operatorAfpId: "",
  });
  const {
    docType,
    docNumber,
    docDateIssuance,
    docPlaceIssuance,
    docPlaceIssuanceCity,
    name,
    surname,
    birthDate,
    rhesus,
    gender,
    mainAddress,
    birthDepart,
    birthCity,
    mainNeighborhood,
    mainCity,
    mainDepartment,
    secondaryAddress,
    secondaryNeighborhood,
    secondaryCity,
    secondaryDepartment,
    mainPhone,
    secondaryPhone,
    landline,
    emergencyContactName,
    emergencyContactPhone,
    emergencyContactAddress,
    epsAffiliationDate,
    afpAffiliationDate,
    arl,
    cashRegisterAffiliation,
    location,
    reportTo,
    entryDate,
    title,
    institution,
    gradeDate,
    professionalCard,
    personalEmail,
    corporateEmail,
    contractType,
    rol,
    status,
    operatorAreaId,
    operatorPositionId,
    operatorEpsId,
    operatorAfpId,
  } = dataUsersForm;

  const getDropdownData = async () => {
    setEpsList(await queryConsult(queries.listEps));
    setAfpList(await queryConsult(queries.listAfps));
    setAreasList(await queryConsult(queries.listAreas));
    const positionResponse: Array<any> = await queryConsult(queries.listPositions);
    positionResponse.sort(function(a: any, b: any) {
      const nombreCompletoA = `${a?.name}`;
      const nombreCompletoB = `${b?.name}`;
      return nombreCompletoA.localeCompare(nombreCompletoB, "es", { sensitivity: "accent" });
    });
    setPositionList(positionResponse);
  };

  const setDataUser = ({ target }: any) => {
    setDataUserForm({
      ...dataUsersForm,
      [target.name]: target.value,
    });
  };

  const docPlaceIssuancePosition = (department: any) => {
    setListDocPlaceIssuance([]);
    const pos = citiesAndDepartment
      .map((e) => e.department)
      .indexOf(department.target.value);
    setListDocPlaceIssuance(citiesAndDepartment[pos]?.cities);
  };

  const birthCityPosition = (department: any) => {
    setListBirthCity([]);
    const pos = citiesAndDepartment
      .map((e) => e.department)
      .indexOf(department.target.value);
    setListBirthCity(citiesAndDepartment[pos]?.cities);
  };

  const mainCityPosition = (department: any) => {
    setListMainDepart([]);
    const pos = citiesAndDepartment
      .map((e) => e.department)
      .indexOf(department.target.value);
    setListMainDepart(citiesAndDepartment[pos]?.cities);
  };

  const secondaryCityPosition = (department: any) => {
    setListSecondaryDepart([]);
    const pos = citiesAndDepartment
      .map((e) => e.department)
      .indexOf(department.target.value);
    setListSecondaryDepart(citiesAndDepartment[pos]?.cities);
  };

  const adduser = async () => {
    setSpinner(true);
    const resp: Array<any> = await queryConsult(getIdByEmail(corporateEmail));
    if (resp.length > 0) {
      globalAlert(
        "error",
        "Email corporativo",
        "El email corporativo ya existe, por favor valídelo."
      );
      setSpinner(false);
    } else {
      let temporalObj: any = dataUsersForm;
      if ((await valData(temporalObj)) === false) {
        Object.keys(temporalObj).forEach((key) => {
          if (temporalObj[key] === "") {
            delete temporalObj[key];
          }
        });
        const resp: any = await queryConsult(listByDocNumber(docNumber));
        if (resp?.length > 0) {
          globalAlert(
            "error",
            "Oops...",
            "Este numero de cedula ya se encuentra registrado!"
          );
          setSpinner(false);
        } else {
          temporalObj.docDateIssuance = temporalObj.docDateIssuance
            .toISOString()
            .split("T")[0];
          temporalObj.birthDate = temporalObj.birthDate
            .toISOString()
            .split("T")[0];
          temporalObj.epsAffiliationDate = temporalObj.epsAffiliationDate
            .toISOString()
            .split("T")[0];
          temporalObj.afpAffiliationDate = temporalObj.afpAffiliationDate
            .toISOString()
            .split("T")[0];
          temporalObj.cashRegisterAffiliation =
            temporalObj.cashRegisterAffiliation.toISOString().split("T")[0];
          temporalObj.entryDate = temporalObj.entryDate
            .toISOString()
            .split("T")[0];
          temporalObj.gradeDate = temporalObj.gradeDate
            .toISOString()
            .split("T")[0];
          temporalObj.arl = temporalObj.arl.toISOString().split("T")[0];
          const saveUser: any = await mutationQuery(
            createOperator,
            temporalObj
          );
          if (saveUser.id && saveUser.id !== "") {
            setSpinner(false);
            globalAlert(
              "success",
              "Nuevo usuario",
              "Nuevo usuario insertado con éxito."
            );
            dispatch(changeUserShowComponent(true));
          }
        }
      }
      setSpinner(false);
    }
  };

  const updateUser = async () => {
    let temporalObj: any = dataUsersForm;
    Object.keys(temporalObj).forEach((key) => {
      if (temporalObj[key] === "") {
        delete temporalObj[key];
      }
    });
    if ((await valData(temporalObj)) === false) {
      temporalObj.id = userIdEdit;
      temporalObj.docDateIssuance = currentDateFormatYMD(temporalObj.docDateIssuance);
      temporalObj.birthDate = temporalObj.birthDate.toISOString().split("T")[0];
      temporalObj.epsAffiliationDate = temporalObj.epsAffiliationDate
        .toISOString()
        .split("T")[0];
      temporalObj.afpAffiliationDate = temporalObj.afpAffiliationDate
        .toISOString()
        .split("T")[0];
      temporalObj.cashRegisterAffiliation = temporalObj.cashRegisterAffiliation
        .toISOString()
        .split("T")[0];
      temporalObj.entryDate = temporalObj.entryDate.toISOString().split("T")[0];
      temporalObj.gradeDate = temporalObj.gradeDate.toISOString().split("T")[0];
      temporalObj.arl = temporalObj.arl.toISOString().split("T")[0];
      const resp: any = await mutationQuery(updateOperator, temporalObj);
      if (resp.id && resp.id !== "") {
        globalAlert(
          "success",
          "Usuario actualizado",
          "El usuario fue actualizado con éxito."
        );
        dispatch(changeUserShowComponent(true));
      }
    }
  };

  const valData = async (temporalObj: any) => {
    if (temporalObj?.docType === "Documento") {
      globalAlert("error", "Tipo de documento", "Seleccione una opción");
      return true;
    } else if (temporalObj?.docNumber === "") {
      globalAlert("error", "N° de documento", "El campo no puede estar vacío");
      return true;
    } else if (temporalObj?.rhesus === "RH") {
      globalAlert("error", "RH", "Por favor escoja una opción");
      return true;
    } else if (temporalObj?.gender === "Género") {
      globalAlert("error", "Género", "Por favor escoja una opción");
      return true;
    } else if (temporalObj?.docPlaceIssuance === "") {
      globalAlert(
        "error",
        "Departamento de expedición",
        "Por favor seleccione un departamento de expedición"
      );
      return true;
    } else if (temporalObj?.docPlaceIssuanceCity === "") {
      globalAlert(
        "error",
        "Ciudad de expedición",
        "Por favor seleccione una ciudad de expedición"
      );
      return true;
    } else if (temporalObj?.name === "") {
      globalAlert("error", "Nombre", "El nombre no puede estar vacío");
      return true;
    } else if (temporalObj?.surname === "") {
      globalAlert("error", "Apellidos", "Los apellidos no pueden estar vacios");
      return true;
    } else if (restDays(temporalObj?.birthDate)) {
      globalAlert(
        "error",
        "Fecha de nacimiento",
        "Por favor valide la fecha de nacimiento"
      );
      return true;
    } else if (temporalObj?.birthDepart === "") {
      globalAlert(
        "error",
        "Departamento de nacimiento",
        "Este campo no puede ir vacío"
      );
      return true;
    } else if (temporalObj?.birthCity === "") {
      globalAlert(
        "error",
        "Ciudad de nacimiento",
        "Este campo no puede estar vacío"
      );
      return true;
    } else if (temporalObj?.mainAddress === "") {
      globalAlert(
        "error",
        "Dirección principal",
        "Este campo no puede ir vacío"
      );
      return true;
    } else if (temporalObj?.mainNeighborhood === "") {
      globalAlert("error", "Barrio principal", "Este campo no puede ir vacío");
      return true;
    } else if (temporalObj?.mainDepartment === "") {
      globalAlert(
        "error",
        "Departamento principal",
        "Este campo no puede ir vacío"
      );
      return true;
    } else if (temporalObj?.mainCity === "") {
      globalAlert("error", "Ciudad principal", "Este campo no puede ir vacío");
      return true;
    } else if (temporalObj?.mainPhone === "") {
      globalAlert("error", "Celular principal", "Este campo no puede ir vacío");
      return true;
    } else if (temporalObj?.landline === "") {
      globalAlert(
        "error",
        "Teléfono principal",
        "Este campo no puede ir vacío"
      );
      return true;
    } else if (temporalObj?.emergencyContactName === "") {
      globalAlert(
        "error",
        "Nombre del contacto de emergencia",
        "Este campo no puede ir vacío"
      );
      return true;
    } else if (temporalObj?.emergencyContactPhone === "") {
      globalAlert(
        "error",
        "Telefono del contacto de emergencia",
        "Este campo no puede ir vacío"
      );
      return true;
    } else if (temporalObj?.emergencyContactAddress === "") {
      globalAlert(
        "error",
        "Dirección del contacto de emergencia",
        "Este campo no puede ir vacío"
      );
      return true;
    } else if (temporalObj?.title === "") {
      globalAlert("error", "Titulo", "Este campo no puede ir vacío");
      return true;
    } else if (temporalObj?.institution === "") {
      globalAlert("error", "Institución", "Este campo no puede ir vacio");
      return true;
    } else if (temporalObj?.professionalCard === "") {
      globalAlert(
        "error",
        "Tarjeta profesional",
        "Este campo no puede ir vacio"
      );
      return true;
    } else if (temporalObj?.eps === "EPS") {
      globalAlert("error", "EPS", "Seleccione una EPS");
      return true;
    } else if (temporalObj?.afp === "AFP") {
      globalAlert("error", "AFP", "Seleccione una AFP");
      return true;
    } else if (temporalObj?.contractType === "") {
      globalAlert(
        "error",
        "Tipo de contrato",
        "El tipo de contrato no puede estar vacío"
      );
      return true;
    } else if (temporalObj?.area === "Área") {
      globalAlert("error", "Área", "Seleccione un Área");
      return true;
    } else if (temporalObj?.operatorPositionId === "") {
      globalAlert("error", "Cargo", "Seleccione un cargo");
      return true;
    } else if (temporalObj?.location === "") {
      globalAlert("error", "Ubicación", "La ubicación no puede estar vacia");
      return true;
    } else if (temporalObj?.reportTo === "") {
      globalAlert(
        "error",
        "A quien reporta",
        "No puede estar vacio este campo"
      );
      return true;
    } else if (temporalObj?.corporateEmail === "") {
      globalAlert("error", "Email corporativo", "Este campo no puede ir vacío");
      return true;
    } else if (temporalObj?.rol === "Seleccione el rol") {
      globalAlert("error", "Rol", "Seleccione un rol");
      return true;
    } else if (
      temporalObj?.personalEmail &&
      temporalObj?.personalEmail !== ""
    ) {
      try {
        let indices = temporalObj?.personalEmail.split("@");
        if (indices.length > 2) {
          globalAlert(
            "error",
            "Correo personal",
            "Solo puede ingresar un correo"
          );
          return true;
        } else if (temporalObj?.personalEmail.includes("/")) {
          let outString = temporalObj?.personalEmail.replace(
            /[`~!#$%^&*()|+\=?;:'\{\}\[\]\\\/]/gi,
            ""
          );
          setDataUserForm({ ...dataUsersForm, personalEmail: outString });
          globalAlert(
            "error",
            "Correo personal",
            "Se eliminan los caracteres especiales, valide el correo"
          );
          return true;
        }
        return false;
      } catch (error) {
        console.error(error);
      }
    } else return false;
  };

  useEffect(() => {
    let departments: any = [];
    citiesAndDepartment.map((pos: any) => {
      departments.push(pos.department);
    });
    const getAllUsers = async () => {
      const resp = await getById(queries.getOperator, userIdEdit);
      if (resp !== undefined) {
        setDataUserForm({
          ...dataUsersForm,
          docType: resp?.docType,
          docNumber: resp?.docNumber,
          docDateIssuance: new Date(dateFormatMDY(resp?.docDateIssuance)),
          docPlaceIssuance: resp?.docPlaceIssuance,
          docPlaceIssuanceCity: resp?.docPlaceIssuanceCity,
          name: resp?.name,
          surname: resp?.surname,
          location: resp?.location,
          reportTo: resp?.reportTo,
          contractType: resp?.contractType,
          entryDate: new Date(dateFormatMDY(resp?.entryDate)),
          birthDate: new Date(dateFormatMDY(resp?.birthDate)),
          birthCity: resp?.birthCity,
          birthDepart: resp?.birthDepart,
          rhesus: resp?.rhesus,
          gender: resp?.gender,
          title: resp?.title,
          institution: resp?.institution,
          gradeDate: new Date(dateFormatMDY(resp?.gradeDate)),
          professionalCard: resp?.professionalCard,
          epsAffiliationDate: new Date(dateFormatMDY(resp?.epsAffiliationDate)),
          afpAffiliationDate: new Date(dateFormatMDY(resp?.afpAffiliationDate)),
          mainAddress: resp?.mainAddress,
          mainNeighborhood: resp?.mainNeighborhood,
          mainCity: resp?.mainCity,
          mainDepartment: resp?.mainDepartment,
          secondaryAddress: resp?.secondaryAddress,
          secondaryNeighborhood: resp?.secondaryNeighborhood,
          secondaryCity: resp?.secondaryCity,
          secondaryDepartment: resp?.secondaryDepartment,
          mainPhone: resp?.mainPhone,
          secondaryPhone: resp?.secondaryPhone,
          landline: resp?.landline,
          corporateEmail: resp?.corporateEmail,
          personalEmail: resp?.personalEmail,
          arl: new Date(dateFormatMDY(resp?.arl)),
          cashRegisterAffiliation: new Date(dateFormatMDY(resp?.cashRegisterAffiliation)),
          emergencyContactName: resp?.emergencyContactName,
          emergencyContactPhone: resp?.emergencyContactPhone,
          emergencyContactAddress: resp?.emergencyContactAddress,
          status: resp?.status,
          operatorAreaId: resp?.operatorAreaId,
          operatorPositionId: resp?.operatorPositionId,
          operatorEpsId: resp?.operatorEpsId,
          operatorAfpId: resp?.operatorAfpId,
          rol: resp?.rol,
        });
        setUpdateEmail(true);
        setEps(resp?.eps?.name);
        setAfp(resp?.afp?.name);
        setArea(resp?.area?.name);
        setPosition(resp?.position?.name);
        setEpsCode(resp?.eps?.epsCode);
        setAfpCode(resp?.afp?.afpCode);
      }
    };
    if (userIdEdit !== "") {
      getAllUsers();
    }
    getDropdownData();
  }, [userIdEdit]);

  return (
    <>
      <form>
        <div className="row justify-content-center py-2">
          <hr style={{ border: "2px solid black" }} />
          <h1 className="text-center" style={{ color: "#2a3587" }}>
            Datos Básicos
          </h1>
          <div className="col-md-2">
            <label className="form-label d-flex justify-content-start">
              Tipo de documento <p className="p-0 m-0 text-danger">*</p>
            </label>
            <div className="col-md-12 btn-group">
              <button
                type="button"
                className="btn btn-outline-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                value={docType}
              >
                {docType}
              </button>
              <ul className={`col-md-12 dropdown-menu ${styles.dropdownContainer}`}>
                {typeDoc.map((pos: any, typeDocIndex: number) => {
                  return (
                    <li key={typeDocIndex}>
                      <button
                        id="docType"
                        name="docType"
                        type="button"
                        className="col-12 btn btn-light"
                        onClick={(e) => setDataUser(e)}
                        value={pos}
                      >
                        {pos}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="col-md-2">
            <label className="form-label d-flex justify-content-start">
              Número de documento <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              type="number"
              className="form-control"
              id="docNumber"
              name="docNumber"
              onChange={(e) => setDataUser(e)}
              value={docNumber}
            />
          </div>
          <div className="col-md-1">
            <label className="form-label d-flex justify-content-start">
              RH <p className="p-0 m-0 text-danger">*</p>
            </label>
            <div className="col-md-12 btn-group">
              <button
                type="button"
                className="btn btn-outline-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                value={rhesus}
              >
                {rhesus}
              </button>
              <ul className={`col-md-12 dropdown-menu ${styles.dropdownContainer}`}>
                {rhList.map((pos: any, rhIndex: number) => {
                  return (
                    <li key={rhIndex}>
                      <button
                        id="rhesus"
                        name="rhesus"
                        type="button"
                        className="col-md-12 btn btn-light"
                        onClick={(e) => setDataUser(e)}
                        value={pos}
                      >
                        {pos}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="col-md-2">
            <label className="form-label d-flex justify-content-start">
              Género <p className="p-0 m-0 text-danger">*</p>
            </label>
            <div className="col-md-12 btn-group">
              <button
                type="button"
                className="btn btn-outline-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                value={gender}
              >
                {gender}
              </button>
              <ul className={`col-md-12 dropdown-menu ${styles.dropdownContainer}`}>
                {genderList.map((pos: any, genderIndex: number) => {
                  return (
                    <li key={genderIndex}>
                      <button
                        id="gender"
                        name="gender"
                        type="button"
                        className="col-md-12 btn btn-light"
                        onClick={(e) => setDataUser(e)}
                        value={pos}
                      >
                        {pos}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="row justify-content-center py-2">
          <div className="col-md-3">
            <label className="form-label d-flex justify-content-start">
              Fecha de expedición cédula{" "}
              <p className="p-0 m-0 text-danger">*</p>
            </label>
            <DatePicker
              id="docDateIssuance"
              className="form-control"
              selected={docDateIssuance}
              dateFormat="dd/MM/yyyy"
              onChange={(date: Date) =>
                setDataUserForm({
                  ...dataUsersForm,
                  docDateIssuance: date,
                })
              }
            />
          </div>
          <div className="col-md-3">
            <label className="col-12 form-label d-flex justify-content-start">
              Departamento de expedición
              <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              id="docPlaceIssuance"
              name="docPlaceIssuance"
              list="departments"
              className="form-control mt-2"
              onChange={(e) => {
                setDataUser(e);
                docPlaceIssuancePosition(e);
              }}
              onFocus={(e) =>
                setDataUserForm({
                  ...dataUsersForm,
                  docPlaceIssuanceCity: "",
                })
              }
              value={docPlaceIssuance}
            />
            <datalist id="departments">
              {citiesAndDepartment?.map(
                (pos: any, docPlaceIssuanceIndex: number) => {
                  return (
                    <option
                      key={docPlaceIssuanceIndex}
                      value={pos.department}
                    />
                  );
                }
              )}
            </datalist>
          </div>
          <div className="col-md-3">
            <label className="col-12 form-label d-flex justify-content-start">
              Ciudad de expedición
              <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              id="docPlaceIssuanceCity"
              name="docPlaceIssuanceCity"
              list="listDocPlaceIssuance"
              disabled={docPlaceIssuance?.length < 4}
              className="col-12 form-control mt-2"
              onChange={(e) => {
                setDataUser(e);
              }}
              value={docPlaceIssuanceCity}
            />
            <datalist id="listDocPlaceIssuance">
              {listDocPlaceIssuance?.map(
                (pos: any, docPlaceIssuanceIndex: number) => {
                  return <option key={docPlaceIssuanceIndex} value={pos} />;
                }
              )}
            </datalist>
          </div>
        </div>
        <div className="row justify-content-center py-2">
          <div className="col-md-3">
            <label className="form-label d-flex justify-content-start">
              Nombres <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              onChange={(e) =>
                setDataUserForm({
                  ...dataUsersForm,
                  name: e.target.value.toUpperCase(),
                })
              }
              value={name.toUpperCase()}
            />
          </div>
          <div className="col-md-3">
            <label className="form-label d-flex justify-content-start">
              Apellidos <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              type="text"
              className="form-control"
              id="surname"
              name="surname"
              onChange={(e) =>
                setDataUserForm({
                  ...dataUsersForm,
                  surname: e.target.value.toUpperCase(),
                })
              }
              value={surname.toUpperCase()}
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Nombre completo</label>
            <input
              type="text"
              className="form-control"
              disabled={true}
              value={`${name.toUpperCase()} ${surname.toUpperCase()}`}
            />
          </div>
        </div>
        <div className="row justify-content-between py-2">
          <div className="col-md-2">
            <label className="form-label d-flex justify-content-start">
              Fecha de nacimiento <p className="p-0 m-0 text-danger">*</p>
            </label>
            <DatePicker
              id="birthDate"
              className="form-control"
              selected={birthDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date: Date) =>
                setDataUserForm({
                  ...dataUsersForm,
                  birthDate: date,
                })
              }
            />
          </div>
          <div className="col-md-3">
            <label className="col-md-12 form-label d-flex justify-content-start">
              Departamento de nacimiento
              <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              id="birthDepart"
              name="birthDepart"
              list="citiesAndDepartment"
              className="form-control mt-2"
              onChange={(e) => {
                setDataUser(e);
                birthCityPosition(e);
              }}
              onFocus={(e) =>
                setDataUserForm({
                  ...dataUsersForm,
                  birthCity: "",
                })
              }
              value={birthDepart}
            />
            <datalist id="citiesAndDepartment">
              {citiesAndDepartment?.map(
                (pos: any, birthDepartIndex: number) => {
                  return (
                    <option key={birthDepartIndex} value={pos.department} />
                  );
                }
              )}
            </datalist>
          </div>
          <div className="col-md-3">
            <label className="col-12 form-label d-flex justify-content-start">
              Ciudad de nacimiento
              <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              id="birthCity"
              name="birthCity"
              list="listBirthCity"
              disabled={birthDepart?.length < 4}
              className="col-12 form-control mt-2"
              onChange={(e) => {
                setDataUser(e);
              }}
              value={birthCity}
            />
            <datalist id="listBirthCity">
              {listBirthCity?.map((pos: any, birthPlaceIndex: number) => {
                return <option key={birthPlaceIndex} value={pos} />;
              })}
            </datalist>
          </div>
          <div className="col-md-3">
            <label className="form-label d-flex justify-content-start">
              Dirección principal <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              id="mainAddress"
              name="mainAddress"
              type="text"
              className="form-control"
              value={mainAddress}
              onChange={(e) => {
                setDataUserForm({
                  ...dataUsersForm,
                  mainAddress: e.target.value.toUpperCase(),
                })
              }}
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-2">
            <label className="form-label d-flex justify-content-start">
              Barrio principal <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              type="text"
              className="form-control"
              id="mainNeighborhood"
              name="mainNeighborhood"
              value={mainNeighborhood}
              onChange={(e) => {
                setDataUserForm({
                  ...dataUsersForm,
                  mainNeighborhood: e.target.value.toUpperCase(),
                })
              }}
            />
          </div>
          <div className="col-md-3">
            <label className="col-12 form-label d-flex justify-content-start">
              Departamento principal
              <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              id="mainDepartment"
              name="mainDepartment"
              list="departments"
              className="form-control mt-2"
              value={mainDepartment}
              onChange={(e) => {
                setDataUser(e);
                mainCityPosition(e);
              }}
              onFocus={(e) =>
                setDataUserForm({
                  ...dataUsersForm,
                  mainCity: "",
                })
              }
            />
            <datalist id="departments">
              {citiesAndDepartment?.map(
                (pos: any, mainDepartmentIndex: number) => {
                  return (
                    <option key={mainDepartmentIndex} value={pos.department} />
                  );
                }
              )}
            </datalist>
          </div>
          <div className="col-md-3">
            <label className="col-12 form-label d-flex justify-content-start">
              Ciudad principal
              <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              id="mainCity"
              name="mainCity"
              list="listMainDepart"
              disabled={mainDepartment?.length < 4}
              className="form-control mt-2"
              onChange={(e) => {
                setDataUserForm({
                  ...dataUsersForm,
                  mainCity: e.target.value.toUpperCase(),
                })
              }}
              value={mainCity}
            />
            <datalist id="listMainDepart">
              {listMainDepart?.map((pos: any, mainCityIndex: number) => {
                return <option key={mainCityIndex} value={pos} />;
              })}
            </datalist>
          </div>
          <div className="col-md-2">
            <label className="form-label">Dirección secundaria</label>
            <input
              type="text"
              className="form-control"
              id="secondaryAddress"
              name="secondaryAddress"
              value={secondaryAddress}
              onChange={(e) => {
                setDataUserForm({
                  ...dataUsersForm,
                  secondaryAddress: e.target.value.toUpperCase(),
                })
              }}
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">Barrio secundario</label>
            <input
              type="text"
              className="form-control"
              id="secondaryNeighborhood"
              name="secondaryNeighborhood"
              value={secondaryNeighborhood}
              onChange={(e) => {
                setDataUserForm({
                  ...dataUsersForm,
                  secondaryNeighborhood: e.target.value.toUpperCase(),
                })
              }}
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-3">
            <label className="col-12 form-label">
              Departamento secundario
              <input
                id="secondaryDepartment"
                name="secondaryDepartment"
                list="departments"
                className="form-control mt-2"
                value={secondaryDepartment}
                onChange={(e) => {
                  setDataUser(e);
                  secondaryCityPosition(e);
                }}
                onFocus={(e) =>
                  setDataUserForm({
                    ...dataUsersForm,
                    secondaryCity: "",
                  })
                }
              />
            </label>
            <datalist id="departments">
              {citiesAndDepartment?.map(
                (pos: any, secondaryDepartmentIndex: number) => {
                  return (
                    <option
                      key={secondaryDepartmentIndex}
                      value={pos.department}
                    />
                  );
                }
              )}
            </datalist>
          </div>
          <div className="col-md-3">
            <label className="col-12 form-label">
              Ciudad secundaria
              <input
                id="secondaryCity"
                name="secondaryCity"
                list="listSecondaryDepart"
                disabled={secondaryDepartment?.length < 4}
                className="form-control mt-2"
                onChange={(e) => {
                  setDataUserForm({
                    ...dataUsersForm,
                    secondaryCity: e.target.value.toUpperCase(),
                  })
                }}
                value={secondaryCity}
              />
            </label>
            <datalist id="listSecondaryDepart">
              {listSecondaryDepart?.map(
                (pos: any, secondaryCityIndex: number) => {
                  return <option key={secondaryCityIndex} value={pos} />;
                }
              )}
            </datalist>
          </div>
          <div className="col-md-2">
            <label className="form-label d-flex justify-content-start">
              Celular principal <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              type="number"
              className="form-control"
              id="mainPhone"
              name="mainPhone"
              value={mainPhone}
              onChange={(e) => setDataUser(e)}
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">Celular secundario</label>
            <input
              type="number"
              className="form-control"
              id="secondaryPhone"
              name="secondaryPhone"
              value={secondaryPhone}
              onChange={(e) => setDataUser(e)}
            />
          </div>
          <div className="col-md-2">
            <label className="form-label d-flex justify-content-start">
              Teléfono fijo <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              type="number"
              className="form-control"
              id="landline"
              name="landline"
              value={landline}
              onChange={(e) => setDataUser(e)}
            />
          </div>
        </div>
        <div className="row justify-content-around">
          <div className="col-md-4">
            <label className="form-label d-flex justify-content-start">
              Nombre completo del contacto de emergencia{" "}
              <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              type="email"
              className="form-control"
              id="emergencyContactName"
              name="emergencyContactName"
              value={emergencyContactName.toUpperCase()}
              onChange={(e) => setDataUser(e)}
            />
          </div>
          <div className="col-md-3">
            <label className="form-label d-flex justify-content-start">
              Número de contacto de emergencia{" "}
              <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              type="number"
              className="form-control"
              id="emergencyContactPhone"
              name="emergencyContactPhone"
              value={emergencyContactPhone}
              onChange={(e) => setDataUser(e)}
            />
          </div>
          <div className="col-md-4">
            <label className="form-label d-flex justify-content-start">
              Dirección de contacto del contacto de emergencia{" "}
              <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              type="text"
              className="form-control"
              id="emergencyContactAddress"
              name="emergencyContactAddress"
              value={emergencyContactAddress}
              onChange={(e) => {
                setDataUserForm({
                  ...dataUsersForm,
                  emergencyContactAddress: e.target.value.toUpperCase(),
                })
              }}
            />
          </div>
        </div>
        <div className="row justify-content-between py-2">
          <div className="col-md-3">
            <label className="form-label d-flex justify-content-start">
              Titulo profesional <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              id="title"
              name="title"
              type="text"
              className="form-control"
              value={title.toUpperCase()}
              onChange={(e) => {
                setDataUserForm({
                  ...dataUsersForm,
                  title: e.target.value.toUpperCase(),
                });
              }}
            />
          </div>
          <div className="col-md-3">
            <label className="form-label d-flex justify-content-start">
              Institución <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              id="institution"
              name="institution"
              type="text"
              className="form-control"
              value={institution.toUpperCase()}
              onChange={(e) => {
                setDataUserForm({
                  ...dataUsersForm,
                  institution: e.target.value.toUpperCase(),
                });
              }}
            />
          </div>
          <div className="col-md-2">
            <label className="form-label d-flex justify-content-start">
              Fecha de grado <p className="p-0 m-0 text-danger">*</p>
            </label>
            <DatePicker
              id="gradeDate"
              className="form-control"
              selected={gradeDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date: Date) =>
                setDataUserForm({ ...dataUsersForm, gradeDate: date })
              }
            />
          </div>
          <div className="col-md-2">
            <label className="form-label d-flex justify-content-start">
              Tarjeta profesional <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              id="professionalCard"
              name="professionalCard"
              type="text"
              className="form-control"
              value={professionalCard.toUpperCase()}
              onChange={(e) => {
                setDataUserForm({
                  ...dataUsersForm,
                  professionalCard: e.target.value.toUpperCase(),
                });
              }}
            />
          </div>
          <div className="col-md-2">
            <label className="form-label">Correo personal</label>
            <input
              id="personalEmail"
              name="personalEmail"
              type="email"
              className="form-control"
              value={personalEmail}
              onChange={(e) => setDataUser(e)}
            />
          </div>
        </div>
        <div className="row justify-content-center py-2">
          <hr style={{ border: "2px solid black" }} />
          <h1 className="text-center" style={{ color: "#2a3587" }}>
            Seguridad social
          </h1>
          <div className="col-md-2">
            <label className="form-label d-flex justify-content-start">
              EPS <p className="p-0 m-0 text-danger">*</p>
            </label>
            <div className="col-md-12 btn-group">
              <button
                type="button"
                className="btn btn-outline-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                value={eps}
              >
                {eps}
              </button>
              <ul className={`col-md-12 dropdown-menu ${styles.dropdownContainer}`}>
                {epsList.map((pos: any, epsIndex: number) => {
                  return (
                    <li key={epsIndex}>
                      <button
                        id="eps"
                        name="eps"
                        type="button"
                        className="col-md-12 btn btn-light"
                        onClick={(e: any) => {
                          setEps(e.target.value);
                          setDataUserForm({
                            ...dataUsersForm,
                            operatorEpsId: pos.id,
                          });
                          setEpsCode(pos?.epsCode);
                        }}
                        value={pos.name}
                      >
                        {pos.name}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="col-md-2">
            <label className="form-label">EPS CODE</label>
            <input
              type="text"
              className="form-control"
              value={epsCode}
              disabled
            />
          </div>
          <div className="col-md-3">
            <label className="form-label d-flex justify-content-start">
              Fecha de afiliación EPS <p className="p-0 m-0 text-danger">*</p>
            </label>
            <DatePicker
              id="epsAffiliationDate"
              className="form-control"
              dateFormat="dd/MM/yyyy"
              selected={epsAffiliationDate}
              onChange={(date: Date) =>
                setDataUserForm({ ...dataUsersForm, epsAffiliationDate: date })
              }
            />
          </div>
          <div className="col-md-2">
            <label className="form-label d-flex justify-content-start">
              AFP <p className="p-0 m-0 text-danger">*</p>
            </label>
            <div className="col-md-12 btn-group">
              <button
                type="button"
                className="btn btn-outline-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                value={afp}
              >
                {afp}
              </button>
              <ul className={`col-md-12 dropdown-menu ${styles.dropdownContainer}`}>
                {afpList.map((pos: any, afpIndex: number) => {
                  return (
                    <li key={afpIndex}>
                      <button
                        id="afp"
                        name="afp"
                        type="button"
                        className="col-md-12 btn btn-light"
                        onClick={(e: any) => {
                          setAfp(e.target.value);
                          setDataUserForm({
                            ...dataUsersForm,
                            operatorAfpId: pos.id,
                          });
                          setAfpCode(pos?.afpCode);
                        }}
                        value={pos.name}
                      >
                        {pos.name}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="col-md-2">
            <label className="form-label">AFP CODE</label>
            <input
              type="text"
              className="form-control"
              value={afpCode}
              disabled
            />
          </div>
        </div>
        <div className="row justify-content-around py-2">
          <div className="col-md-3">
            <label className="form-label d-flex justify-content-start">
              Fecha de afiliación AFP <p className="p-0 m-0 text-danger">*</p>
            </label>
            <DatePicker
              id="afpAffiliationDate"
              className="form-control"
              dateFormat="dd/MM/yyyy"
              selected={afpAffiliationDate}
              onChange={(date: Date) =>
                setDataUserForm({ ...dataUsersForm, afpAffiliationDate: date })
              }
            />
          </div>
          <div className="col-md-3">
            <label className="form-label d-flex justify-content-start">
              Positiva / Arl <p className="p-0 m-0 text-danger">*</p>
            </label>
            <DatePicker
              id="arl"
              className="form-control"
              selected={arl}
              dateFormat="dd/MM/yyyy"
              onChange={(date: Date) =>
                setDataUserForm({ ...dataUsersForm, arl: date })
              }
            />
          </div>
          <div className="col-md-3">
            <label className="form-label d-flex justify-content-start">
              Fecha de afiliación caja <p className="p-0 m-0 text-danger">*</p>
            </label>
            <DatePicker
              id="cashRegisterAffiliation"
              className="form-control"
              dateFormat="dd/MM/yyyy"
              selected={cashRegisterAffiliation}
              onChange={(date: Date) =>
                setDataUserForm({
                  ...dataUsersForm,
                  cashRegisterAffiliation: date,
                })
              }
            />
          </div>
        </div>
        <div className="row justify-content-around py-2">
          <hr style={{ border: "2px solid black" }} />
          <h1 className="text-center" style={{ color: "#2a3587" }}>
            Datos de contratación
          </h1>
          <div className="col-md-2">
            <label className="form-label d-flex justify-content-start">
              Fecha de ingreso <p className="p-0 m-0 text-danger">*</p>
            </label>
            <DatePicker
              id="entryDate"
              className="form-control"
              selected={entryDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date: Date) =>
                setDataUserForm({ ...dataUsersForm, entryDate: date })
              }
            />
          </div>
          <div className="col-md-2">
            <label className="form-label d-flex justify-content-start">
              Tipo de contrato <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              id="contractType"
              name="contractType"
              type="text"
              className="form-control"
              value={contractType.toUpperCase()}
              onChange={(e) => {
                setDataUserForm({
                  ...dataUsersForm,
                  contractType: e.target.value.toUpperCase(),
                });
              }}
            />
          </div>
          <div className="col-md-3">
            <label className="form-label d-flex justify-content-start">
              Área <p className="p-0 m-0 text-danger">*</p>
            </label>
            <div className="col-md-12 btn-group">
              <button
                type="button"
                className="btn btn-outline-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                value={area}
              >
                {area}
              </button>
              <ul className={`col-md-12 dropdown-menu ${styles.dropdownContainer}`}>
                {areasList.map((pos: any, areaIndex: number) => {
                  return (
                    <li key={areaIndex}>
                      <button
                        id="area"
                        name="area"
                        type="button"
                        className="col-md-12 btn btn-light"
                        onClick={(e: any) => {
                          setArea(e.target.value);
                          setDataUserForm({
                            ...dataUsersForm,
                            operatorAreaId: pos.id,
                          });
                        }}
                        value={pos.name}
                      >
                        {pos.name}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <label className="form-label d-flex justify-content-start">
              Cargo <p className="p-0 m-0 text-danger">*</p>
            </label>
            <div className="col-md-12 btn-group">
              <button
                type="button"
                className="btn btn-outline-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                value={position}
              >
                {position}
              </button>
              <ul className={`col-md-12 dropdown-menu ${styles.dropdownContainer}`}>
                {positionList.map((pos: any, positionIndex: number) => {
                  return (
                    <li key={positionIndex}>
                      <button
                        id="position"
                        name="position"
                        type="button"
                        className="col-md-12 btn btn-light"
                        onClick={(e: any) => {
                          setPosition(e.target.value);
                          setDataUserForm({
                            ...dataUsersForm,
                            operatorPositionId: pos.id,
                          });
                        }}
                        value={pos.name}
                      >
                        {pos.name}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="row justify-content-around">
          <div className="col-md-3">
            <label className="form-label d-flex justify-content-start">
              Ubicación <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              id="location"
              name="location"
              type="text"
              className="form-control"
              value={location.toUpperCase()}
              onChange={(e) => {
                setDataUserForm({
                  ...dataUsersForm,
                  location: e.target.value.toUpperCase(),
                });
              }}
            />
          </div>
          <div className="col-md-3">
            <label className="form-label d-flex justify-content-start">
              Reporta a <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              id="reportTo"
              name="reportTo"
              type="text"
              className="form-control"
              value={reportTo.toUpperCase()}
              onChange={(e) => {
                setDataUserForm({
                  ...dataUsersForm,
                  reportTo: e.target.value.toUpperCase(),
                });
              }}
            />
          </div>
          <div className="col-md-3">
            <label className="form-label d-flex justify-content-start">
              Correo corporativo <p className="p-0 m-0 text-danger">*</p>
            </label>
            <input
              id="corporateEmail"
              name="corporateEmail"
              type="email"
              className="form-control"
              disabled={updateEmail}
              value={corporateEmail}
              onChange={(e) => setDataUser(e)}
            />
          </div>
          <div className="col-md-2">
            <label className="form-label d-flex justify-content-start">
              Rol del usuario <p className="p-0 m-0 text-danger">*</p>
            </label>
            <div className="col-md-12 btn-group">
              <button
                type="button"
                className="btn btn-outline-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                value={
                  Object.values(ROL)[
                    Object.keys(ROL).findIndex((pos: any) => pos == rol)
                  ]
                }
              >
                {
                  Object.values(ROL)[
                    Object.keys(ROL).findIndex((pos: any) => pos == rol)
                  ]
                }
              </button>
              <ul className={`col-md-12 dropdown-menu ${styles.dropdownContainer}`}>
                {rolList.map((pos: any, positionIndex: number) => {
                  return (
                    <li key={positionIndex}>
                      <button
                        id="rol"
                        name="rol"
                        type="button"
                        className="col-md-12 btn btn-light"
                        onClick={() =>
                          setDataUserForm({
                            ...dataUsersForm,
                            rol: Object.keys(ROL)[positionIndex],
                          })
                        }
                        value={pos}
                      >
                        {pos}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="row justify-content-around py-2">
          <button
            type="button"
            className="col-md-2 btn btn-outline-danger"
            onClick={() => dispatch(changeUserShowComponent(true))}
          >
            Cancelar
          </button>
          {spinner === true ? (
            <button
              type="button"
              className={`col-md-2 btn btn-outline-${
                userIdEdit !== "" ? `warning` : `success`
              }`}
            >
              <div className="spinner-border text-light" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </button>
          ) : (
            <button
              type="button"
              className={`col-md-2 btn btn-outline-${
                userIdEdit !== "" ? `warning` : `success`
              }`}
              onClick={() => {
                userIdEdit !== "" ? updateUser() : adduser();
              }}
            >
              {`${
                userIdEdit !== "" ? "Actualizar usuario" : "Agregar usuario"
              }`}
            </button>
          )}
        </div>
      </form>
    </>
  );
}
