import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import {
  confirmDelete,
  globalAlert,
  mutationQuery,
  queryConsult,
} from "../../../utils/commonFuntions/commonFuntions";
import { listWells } from "../../../graphql/queries";
import AddUpdateWells from "./AddUpdateWells";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { countWell, wellIdOfEdit } from "../../../redux/features/operative";
import { deleteWell } from "../../../graphql/mutations";
import { adminReports, dailyReports } from "../../../utils/specifictQueries";

export default function Wells() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { newWell }: any = useAppSelector((state) => state.operatives);
  const [wellsList, setWellsList] = useState([]) as [
    Array<any>,
    CallableFunction
  ];

  const listAllWells = async () => {
    const resp: Array<any> = await queryConsult(listWells);
    if (resp.length > 0) {
      resp.sort(function(a: any, b: any) {
        const nombreCompletoA = `${a?.name}`;
        const nombreCompletoB = `${b?.name}`;
        return nombreCompletoA.localeCompare(nombreCompletoB, "es", { sensitivity: "accent" });
      });
      setWellsList(resp);
      dispatch(countWell(resp.length));
    }
  };

  const deleteWells = async (id: string) => {
    const confirmation = await confirmDelete();
    if (confirmation) {
      const valAdmin: Array<any> = await queryConsult(adminReports(id));
      const valDaily: Array<any> = await queryConsult(dailyReports(id));
      if( valAdmin.length > 0 || valDaily.length > 0){
        globalAlert(
          "error",
          "Pozo",
          "El pozo no se puede eliminar ya que esta en uso."
        );
      } else {
        const resp: any = await mutationQuery(deleteWell, { id: id });
        if (resp.id && resp.id !== "") {
          globalAlert(
            "success",
            "Pozo eliminado",
            "El pozo fue eliminado con éxito."
          );
          dispatch(countWell(newWell - 1));
        }
      }
    }
  };

  useEffect(() => {
    listAllWells();
  }, [newWell]);

  return (
    <div className="container-fluid">
      <div className="row justify-content-between mt-3 mx-1">
        <div className="row col-12 justify-content-between p-2">
          <button
            type="button"
            className="col-1 d-flex justify-content-around align-items-center btn btn-primary"
            onClick={() => navigate(`/Operations`)}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            <p className="ps-md-1 p-0 m-0">Volver</p>
          </button>
        </div>
        <div className="col-md-7">
          {wellsList.length > 0 ? (
            <div className="table-responsive py-3">
              <table className="table table-hover">
                <thead>
                  <tr className="text-center table-dark">
                    <th scope="col">Nombre del pozo</th>
                    <th scope="col">Job number</th>
                    <th scope="col">Ultra básico</th>
                    <th scope="col">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {wellsList.map((pos: any, index: number) => {
                    return (
                      <tr key={index} className="text-center">
                        <th>{pos?.name}</th>
                        <th>{pos?.jobNumber?.name}</th>
                        <th>{`${
                          pos?.isUltrabasic
                            ? "Es ultra básico"
                            : "No es ultra básico"
                        }`}</th>
                        <th className="d-flex justify-content-around">
                          <button
                            type="button"
                            id={pos?.id}
                            className="col-4 btn btn-warning btn-sm"
                            onClick={() => dispatch(wellIdOfEdit(pos.id))}
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </button>
                          <button
                            type="button"
                            id={pos.id}
                            className="col-4 btn btn-danger btn-sm"
                            onClick={() => deleteWells(pos.id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </th>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <h1>No hay pozos por el momento.</h1>
          )}
        </div>
        <div className="col-md-5">
          <div>
            <AddUpdateWells />
          </div>
        </div>
      </div>
    </div>
  );
}
