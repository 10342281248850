import { Auth } from "aws-amplify";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  changeUserShowComponent,
  getComponentNameAdmin,
} from "../../redux/features/operative";
import { useAppDispatch } from "../../redux/hooks";
import styles from "../../styles/header.module.css";
import usuarios from "../../assets/Usuarios.png";
import jobnumber from "../../assets/jobnumber.png";
import cargos from "../../assets/Cargos.png";
import bonos from "../../assets/Bonos.png";
import area from "../../assets/Area.png";
import Logo from "../../assets/logopng.png";
import thIcono from "../../assets/thIcono.png";

export default function HeaderAdmin() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const SingOut = async () => {
    await Auth.signOut();
    navigate("/login");
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <nav className="navbar navbar-expand-lg bg-light p-0 m-0">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className="collapse navbar-collapse py-2"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <div>
                      <img
                        src={usuarios}
                        className={`py-1 ps-1 ${styles.icon}`}
                      />
                      <button
                        className="btn"
                        onClick={() => dispatch(getComponentNameAdmin("users"))}
                      >
                        Usuarios
                      </button>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div>
                      <img src={bonos} className={`py-1 ps-1 ${styles.icon}`} />
                      <button
                        className="btn"
                        onClick={() => {
                          dispatch(changeUserShowComponent(true));
                          dispatch(getComponentNameAdmin("bonus"));
                        }}
                      >
                        Bonos
                      </button>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div>
                      <img
                        src={cargos}
                        className={`py-1 ps-1 ${styles.icon}`}
                      />
                      <button
                        className="btn"
                        onClick={() =>
                          dispatch(getComponentNameAdmin("position"))
                        }
                      >
                        Cargos
                      </button>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div>
                      <img src={area} className={`py-1 ps-1 ${styles.icon}`} />
                      <button
                        className="btn"
                        onClick={() => dispatch(getComponentNameAdmin("area"))}
                      >
                        Área/División
                      </button>
                    </div>
                  </li>
                </ul>
                <form>
                  <button
                    className="col-12 btn btn-outline-danger"
                    type="button"
                    onClick={() => SingOut()}
                  >
                    Cerrar sesión
                  </button>
                </form>
              </div>
            </div>
          </nav>
        </div>
        <div className="row">
          <div className="row justify-content-around mt-2">
            <div className="col-md-6">
              <div className="d-flex justify-content-center">
                <h1 className={`${styles.sinidName}`}>DataBono$</h1>
                <img
                  src={Logo}
                  className={`pt-md-2 ps-3 ${styles.logoUsersSize}`}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex justify-content-center pt-2">
                <button type="button" className={`${styles.thBoton}`}>
                  Talento Humano
                </button>
                <img src={thIcono} className={`ps-3 ${styles.iconoTh}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
