import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function HeaderOperator() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState();

  const SingOut = async () => {
    await Auth.signOut();
    navigate("/login");
  };

  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        setUserName(user.attributes.email);
      }
    })();
  }, []);
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start">
            <h5 className="pt-2 fw-ligth">{userName}</h5>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-md-end">
            <button className="col-12 col-md-3 btn btn-danger" onClick={() => SingOut()}>
              Cerrar sesión
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
