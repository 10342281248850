/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPosition = /* GraphQL */ `
  query GetPosition($id: ID!) {
    getPosition(id: $id) {
      id
      name
      salary
      bonuses {
        items {
          id
          positionID
          bonusID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPositions = /* GraphQL */ `
  query ListPositions(
    $filter: ModelPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPositions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        salary
        bonuses {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBonus = /* GraphQL */ `
  query GetBonus($id: ID!) {
    getBonus(id: $id) {
      id
      name
      type
      value
      isPercentage
      isCreated
      position {
        items {
          id
          positionID
          bonusID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBonuses = /* GraphQL */ `
  query ListBonuses(
    $filter: ModelBonusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBonuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        value
        isPercentage
        isCreated
        position {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getArea = /* GraphQL */ `
  query GetArea($id: ID!) {
    getArea(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listAreas = /* GraphQL */ `
  query ListAreas(
    $filter: ModelAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAreas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEps = /* GraphQL */ `
  query GetEps($id: ID!) {
    getEps(id: $id) {
      id
      name
      epsCode
      createdAt
      updatedAt
    }
  }
`;
export const listEps = /* GraphQL */ `
  query ListEps($filter: ModelEpsFilterInput, $limit: Int, $nextToken: String) {
    listEps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        epsCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAfp = /* GraphQL */ `
  query GetAfp($id: ID!) {
    getAfp(id: $id) {
      id
      name
      afpCode
      createdAt
      updatedAt
    }
  }
`;
export const listAfps = /* GraphQL */ `
  query ListAfps(
    $filter: ModelAfpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAfps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        afpCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOperator = /* GraphQL */ `
  query GetOperator($id: ID!) {
    getOperator(id: $id) {
      id
      docType
      docNumber
      docDateIssuance
      docPlaceIssuance
      docPlaceIssuanceCity
      name
      surname
      location
      reportTo
      contractType
      entryDate
      birthDate
      birthCity
      birthDepart
      rhesus
      gender
      title
      institution
      gradeDate
      professionalCard
      epsAffiliationDate
      afpAffiliationDate
      mainAddress
      mainNeighborhood
      mainCity
      mainDepartment
      secondaryAddress
      secondaryNeighborhood
      secondaryCity
      secondaryDepartment
      mainPhone
      secondaryPhone
      landline
      corporateEmail
      personalEmail
      arl
      cashRegisterAffiliation
      emergencyContactName
      emergencyContactPhone
      emergencyContactAddress
      status
      rol
      area {
        id
        name
        createdAt
        updatedAt
      }
      position {
        id
        name
        salary
        bonuses {
          nextToken
        }
        createdAt
        updatedAt
      }
      eps {
        id
        name
        epsCode
        createdAt
        updatedAt
      }
      afp {
        id
        name
        afpCode
        createdAt
        updatedAt
      }
      dailyWorkReports {
        items {
          id
          dateReport
          duty
          entry
          out
          createdAt
          updatedAt
          operatorDailyWorkReportsId
          dailyWorkReportWellId
        }
        nextToken
      }
      adminWorkReports {
        items {
          id
          dateReport
          duty
          entry
          out
          createdAt
          updatedAt
          operatorAdminWorkReportsId
          adminReportWellId
        }
        nextToken
      }
      timeReports {
        items {
          id
          startDate
          endDate
          fieldDays
          overBonusDays
          travelDays
          createdAt
          updatedAt
          operatorTimeReportsId
          timeReportWellId
        }
        nextToken
      }
      calculatedPayroll
      createdAt
      updatedAt
      operatorAreaId
      operatorPositionId
      operatorEpsId
      operatorAfpId
    }
  }
`;
export const listOperators = /* GraphQL */ `
  query ListOperators(
    $filter: ModelOperatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOperators(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        docType
        docNumber
        docDateIssuance
        docPlaceIssuance
        docPlaceIssuanceCity
        name
        surname
        location
        reportTo
        contractType
        entryDate
        birthDate
        birthCity
        birthDepart
        rhesus
        gender
        title
        institution
        gradeDate
        professionalCard
        epsAffiliationDate
        afpAffiliationDate
        mainAddress
        mainNeighborhood
        mainCity
        mainDepartment
        secondaryAddress
        secondaryNeighborhood
        secondaryCity
        secondaryDepartment
        mainPhone
        secondaryPhone
        landline
        corporateEmail
        personalEmail
        arl
        cashRegisterAffiliation
        emergencyContactName
        emergencyContactPhone
        emergencyContactAddress
        status
        rol
        area {
          id
          name
          createdAt
          updatedAt
        }
        position {
          id
          name
          salary
          createdAt
          updatedAt
        }
        eps {
          id
          name
          epsCode
          createdAt
          updatedAt
        }
        afp {
          id
          name
          afpCode
          createdAt
          updatedAt
        }
        dailyWorkReports {
          nextToken
        }
        adminWorkReports {
          nextToken
        }
        timeReports {
          nextToken
        }
        calculatedPayroll
        createdAt
        updatedAt
        operatorAreaId
        operatorPositionId
        operatorEpsId
        operatorAfpId
      }
      nextToken
    }
  }
`;
export const getWell = /* GraphQL */ `
  query GetWell($id: ID!) {
    getWell(id: $id) {
      id
      name
      jobNumber {
        id
        name
        customerName
        wells {
          nextToken
        }
        createdAt
        updatedAt
      }
      isUltrabasic
      createdAt
      updatedAt
      jobNumberWellsId
    }
  }
`;
export const listWells = /* GraphQL */ `
  query ListWells(
    $filter: ModelWellFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWells(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        jobNumber {
          id
          name
          customerName
          createdAt
          updatedAt
        }
        isUltrabasic
        createdAt
        updatedAt
        jobNumberWellsId
      }
      nextToken
    }
  }
`;
export const getJobNumber = /* GraphQL */ `
  query GetJobNumber($id: ID!) {
    getJobNumber(id: $id) {
      id
      name
      customerName
      wells {
        items {
          id
          name
          isUltrabasic
          createdAt
          updatedAt
          jobNumberWellsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listJobNumbers = /* GraphQL */ `
  query ListJobNumbers(
    $filter: ModelJobNumberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobNumbers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        customerName
        wells {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDailyWorkReport = /* GraphQL */ `
  query GetDailyWorkReport($id: ID!) {
    getDailyWorkReport(id: $id) {
      id
      dateReport
      duty
      well {
        id
        name
        jobNumber {
          id
          name
          customerName
          createdAt
          updatedAt
        }
        isUltrabasic
        createdAt
        updatedAt
        jobNumberWellsId
      }
      operator {
        id
        docType
        docNumber
        docDateIssuance
        docPlaceIssuance
        docPlaceIssuanceCity
        name
        surname
        location
        reportTo
        contractType
        entryDate
        birthDate
        birthCity
        birthDepart
        rhesus
        gender
        title
        institution
        gradeDate
        professionalCard
        epsAffiliationDate
        afpAffiliationDate
        mainAddress
        mainNeighborhood
        mainCity
        mainDepartment
        secondaryAddress
        secondaryNeighborhood
        secondaryCity
        secondaryDepartment
        mainPhone
        secondaryPhone
        landline
        corporateEmail
        personalEmail
        arl
        cashRegisterAffiliation
        emergencyContactName
        emergencyContactPhone
        emergencyContactAddress
        status
        rol
        area {
          id
          name
          createdAt
          updatedAt
        }
        position {
          id
          name
          salary
          createdAt
          updatedAt
        }
        eps {
          id
          name
          epsCode
          createdAt
          updatedAt
        }
        afp {
          id
          name
          afpCode
          createdAt
          updatedAt
        }
        dailyWorkReports {
          nextToken
        }
        adminWorkReports {
          nextToken
        }
        timeReports {
          nextToken
        }
        calculatedPayroll
        createdAt
        updatedAt
        operatorAreaId
        operatorPositionId
        operatorEpsId
        operatorAfpId
      }
      entry
      out
      createdAt
      updatedAt
      operatorDailyWorkReportsId
      dailyWorkReportWellId
    }
  }
`;
export const listDailyWorkReports = /* GraphQL */ `
  query ListDailyWorkReports(
    $filter: ModelDailyWorkReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyWorkReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dateReport
        duty
        well {
          id
          name
          isUltrabasic
          createdAt
          updatedAt
          jobNumberWellsId
        }
        operator {
          id
          docType
          docNumber
          docDateIssuance
          docPlaceIssuance
          docPlaceIssuanceCity
          name
          surname
          location
          reportTo
          contractType
          entryDate
          birthDate
          birthCity
          birthDepart
          rhesus
          gender
          title
          institution
          gradeDate
          professionalCard
          epsAffiliationDate
          afpAffiliationDate
          mainAddress
          mainNeighborhood
          mainCity
          mainDepartment
          secondaryAddress
          secondaryNeighborhood
          secondaryCity
          secondaryDepartment
          mainPhone
          secondaryPhone
          landline
          corporateEmail
          personalEmail
          arl
          cashRegisterAffiliation
          emergencyContactName
          emergencyContactPhone
          emergencyContactAddress
          status
          rol
          calculatedPayroll
          createdAt
          updatedAt
          operatorAreaId
          operatorPositionId
          operatorEpsId
          operatorAfpId
        }
        entry
        out
        createdAt
        updatedAt
        operatorDailyWorkReportsId
        dailyWorkReportWellId
      }
      nextToken
    }
  }
`;
export const getAdminReport = /* GraphQL */ `
  query GetAdminReport($id: ID!) {
    getAdminReport(id: $id) {
      id
      dateReport
      duty
      well {
        id
        name
        jobNumber {
          id
          name
          customerName
          createdAt
          updatedAt
        }
        isUltrabasic
        createdAt
        updatedAt
        jobNumberWellsId
      }
      operator {
        id
        docType
        docNumber
        docDateIssuance
        docPlaceIssuance
        docPlaceIssuanceCity
        name
        surname
        location
        reportTo
        contractType
        entryDate
        birthDate
        birthCity
        birthDepart
        rhesus
        gender
        title
        institution
        gradeDate
        professionalCard
        epsAffiliationDate
        afpAffiliationDate
        mainAddress
        mainNeighborhood
        mainCity
        mainDepartment
        secondaryAddress
        secondaryNeighborhood
        secondaryCity
        secondaryDepartment
        mainPhone
        secondaryPhone
        landline
        corporateEmail
        personalEmail
        arl
        cashRegisterAffiliation
        emergencyContactName
        emergencyContactPhone
        emergencyContactAddress
        status
        rol
        area {
          id
          name
          createdAt
          updatedAt
        }
        position {
          id
          name
          salary
          createdAt
          updatedAt
        }
        eps {
          id
          name
          epsCode
          createdAt
          updatedAt
        }
        afp {
          id
          name
          afpCode
          createdAt
          updatedAt
        }
        dailyWorkReports {
          nextToken
        }
        adminWorkReports {
          nextToken
        }
        timeReports {
          nextToken
        }
        calculatedPayroll
        createdAt
        updatedAt
        operatorAreaId
        operatorPositionId
        operatorEpsId
        operatorAfpId
      }
      entry
      out
      createdAt
      updatedAt
      operatorAdminWorkReportsId
      adminReportWellId
    }
  }
`;
export const listAdminReports = /* GraphQL */ `
  query ListAdminReports(
    $filter: ModelAdminReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dateReport
        duty
        well {
          id
          name
          isUltrabasic
          createdAt
          updatedAt
          jobNumberWellsId
        }
        operator {
          id
          docType
          docNumber
          docDateIssuance
          docPlaceIssuance
          docPlaceIssuanceCity
          name
          surname
          location
          reportTo
          contractType
          entryDate
          birthDate
          birthCity
          birthDepart
          rhesus
          gender
          title
          institution
          gradeDate
          professionalCard
          epsAffiliationDate
          afpAffiliationDate
          mainAddress
          mainNeighborhood
          mainCity
          mainDepartment
          secondaryAddress
          secondaryNeighborhood
          secondaryCity
          secondaryDepartment
          mainPhone
          secondaryPhone
          landline
          corporateEmail
          personalEmail
          arl
          cashRegisterAffiliation
          emergencyContactName
          emergencyContactPhone
          emergencyContactAddress
          status
          rol
          calculatedPayroll
          createdAt
          updatedAt
          operatorAreaId
          operatorPositionId
          operatorEpsId
          operatorAfpId
        }
        entry
        out
        createdAt
        updatedAt
        operatorAdminWorkReportsId
        adminReportWellId
      }
      nextToken
    }
  }
`;
export const getTimeReport = /* GraphQL */ `
  query GetTimeReport($id: ID!) {
    getTimeReport(id: $id) {
      id
      operator {
        id
        docType
        docNumber
        docDateIssuance
        docPlaceIssuance
        docPlaceIssuanceCity
        name
        surname
        location
        reportTo
        contractType
        entryDate
        birthDate
        birthCity
        birthDepart
        rhesus
        gender
        title
        institution
        gradeDate
        professionalCard
        epsAffiliationDate
        afpAffiliationDate
        mainAddress
        mainNeighborhood
        mainCity
        mainDepartment
        secondaryAddress
        secondaryNeighborhood
        secondaryCity
        secondaryDepartment
        mainPhone
        secondaryPhone
        landline
        corporateEmail
        personalEmail
        arl
        cashRegisterAffiliation
        emergencyContactName
        emergencyContactPhone
        emergencyContactAddress
        status
        rol
        area {
          id
          name
          createdAt
          updatedAt
        }
        position {
          id
          name
          salary
          createdAt
          updatedAt
        }
        eps {
          id
          name
          epsCode
          createdAt
          updatedAt
        }
        afp {
          id
          name
          afpCode
          createdAt
          updatedAt
        }
        dailyWorkReports {
          nextToken
        }
        adminWorkReports {
          nextToken
        }
        timeReports {
          nextToken
        }
        calculatedPayroll
        createdAt
        updatedAt
        operatorAreaId
        operatorPositionId
        operatorEpsId
        operatorAfpId
      }
      startDate
      endDate
      well {
        id
        name
        jobNumber {
          id
          name
          customerName
          createdAt
          updatedAt
        }
        isUltrabasic
        createdAt
        updatedAt
        jobNumberWellsId
      }
      fieldDays
      overBonusDays
      travelDays
      calculatedBonus {
        id
        name
        calculated
      }
      createdAt
      updatedAt
      operatorTimeReportsId
      timeReportWellId
    }
  }
`;
export const listTimeReports = /* GraphQL */ `
  query ListTimeReports(
    $filter: ModelTimeReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTimeReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        operator {
          id
          docType
          docNumber
          docDateIssuance
          docPlaceIssuance
          docPlaceIssuanceCity
          name
          surname
          location
          reportTo
          contractType
          entryDate
          birthDate
          birthCity
          birthDepart
          rhesus
          gender
          title
          institution
          gradeDate
          professionalCard
          epsAffiliationDate
          afpAffiliationDate
          mainAddress
          mainNeighborhood
          mainCity
          mainDepartment
          secondaryAddress
          secondaryNeighborhood
          secondaryCity
          secondaryDepartment
          mainPhone
          secondaryPhone
          landline
          corporateEmail
          personalEmail
          arl
          cashRegisterAffiliation
          emergencyContactName
          emergencyContactPhone
          emergencyContactAddress
          status
          rol
          calculatedPayroll
          createdAt
          updatedAt
          operatorAreaId
          operatorPositionId
          operatorEpsId
          operatorAfpId
        }
        startDate
        endDate
        well {
          id
          name
          isUltrabasic
          createdAt
          updatedAt
          jobNumberWellsId
        }
        fieldDays
        overBonusDays
        travelDays
        calculatedBonus {
          id
          name
          calculated
        }
        createdAt
        updatedAt
        operatorTimeReportsId
        timeReportWellId
      }
      nextToken
    }
  }
`;
export const getPositionBonus = /* GraphQL */ `
  query GetPositionBonus($id: ID!) {
    getPositionBonus(id: $id) {
      id
      positionID
      bonusID
      position {
        id
        name
        salary
        bonuses {
          nextToken
        }
        createdAt
        updatedAt
      }
      bonus {
        id
        name
        type
        value
        isPercentage
        isCreated
        position {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPositionBonuses = /* GraphQL */ `
  query ListPositionBonuses(
    $filter: ModelPositionBonusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPositionBonuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        positionID
        bonusID
        position {
          id
          name
          salary
          createdAt
          updatedAt
        }
        bonus {
          id
          name
          type
          value
          isPercentage
          isCreated
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
