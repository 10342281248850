import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { getDailyWorkReport, listWells } from "../../graphql/queries";
import {
  calculateDays,
  confirmDelete,
  currentDateFormatYMD,
  getById,
  globalAlert,
  mutationQuery,
  queryConsult,
  queryPaginationConsult,
} from "../../utils/commonFuntions/commonFuntions";
import {
  dailyReportListById,
  getIdByEmail,
  valDailyWorkReport,
} from "../../utils/specifictQueries";
import { Dayjs } from "dayjs";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DUTY } from "../../utils/enums/enum";
import {
  createDailyWorkReport,
  deleteDailyWorkReport,
  updateDailyWorkReport,
} from "../../graphql/mutations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import styles from "../../styles/reports.module.css";
import Reports from "./Reports";
import fetchApi from "../../lambda";

export default function Operator() {
  const [id, setId] = useState("");
  const [operatorId, setOperatorId] = useState("");
  const dutyList = Object.values(DUTY);
  const [range, setRange] = useState(true);
  const [jobNumber, setJobNumber] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [loadDataSpinner, setLoadDataSpinner] = useState(false);
  const [spinnerMassive, setSpinnerMassive] = useState(false);
  const [wellName, setWellName] = useState("Pozo");
  const [day, setDay] = React.useState<Dayjs | null>(null);
  const [initialDate, setInitialDate] = useState("");
  const [initialRange, setInitialRange] = React.useState<Dayjs | null>(null);
  const [finalRange, setFinaleRange] = React.useState<Dayjs | null>(null);
  const [dataToDelete, setDataToDelete]: Array<any> = useState([]) as [
    Array<any>,
    CallableFunction
  ];
  const [wellList, setWellList] = useState([]) as [
    Array<any>,
    CallableFunction
  ];
  const [dailyWorkReports, setDailyWorkReports] = useState([]) as [
    Array<any>,
    CallableFunction
  ];
  const [dataOperatorForm, setDataOperatorForm] = useState({
    dateReport: "",
    duty: "Duty",
    operatorDailyWorkReportsId: "",
    dailyWorkReportWellId: "",
  });
  const { dateReport, duty, dailyWorkReportWellId } = dataOperatorForm;

  const listReports = async () => {
    let data: Array<any> = [];
    let token = null;
    const user = await Auth.currentAuthenticatedUser();
    const resp: Array<any> = await queryConsult(
      getIdByEmail(user.attributes.email)
    );
    setLoadDataSpinner(true);
    if (resp?.length > 0) {
      setDataOperatorForm({
        ...dataOperatorForm,
        operatorDailyWorkReportsId: resp[0].id,
      });
      const currentDate = currentDateFormatYMD(new Date());
      try {
        do {
          const { items, nextToken }: any = await queryPaginationConsult(
            dailyReportListById(
              resp[0].id,
              token,
              `${currentDate.substring(0, 8)}26`,
              calculateDays(30, `${currentDate.substring(0, 8)}26`, true)
            )
          );
          token = nextToken;
          data = [...data, ...items];
        } while (token);
        console.log("data: ", data);
      } catch (error) {
        console.log("ERROR NEXT_TOKEN: ", error);
      }
      setOperatorId(resp[0].id);
      if (data?.length > 0) {
        data.sort(function (a: any, b: any) {
          return (
            new Date(a.dateReport).getTime() - new Date(b.dateReport).getTime()
          );
        });
        setDailyWorkReports(data);
      } else {
        setDailyWorkReports([]);
      }
      setLoadDataSpinner(false);
    }
  };

  const dataById = async (id: string) => {
    setId(id);
    const resp: any = await getById(getDailyWorkReport, id);
    setInitialRange(resp?.dateReport);
    setWellName(resp?.well?.name);
    setJobNumber(resp?.well?.jobNumber?.name);
    setDataOperatorForm({
      ...dataOperatorForm,
      duty: Object.values(DUTY)[
        Object.keys(DUTY).findIndex((e: any) => e === resp.duty)
      ],
      dateReport: resp.dateReport,
      operatorDailyWorkReportsId: resp.operatorDailyWorkReportsId,
      dailyWorkReportWellId: resp.dailyWorkReportWellId,
    });
    setInitialDate(resp.dateReport);
  };

  const addReport = async () => {
    if (!valData()) {
      try {
        setSpinner(true);
        const arrayDates: Array<any> = [];
        const token = await Auth.currentSession().then((data) =>
          data.getIdToken().getJwtToken()
        );
        let init: any = initialRange;
        let fin: any = finalRange;
        const startDate = new Date(init);
        const endDate = new Date(fin);
        let currentDate = new Date(startDate);

        if (!range) {
          while (currentDate.getTime() <= endDate.getTime()) {
            const dateStr = currentDate.toISOString().split("T")[0];
            arrayDates.push({
              date: dateStr,
              wellId: dataOperatorForm.dailyWorkReportWellId,
              duty: dataOperatorForm.duty,
              entry: false,
              out: false,
            });
            currentDate.setDate(currentDate.getDate() + 1);
          }

          const { data }: any = await fetchApi(
            `dailyReports/${operatorId}`,
            "POST",
            token,
            { role: "Operator", dailyReports: arrayDates }
          );
          const success: Array<any> = data?.data?.filter(
            (pos: any) => pos?.inserted === true
          );
          const fail: Array<any> = data?.data?.filter(
            (pos: any) => pos?.inserted === false
          );
          globalAlert(
            "success",
            "Data insertada",
            `Se insertaron ${success.length} y fallaron ${fail.length} al insertarse`
          );
        } else {
          const { data }: any = await fetchApi(
            `dailyReports/${operatorId}`,
            "POST",
            token,
            {
              role: "Operator",
              dailyReports: [
                {
                  date: new Date(init).toISOString().split("T")[0],
                  wellId: dataOperatorForm.dailyWorkReportWellId,
                  duty: dataOperatorForm.duty,
                  entry: false,
                  out: false,
                },
              ],
            }
          );
          const success: Array<any> = data?.data?.filter(
            (pos: any) => pos?.inserted === true
          );
          const fail: Array<any> = data?.data?.filter(
            (pos: any) => pos?.inserted === false
          );
          globalAlert(
            "success",
            "Data insertada",
            `Se insertaron ${success.length} y fallaron ${fail.length} al insertarse`
          );
        }
        setSpinner(false);
        listReports();
        limpiar();
      } catch (error: any) {
        console.error("Error in addReport:", error);
        console.error(error?.data?.message);
        setSpinner(false);
      }
    }
  };

  const updateReport = async () => {
    let tempObj: any = dataOperatorForm;
    tempObj.id = id;
    const valDateReport: Array<any> = await valDailyWorkReport(
      operatorId,
      tempObj?.dateReport
    );
    if (
      valDateReport?.length > 0 &&
      valDateReport[0]?.dateReport !== initialDate
    ) {
      globalAlert("error", "Reporte", "No pueden haber fechas repetidas.");
      limpiar();
    } else {
      if (Object.values(DUTY).findIndex((e: any) => e === tempObj.duty) >= 0)
        delete tempObj.duty;
      const resp: any = await mutationQuery(updateDailyWorkReport, tempObj);
      if (resp.id) {
        globalAlert(
          "success",
          "Reporte actualizado",
          "El reporte fue actualizado con éxito."
        );
        listReports();
        limpiar();
      }
    }
  };

  const limpiar = () => {
    setDataOperatorForm({
      dateReport: "",
      duty: "Duty",
      operatorDailyWorkReportsId: operatorId,
      dailyWorkReportWellId: "",
    });
    setWellName("Pozo");
    setInitialRange(day);
    setFinaleRange(day);
    setRange(true);
    setSpinner(false);
    setJobNumber("");
    setId("");
  };

  const valData = () => {
    if (dateReport === "") {
      globalAlert(
        "error",
        "Fecha del reporte",
        "Por favor seleccione una fecha."
      );
      return true;
    } else if (duty === "") {
      globalAlert("error", "Duty", "Por favor seleccione un Duty.");
      return true;
    } else if (dailyWorkReportWellId === "") {
      globalAlert("error", "Pozo", "Por favor seleccione un pozo.");
      return true;
    } else return false;
  };

  const deleteMassive = async (id: any) => {
    if (dataToDelete.includes(id)) {
      setDataToDelete(dataToDelete.filter((e: any) => e !== id));
    } else {
      setDataToDelete([...dataToDelete, id]);
    }
  };

  const deleteReport = async (id: any) => {
    const confirmation = await confirmDelete();
    if (confirmation) {
      const resp: any = await mutationQuery(deleteDailyWorkReport, {
        id: id,
      });
      if (resp.id) {
        globalAlert(
          "success",
          "Reporte eliminado",
          "El reporte fue eliminado con éxito."
        );
      }
      listReports();
    }
  };

  const deleteItemsChecked = async () => {
    const confirmation = await confirmDelete();
    if (confirmation) {
      try {
        await dataToDelete.map(async (pos: any) => {
          const resp: any = await mutationQuery(deleteDailyWorkReport, {
            id: pos,
          });
        });
        listReports();
        globalAlert(
          "success",
          "Reportes eliminados",
          "Los reportes seleccionados fueron eliminados con éxito."
        );
      } catch (error) {
        console.error("Error: ", error);
        listReports();
        globalAlert(
          "error",
          "Error al eliminar",
          "Por favor pruebe de nuevo, sino contactese con el tecnico."
        );
      }
      setDataToDelete([]);
      setSpinnerMassive(false);
    }
  };

  useEffect(() => {
    (async () => {
      const resp: Array<any> = await queryConsult(listWells);
      resp.sort(function (a: any, b: any) {
        if (`${a?.name}` < `${b?.name}`) {
          return -1;
        }
        if (`${b?.name}` < `${a?.name}`) {
          return 1;
        }
        return 0;
      });
      setWellList(resp);
      listReports();
    })();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row col-12 text-center">
        <h3 className="fs-6 fs-md-1">
          REPORTE DE TIEMPO TRABAJADO CAMPO / OFICINA
        </h3>
      </div>
      <div className="row justify-content-between">
        <div className="col-12 col-md-6">
          <button
            type="button"
            className="col-12 btn btn-danger"
            hidden={dataToDelete?.length <= 0}
            onClick={() => {
              setSpinnerMassive(true);
              deleteItemsChecked();
            }}
          >
            {spinnerMassive ? (
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              />
            ) : (
              <>Eliminar los reportes seleccionados</>
            )}
          </button>
          {dailyWorkReports?.length > 0 ? (
            <div className="table-responsive py-3">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Fecha</th>
                    <th>Duty</th>
                    <th>Nombre del pozo</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {dailyWorkReports &&
                    dailyWorkReports.map((pos: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td style={{ fontSize: 13 }}>{index + 1}</td>
                          <td style={{ fontSize: 13 }}>{pos?.dateReport}</td>
                          <td style={{ fontSize: 13 }}>{pos?.duty}</td>
                          <td style={{ fontSize: 13 }}>{pos?.well?.name}</td>
                          <td className="d-flex justify-content-around align-items-center">
                            <button
                              type="button"
                              id={pos?.id}
                              className="btn btn-warning btn-sm"
                              onClick={() => dataById(pos?.id)}
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>
                            <button
                              type="button"
                              id={pos?.id}
                              className="btn btn-danger btn-sm"
                              hidden={dataToDelete?.length > 0}
                              onClick={() => deleteReport(pos?.id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                id="flexCheckDefault"
                                className="form-check-input"
                                checked={
                                  dataToDelete.findIndex(
                                    (e: any) => e == pos?.id
                                  ) == -1
                                    ? false
                                    : true
                                }
                                onChange={() => deleteMassive(pos?.id)}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          ) : loadDataSpinner ? (
            <div className="row justify-content-center">
              <div className="spinner-border text-success" role="status" />
            </div>
          ) : (
            <div className="alert alert-primary" role="alert">
              No hay reportes por el momento!
            </div>
          )}
        </div>
        <div className="col-12 col-md-6">
          <div className="row justify-content-start">
            <div className="col-12 col-md-4">
              <label className="form-label">Duty</label>
              <div className="col-12 btn-group">
                <button
                  type="button"
                  className="btn btn-outline-primary dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {duty}
                </button>
                <ul
                  className={`col-md-12 dropdown-menu ${styles.dropdownContainer}`}
                >
                  {dutyList.map((pos: any, index: number) => {
                    const tempDuty = Object.keys(DUTY);
                    return (
                      <li key={index}>
                        <button
                          id="duty"
                          name="duty"
                          type="button"
                          className="col-12 btn btn-light"
                          onClick={() =>
                            setDataOperatorForm({
                              ...dataOperatorForm,
                              duty: tempDuty[index],
                            })
                          }
                          value={pos}
                        >
                          {pos}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <label className="form-label">Nombre del pozo</label>
              <div className="col-12 btn-group">
                <button
                  type="button"
                  className="btn btn-outline-primary dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {wellName}
                </button>
                <ul
                  className={`col-md-12 dropdown-menu ${styles.dropdownContainer}`}
                >
                  {wellList.map((pos: any, index: number) => {
                    return (
                      <li key={index} className="col-12">
                        <button
                          id="dailyWorkReportWellId"
                          name="dailyWorkReportWellId"
                          type="button"
                          className="col-12 btn btn-light"
                          onClick={() => {
                            setDataOperatorForm({
                              ...dataOperatorForm,
                              dailyWorkReportWellId: pos.id,
                            });
                            setJobNumber(pos?.jobNumber?.name);
                            setWellName(pos?.name);
                          }}
                          value={pos?.name}
                        >
                          {pos?.name}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <label className="form-label">Job number</label>
              <input
                type="text"
                className="form-control"
                id="jobNumber"
                name="jobNumber"
                value={jobNumber}
                disabled={true}
              />
            </div>
          </div>
          <div className="row justify-content-start pt-2">
            <div className="col-12 col-md-6 pt-2 pt-md-0">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="col-12"
                  label={range ? "Seleccione una fecha" : "Fecha inicial"}
                  value={initialRange}
                  onChange={(newValue: any) => {
                    setInitialRange(newValue);
                    setDataOperatorForm({
                      ...dataOperatorForm,
                      dateReport: new Date(newValue.$d)
                        .toISOString()
                        .split("T")[0],
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="col-12 col-md-6 pt-2 pt-md-0">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="col-12"
                  disabled={range}
                  label={range ? "Seleccione una fecha" : "Fecha final"}
                  value={finalRange}
                  onChange={(newValue) => {
                    setFinaleRange(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="row justify-content-center justify-content-md-start pt-2">
            <div className="col-6 col-md-2">
              <button
                type="button"
                className="col-12 btn btn-outline-secondary"
                onClick={() => setRange(!range)}
              >
                {range ? "Día" : "Rango"}
              </button>
            </div>
          </div>
          <div className="row justify-content-between pt-2">
            <div className="col-6 d-flex justify-content-center">
              <button
                type="button"
                className="col-12 col-md-5 btn btn-danger"
                onClick={() => limpiar()}
              >
                Limpiar
              </button>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <div className="col-6 d-flex justify-content-center">
                {spinner ? (
                  <button
                    type="button"
                    className={`col-12 col-md-7 btn btn-${
                      id ? "warning" : "success"
                    }`}
                  >
                    <div
                      className="spinner-border spinner-border-sm text-light"
                      role="status"
                    />
                  </button>
                ) : (
                  <button
                    type="button"
                    className={`col-12 col-md-7 btn btn-${
                      id ? "warning" : "success"
                    }`}
                    onClick={() => {
                      id ? updateReport() : addReport();
                    }}
                    disabled={duty == "Duty" || wellName == "Pozo"}
                  >
                    <p className="p-0 m-0">{id ? "Actualizar" : "Agregar"}</p>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="row justify-content-center pt-2">
            <Reports />
          </div>
        </div>
      </div>
    </div>
  );
}
