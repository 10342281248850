import React, { useState, useEffect } from "react";
import { createBonus, updateBonus } from "../../../graphql/mutations";
import { bonusIdOfEdit, changeBonusShowComponent } from "../../../redux/features/operative";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  mutationQuery,
  getById,
  globalAlert,
} from "../../../utils/commonFuntions/commonFuntions";
import { BONUSTYPE } from "../../../utils/enums/enum";
import * as queries from "../../../graphql/queries";
import { queryConsult } from '../../../utils/commonFuntions/commonFuntions';
import { valNameBonus } from "../../../utils/specifictQueries";

export default function AddUpdateBonus() {
  const dispatch = useAppDispatch();
  const [behaviorStatus, setBehaviorStatus] = useState("");
  const { bonusIdEdit, listBonusState, bonusArrayValidate }: any =
    useAppSelector((state) => state.operatives);
  const listTypeBonus: Array<any> = Object.values(BONUSTYPE);
  const [typeValue, setTypeValue] = useState("Tipo de bonus");
  const [behaviorValue, setBehaviorValue] = useState("Tipo de comportamiento");
  const [dataBonusForm, setDataBonusForm] = useState({
    name: "",
    type: "Tipo de bonus",
    value: "",
    isPercentage: false,
    isCreated: false,
  });
  const { type, name, value, isPercentage, isCreated } = dataBonusForm;

  const setDataBonus = ({ target }: any) => {
    setDataBonusForm({
      ...dataBonusForm,
      [target.name]: target.value,
    });
  };

  const addBonus = async () => {
    const valName: Array<any> = await queryConsult(valNameBonus(name));
    if(valName.length < 1){
      const resp: any = await mutationQuery(createBonus, dataBonusForm);
      if (resp.id) {
        globalAlert("success", "Nuevo bono", "Bono agregado con éxito.");
        clearForm();
        dispatch(changeBonusShowComponent(false));
      }
    } else {
      globalAlert("error", "Bono", "El nombre del bono no puede estar repetido.");
    }
  };

  const updateBono = async () => {
    let temporalObj: any = dataBonusForm;
    temporalObj.id = bonusIdEdit;
    const resp = await mutationQuery(updateBonus, temporalObj);
    if (resp.id && resp.id !== "") {
      globalAlert(
        "success",
        "Bono actualizado",
        "El bono fue actualizado con éxito."
      );
      clearForm();
      dispatch(changeBonusShowComponent(false));
    }
  };

  const clearForm = () => {
    setDataBonusForm({
      name: "",
      type: "Tipo de bonus",
      value: "",
      isPercentage: false,
      isCreated: false
    });
    setBehaviorStatus("");
    dispatch(bonusIdOfEdit(""));
    dispatch(changeBonusShowComponent(false));
  };

  const valData = () => { 
    if(name.length < 1) {
      return true
    } else if(type === "Tipo de bonus") {
      return true
    } else if(parseInt(value) < 1) {
      return true
    }else if(name.length > 0 && parseInt(value) > 1 && type === "B") {
      return false
    } else if(type === "Otro") {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    const getUserData = async () => {
      const resp = await getById(queries.getBonus, bonusIdEdit);
      setDataBonusForm({
        ...dataBonusForm,
        name: resp.name,
        type: resp.type,
        value: resp.value,
        isPercentage: resp.isPercentage,
      });
    };
    if (bonusIdEdit !== "") {
      getUserData();
    }
  }, [bonusIdEdit]);

  return (
    <>
      <form>
        <div className="col-md-12">
          <label className="form-label">Nombre del bono</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            onChange={(e) => {
              setDataBonusForm({
                ...dataBonusForm,
                name: e.target.value.toLocaleUpperCase(),
              })
            }}
            value={name}
          />
        </div>
        <div className="col-md-12">
          <label className="form-label">Valor del bono</label>
          <input
            id="value"
            type="number"
            step="0.01"
            className="form-control"
            name="value"
            onChange={(e) => setDataBonus(e)}
            value={value}
          />
        </div>
        <div className="row justify-content-between">
          <div className="col-md-12">
            <label className="form-label">¿Es porcentual?</label>
            <div className="col-md-12 btn-group">
              <button
                type="button"
                className="btn btn-outline-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                value={isPercentage.toString()}
              >
                {isPercentage ? "Es un valor porcentual" : "No es porcentual"}
              </button>
              <ul className="col-md-12 dropdown-menu">
                <li>
                  <button
                    id="isPercentage"
                    name="isPercentage"
                    type="button"
                    className="col-md-12 btn btn-light"
                    onClick={() =>
                      setDataBonusForm({
                        ...dataBonusForm,
                        isPercentage: true,
                      })
                    }
                    value="true"
                  >
                    Es un valor porcentual
                  </button>
                </li>
                <li>
                  <button
                    id="isPercentage"
                    name="isPercentage"
                    type="button"
                    className="col-md-12 btn btn-light"
                    onClick={() =>
                      setDataBonusForm({
                        ...dataBonusForm,
                        isPercentage: false,
                      })
                    }
                    value="false"
                  >
                    No es porcentual
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-12">
            <label className="form-label">Tipo de bonus</label>
            <div className="col-md-12 btn-group">
              <button
                type="button"
                className="btn btn-outline-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {typeValue}
              </button>
              <ul className="col-md-12 dropdown-menu">
                {listTypeBonus.map((pos: any, index: number) => {
                  return (
                    <li key={index}>
                      <button
                        id="type"
                        name="type"
                        type="button"
                        className="col-md-12 btn btn-light"
                        onClick={() => {
                          setDataBonusForm({
                            ...dataBonusForm,
                            type: Object.keys(BONUSTYPE)[index],
                            isCreated: false,
                          });
                          setBehaviorStatus(Object.keys(BONUSTYPE)[index]);
                          setTypeValue(pos);
                        }}
                        disabled={bonusArrayValidate.includes(pos)}
                        value={pos}
                      >
                        {pos}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div
            className="col-md-12"
            hidden={behaviorStatus === "O" ? false : true}
          >
            <label className="form-label">Tipo de comportamiento</label>
            <div className="col-md-12 btn-group">
              <button
                type="button"
                className="btn btn-outline-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {behaviorValue}
              </button>
              <ul className="col-md-12 dropdown-menu">
                {listTypeBonus.slice(0,6).map((pos: any, index: number) => {
                  return (
                    <li key={index}>
                      <button
                        id="type"
                        name="type"
                        type="button"
                        className="col-md-12 btn btn-light"
                        onClick={() => {
                          setDataBonusForm({
                            ...dataBonusForm,
                            type: Object.keys(BONUSTYPE)[index],
                            isCreated: true,
                          });
                          setBehaviorValue(pos);
                        }}
                        value={pos}
                      >
                        {pos}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="d-flex justify-content-around pt-3">
            <button
              type="button"
              className="col-md-5 btn btn-danger"
              onClick={() => clearForm()}
            >
              Limpiar formulario
            </button>
            <button
              type="button"
              className={`col-md-5 btn btn-${
                bonusIdEdit !== "" ? "warning" : "success"
              }`}
              disabled={valData()}
              onClick={() => {
                bonusIdEdit !== "" ? updateBono() : addBonus();
              }}
            >
              {`${bonusIdEdit !== "" ? "Actualizar bono" : "Agregar bono"}`}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
