import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import fetchApi from "../../lambda";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { height } from "@mui/system";
import { Auth } from "aws-amplify";

export default function ValidateReports() {
  const navigate = useNavigate();
  const { idUser } = useParams();
  const [reports, setReports] = useState([]) as [Array<any>, CallableFunction];
  const [dataComparison, setDataComparison] = useState({}) as [
    any,
    CallableFunction
  ];
  const [comparisonAlert, setComparisonAlert] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const token = await Auth.currentSession().then(data => data.getIdToken().getJwtToken());
        const resp: any = await fetchApi(
          `timeRecordingComparison/${idUser}`,
          "GET",
          token
        );
        if (resp?.data?.data && resp?.data?.data.length > 0) {
          setReports(resp?.data?.data);
        }
      } catch (error) {
        console.error("errro: ", error);
      }
    })();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row col-12 justify-content-between p-2">
        <button
          id={idUser}
          type="button"
          className="col-3 col-md-1 d-flex justify-content-around align-items-center btn btn-primary"
          onClick={() => navigate(`/Operations/${idUser}`)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <p className="ps-md-1 p-0 m-0">Volver</p>
        </button>
      </div>
      <div className="d-md-flex justify-content-around py-3" hidden={reports.length < 1}>
        <div className="col-12 col-md-3 d-flex justify-content-start align-items-center">
          <div style={{width: "15px", height: "15px", border: "1px solid #A8FFA9", backgroundColor: "#A8FFA9" }} />
          <div className="ps-2">Ambos datos coinciden</div>
        </div>
        <div className="col-12 col-md-3 d-flex justify-content-start align-items-center">
          <div style={{width: "15px", height: "15px", border: "1px solid #A8FFEA", backgroundColor: "#A8FFEA" }} />
          <div className="ps-2">Solo el coordinador cargo el reporte</div>
        </div>
        <div className="col-12 col-md-3 d-flex justify-content-start align-items-center">
          <div style={{width: "15px", height: "15px", border: "1px solid #FFFCA8", backgroundColor: "#FFFCA8" }} />
          <div className="ps-2">Solo el trabajador cargo el reporte</div>
        </div>
        <div className="col-12 col-md-3 d-flex justify-content-start align-items-center">
          <div style={{width: "15px", height: "15px", border: "1px solid #FFA8A8", backgroundColor: "#FFA8A8" }} />
          <div className="ps-2">Los datos no coinciden</div>
        </div>
      </div>
      <div className="row justify-content-between">
        <div
          className={`${
            reports.filter((pos: any) => pos.flagStatus === "changed").length >
            0
              ? "col-md-3"
              : "col-md-12"
          }`}
        >
          {reports && reports.length > 0 ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th className="text-center">Fecha del reporte</th>
                    <th hidden={reports.filter((pos: any) => pos?.flagStatus === "changed").length > 0 ? true : false} className="text-center">Pozo</th>
                    <th className="text-center" hidden={!reports.some((pos: any) => pos?.duty_y !== "")}>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {reports.map((pos: any, index: number) => {
                    return (
                      <tr key={index} style={{backgroundColor: `${pos?.flagStatus === "left_only" ? "#A8FFEA" : pos?.flagStatus === "both" ? "#A8FFA9" : pos?.flagStatus === "changed" ? "#FFA8A8" : "#FFFCA8" }`}}>
                        <td className="text-center">{pos?.dateReport}</td>
                        <td hidden={reports.filter((pos: any) => pos?.flagStatus === "changed").length > 0 ? true : false} className="text-center">{pos[`well.name_x`]}</td>
                        <td className="text-center">
                          <button
                            id={pos.id}
                            type="button"
                            className="btn btn-primary"
                            hidden={pos?.flagStatus !== "changed"}
                            onClick={() => {
                              setDataComparison(pos);
                              setComparisonAlert(true);
                            }}
                          >
                            Comparar
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="alert alert-primary" role="alert">
              No hay reportes por el momento
            </div>
          )}
        </div>
        <div className="col-12 col-md-9 d-md-flex justify-content-between">
          <div
            className="col-12 col-md-6"
            hidden={
              reports.filter((pos: any) => pos.flagStatus === "changed")
                .length > 0
                ? false
                : true
            }
          >
            <div
              className="col-8 offset-2 alert alert-primary"
              hidden={comparisonAlert}
              role="alert"
            >
              Seleccione un dato a comparar
            </div>
            <div className="co-10 offset-1 table-responsive">
              <h4 className="text-center" hidden={!comparisonAlert}>Datos cargados por el coordinador</h4>
              <table
                className="col-6 table table-hover"
                hidden={!comparisonAlert}
              >
                <thead>
                  <tr>
                    <th className="text-center">
                      Fecha del reporte: {dataComparison?.dateReport}{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                    <td>Duty: {dataComparison?.duty_x}</td>
                  </tr> */}
                  <tr>
                    <td>Pozo: {dataComparison[`well.name_x`]}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="col-12 col-md-6"
            hidden={
              reports.filter((pos: any) => pos.flagStatus === "changed")
                .length > 0
                ? false
                : true
            }
            >
            <div
              className="col-8 offset-2 alert alert-primary"
              hidden={comparisonAlert}
              role="alert"
              >
              Seleccione un dato a comparar
            </div>
            <div className="co-10 offset-1 table-responsive">
              <h4 className="text-center" hidden={!comparisonAlert}>Datos cargados por el trabajador</h4>
              <table
                className="col-6 table table-hover"
                hidden={!comparisonAlert}
              >
                <thead>
                  <tr>
                    <th className="text-center">
                      Fecha del reporte: {dataComparison?.dateReport}{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                    <td>Duty: {dataComparison?.duty_y}</td>
                  </tr> */}
                  <tr>
                    <td>Pozo: {dataComparison[`well.name_y`]}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
