import React, { useEffect, useState } from "react";
import { listAllUsers } from "../../../../src/utils/specifictQueries";
import {
  adminPasswordAssing,
  confirmDelete,
  getTimeReportsByUserId,
  globalAlert,
  mutationQuery,
  queryConsult,
  validateUserDelete,
} from "../../../utils/commonFuntions/commonFuntions";
import AddUpdateUsers from "./AddUpdateUsers";
import {
  deleteOperator,
  deleteTimeReport,
  updateOperator,
} from "../../../graphql/mutations";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  changeUserShowComponent,
  userIdOfEdit,
} from "../../../redux/features/operative";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faKey,
  faUserCheck,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
import AWS from "aws-sdk";
import { ROL, STATUS } from "../../../utils/enums/enum";
import { Auth } from "aws-amplify";
import fetchApi from "../../../lambda";
import styles from "../../../styles/users.module.css";

export default function Users() {
  const dispatch = useAppDispatch();
  const [spinner, setSpinner] = useState(false);
  const { userShowComponent }: any = useAppSelector(
    (state) => state.operatives
  );
  const [usersData, setUsersData] = useState([]);
  const UserPoolId = `${process.env.REACT_APP_POOL_ID}`;

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    region: process.env.REACT_APP_REGION,
  });
  const cognito = new AWS.CognitoIdentityServiceProvider();

  const getAllUsers = async () => {
    const resp = await queryConsult(listAllUsers);
    if (resp.length > 0) {
      resp.sort(function (a: any, b: any) {
        const nombreCompletoA = `${a?.surname} ${a?.name}`;
        const nombreCompletoB = `${b?.surname} ${b?.name}`;
        return nombreCompletoA.localeCompare(nombreCompletoB, "es", {
          sensitivity: "accent",
        });
      });
      setUsersData(resp);
    }
  };

  const getUrl = async () => {
    setSpinner(true);
    try {
      const token = await Auth.currentSession().then((data) =>
        data.getIdToken().getJwtToken()
      );
      const resp = await fetchApi("staffExport ", "GET", token);
      window.open(resp?.data?.fileUrl, "_self", "noopener,noreferrer");
      setSpinner(false);
    } catch (error) {
      console.error("error: ", error);
      setSpinner(false);
    }
  };

  const deleteuser = async (data: any) => {
    try {
      const confirmation = await confirmDelete();
      if (confirmation) {
        const valDelete = await validateUserDelete(data?.id, data?.rol);
        if (valDelete) {
          const timeReportsArr: Array<any> = await queryConsult(
            getTimeReportsByUserId(data?.id)
          );
          if (timeReportsArr.length > 0) {
            timeReportsArr.map(async (pos: any) => {
              await mutationQuery(deleteTimeReport, {
                id: pos?.id,
              });
            });
          }
          const resp: any = await mutationQuery(deleteOperator, {
            id: data.id,
          });
          if (resp.id) {
            const { Users }: any = await cognito
              .listUsers({ UserPoolId: UserPoolId })
              .promise();
            const arr: Array<any> = Users;
            arr.map(async (pos: any) => {
              if (pos.Attributes[2].Value === data.corporateEmail) {
                await cognito
                  .adminDeleteUser({
                    UserPoolId: UserPoolId,
                    Username: pos.Username,
                  })
                  .promise();
              }
            });
            globalAlert(
              "success",
              "Usuario eliminado",
              "El usuario fue eliminado con éxito."
            );
          }
          getAllUsers();
        } else {
          globalAlert(
            "error",
            "Usuario",
            "El usuario no se puede eliminar ya que existen reportes con su información."
          );
        }
      }
    } catch (error) {
      console.error("error delete user: ", error);
    }
  };

  const enabledDisabledUser = async (data: any) => {
    const resp: any = await mutationQuery(updateOperator, {
      id: data?.id,
      status: data?.status === "Active" ? STATUS.INACTIVE : STATUS.ACTIVE,
    });
    getAllUsers();
  };

  const changePasswordAdmin = async (userName: string, email: string) => {
    const response = await adminPasswordAssing(userName);

    var params = {
      UserPoolId: `${process.env.REACT_APP_POOL_ID}`,
      Username: email,
      Password: response || `${process.env.REACT_APP_PASS}`,
      Permanent: true,
    };

    cognito.adminSetUserPassword(params, function (err, data) {
      if (err) {
        console.error(err, err.stack);
        globalAlert(
          "error",
          "Contraseña",
          "Error al actualizar la contraseña, contactese con el area de sistemas"
        );
      } else {
        globalAlert("success", "Contraseña", "Contraseña actualizada");
      }
    });
  };

  useEffect(() => {
    getAllUsers();
  }, [userShowComponent]);

  return (
    <>
      <div className="container-fluid py-2">
        <div className="row justify-content-center mt-2">
          <button
            className="col-md-2 mx-2 btn btn-outline-primary"
            onClick={() => dispatch(changeUserShowComponent(true))}
          >
            Lista usuarios
          </button>
          <button
            className="col-md-2 mx-2 btn btn-outline-primary"
            onClick={() => {
              dispatch(changeUserShowComponent(false));
              dispatch(userIdOfEdit(""));
            }}
          >
            Agregar usuarios
          </button>
          <button
            type="button"
            className="col-md-3 mx-2 btn btn-outline-success"
            onClick={() => getUrl()}
          >
            {spinner ? (
              <div>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Descargando...
              </div>
            ) : (
              <>Descargar listado de usuarios</>
            )}
          </button>
        </div>
        {userShowComponent ? (
          <div>
            <div className="row mt-3 mx-1">
              {usersData.length > 0 ? (
                <div className="table-responsive px-0 mx-0">
                  <table className="table table-hover">
                    <thead>
                      <tr className="text-center table-dark">
                        <th scope="col">Nombre Completo</th>
                        <th scope="col">Cargo</th>
                        <th scope="col">Área</th>
                        <th scope="col">Celular</th>
                        <th scope="col">Email empresarial</th>
                        <th scope="col">Rol</th>
                        <th scope="col">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {usersData.map((pos: any, index: number) => {
                        return (
                          <tr key={index} className="text-center">
                            <th
                              className={`${styles.tbody}`}
                            >{`${pos?.surname} ${pos?.name}`}</th>
                            <th className={`${styles.tbody}`}>
                              {pos?.position?.name}
                            </th>
                            <th className={`${styles.tbody}`}>
                              {pos?.area?.name}
                            </th>
                            <th className={`${styles.tbody}`}>
                              {pos?.mainPhone}
                            </th>
                            <th className={`${styles.tbody}`}>
                              {pos?.corporateEmail}
                            </th>
                            <th className={`${styles.tbody}`}>
                              {
                                Object.values(ROL)[
                                  Object.keys(ROL).findIndex(
                                    (e: any) => e == pos?.rol
                                  )
                                ]
                              }
                            </th>
                            <th className="d-flex justify-content-between">
                              <button
                                type="button"
                                id={pos.id}
                                className="btn btn-outline-warning btn-sm"
                                onClick={() => {
                                  dispatch(userIdOfEdit(pos.id));
                                  dispatch(changeUserShowComponent(false));
                                }}
                              >
                                <FontAwesomeIcon icon={faPen} />
                              </button>
                              <button
                                type="button"
                                id={pos.id}
                                className="btn btn-outline-danger btn-sm"
                                onClick={() => deleteuser(pos)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                              <button
                                type="button"
                                id={pos.id}
                                className={`btn btn-outline-${
                                  pos?.status === "Active"
                                    ? "primary"
                                    : "secondary"
                                } btn-sm`}
                                onClick={() => enabledDisabledUser(pos)}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    pos?.status === "Active"
                                      ? faUserCheck
                                      : faUserLock
                                  }
                                />
                              </button>
                              <button
                                type="button"
                                id={pos.id}
                                className="btn btn-outline-info btn-sm"
                                onClick={() =>
                                  changePasswordAdmin(
                                    `${pos?.surname} ${pos?.name}`,
                                    pos?.corporateEmail
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faKey} />
                              </button>
                            </th>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <h1>No hay usuarios por el momento, por favor ingrese uno.</h1>
              )}
            </div>
          </div>
        ) : (
          <div>
            <AddUpdateUsers />
          </div>
        )}
      </div>
    </>
  );
}
