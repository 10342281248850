import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { changeAuthStatus } from "../redux/features/operative";

export default function AuthComponent({ children }: { children: any }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAuth }: any = useAppSelector((state) => state.operatives);
  const router = useLocation();
  const { pathname } = router;

  const publicRoutes = ["/login"];
  // Esta función se encarga de validar la sesión del usuario obteniendo los tokens y los datos de la pool
  // de cognito, cambia la variable changeAuthStatus a true en caso de estar autenticado.
  const getSession = async () => {
    try {
      const tokens = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      if (user && tokens) {
        dispatch(changeAuthStatus(true));
      }
    } catch (error) {
      dispatch(changeAuthStatus(false));
      navigate("/login");
    }
  };

  useEffect(() => {
    getSession();
  }, [isAuth === false]);

  if (publicRoutes.includes(pathname)) {
    return children;
  } else if (isAuth) {
    return children;
  }
}
