import Swal from "sweetalert2";
import { API, graphqlOperation } from "aws-amplify";

const validateDate: number = 6570;

export const globalAlert = (type: String, title: String, text: String) => {
  Swal.fire({
    icon: type === "error" ? "error" : "success",
    title: `${title}`,
    showConfirmButton: false,
    text: `${text}`,
    timer: 2000,
  });
};

export const inputEmailRecoverPas = async () => {
  const { value: email } = await Swal.fire({
    title: "Correo corporativo",
    input: "email",
    inputLabel: "Ingrese su correo",
    inputPlaceholder: "ejemplo@gmail.com",
    confirmButtonText: "Obtener código",
    validationMessage: "Correo electrónico invalido",
  });

  return email;
};

export const confirmDelete = async () => {
  const { value: isConfirmed } = await Swal.fire({
    icon: "question",
    text: "¿Seguro que desea eliminar este campo?",
    confirmButtonText: "Si, estoy seguro",
    cancelButtonText: "Cancelar",
    showCancelButton: true,
  });
  return isConfirmed;
};

export const newPassword = async () => {
  let valCode: String = "";
  let password: String = "";
  let confirmation: String = "";
  let object = { error: false, valCode: "", password: "" };
  let arrayCredentials: Array<any> = [];
  await Swal.fire({
    title: "Cambio de contraseña",
    confirmButtonText: "Actualizar",
    html:
      `<input id="valCode" type="text" class="swal2-input" placeholder="Código recibido" value="${valCode}">` +
      `<input id="newpassword" type="password" class="swal2-input" placeholder="Nueva contraseña" value="${password}">` +
      `<input id="confirmpassword" type="password" class="swal2-input" placeholder="Confirmar contraseña" value="${confirmation}">`,
    focusConfirm: false,
    preConfirm: () => {
      const arr: Array<any> = [
        (document.getElementById("valCode") as HTMLInputElement) || null,
        (document.getElementById("newpassword") as HTMLInputElement) || null,
        (document.getElementById("confirmpassword") as HTMLInputElement) ||
          null,
      ];
      arr.map((pos: any) => {
        arrayCredentials.push(pos?.value);
      });
      valCode = arrayCredentials[0];
      return arr;
    },
  });
  try {
    if (arrayCredentials.length === 3) {
      let valPassword = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
      );
      if (valCode.length < 1) {
        globalAlert("error", "Código", "El código no puede estar vacío");
        object.error = true;
        return object;
      } else if (arrayCredentials.some((pos: String) => pos.length < 1)) {
        globalAlert("error", "Contraseña", "Ningún campo puede estar vacío");
        object.error = true;
        return object;
      } else if (arrayCredentials[1] !== arrayCredentials[2]) {
        globalAlert("error", "Contraseña", "Las contraseñas no coinciden");
        object.error = true;
        return object;
      } else if (
        arrayCredentials[1].match(valPassword) === null ||
        arrayCredentials[2].match(valPassword) === null
      ) {
        globalAlert(
          "error",
          "Contraseña",
          "La contraseña debe ser de al menos 8 digitos y debe contener al menos un caracter especial, una minuscula, una mayuscula y numero"
        );
        object.error = true;
        return object;
      } else {
        object.valCode = arrayCredentials[0];
        object.password = arrayCredentials[1];
        return object;
      }
    } else {
      globalAlert(
        "error",
        "Contraseña",
        "La contraseña no se pudo actualizar, por favor intentelo de nuevo"
      );
      object.error = true;
      return object;
    }
  } catch (error) {
    console.error("ERRRO ALERT: ", error);
  }
};

export const currentDateFormatYMD = (date: Date) => {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")}`;
};

export const adminPasswordAssing = async (nameUser: string) => {
  const { value } = await Swal.fire({
    title: "",
    input: "text",
    inputLabel: `Ingrese la contraseña que desea asignarle a: ${nameUser}`,
    inputPlaceholder: "*Ejemplo123",
    confirmButtonText: "Actualizar contraseña",
    showCancelButton: true,
    cancelButtonText: "Cancelar",
    preConfirm: (password) => {
      let valPassword = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
      );
      if (!password) {
        Swal.showValidationMessage("Debes ingresar una contraseña!");
      } else if (!password.match(valPassword)) {
        Swal.showValidationMessage(
          "La contraseña debe ser de al menos 8 digitos y debe contener al menos un caracter especial, una minuscula, una mayuscula y numero"
        );
      }
    },
  });
  return value;
};

export const dateFormatMDY = (date: String) => {
  return `${date.substring(5, 7)}-${date.substring(8, 10)}-${date.substring(
    0,
    4
  )}`;
};

export const calculateDays = (
  quantityDays: number,
  initialDate: any,
  typeOperation: boolean
) => {
  let dateInitial = new Date(initialDate);
  let date = typeOperation
    ? dateInitial.setDate(dateInitial.getDate() + quantityDays)
    : dateInitial.setDate(dateInitial.getDate() - quantityDays);
  return currentDateFormatYMD(new Date(date));
};

export const queryConsult = async (query: string) => {
  const items: any = await API.graphql(graphqlOperation(query));
  return items.data[Object.keys(items.data)[0]].items;
};

export const queryPaginationConsult = async (query: string) => {
  const items: any = await API.graphql(graphqlOperation(query));
  return items.data[Object.keys(items.data)[0]];
};

export const getById = async (query: string, id: any) => {
  const items: any = await API.graphql(graphqlOperation(query, { id }));
  return items.data[Object.keys(items.data)[0]];
};

export const mutationQuery = async (query: string, input: any) => {
  const items: any = await API.graphql(
    graphqlOperation(query, { input: input })
  );
  return items.data[Object.keys(items.data)[0]];
};

export const restDays = (date: Date) => {
  const dateReceived = new Date(date);
  const currentDate = new Date();
  const difference_in_time = currentDate.getTime() - dateReceived.getTime();
  const difference_in_days = difference_in_time / (1000 * 3600 * 24);
  return difference_in_days >= validateDate ? false : true;
};

export const validateUserDelete = async (id: String, rol: String) => {
  if (rol === "Operator") {
    const queryAdminReport = ` {
      listAdminReports(limit: 1000000, filter: {
        operatorAdminWorkReportsId: {
          contains: "${id}"}}) {
        items {
          id
          operatorAdminWorkReportsId
        }
      }
    }`;
    const queryDailyReport = ` {
      listDailyWorkReports(limit: 1000000, filter: {
        operatorDailyWorkReportsId: {
          contains: "${id}"}}) {
        items {
          id
          operatorDailyWorkReportsId
        }
      }
    }`;
    const dailyResp: Array<any> = await queryConsult(queryDailyReport);
    const adminResp: Array<any> = await queryConsult(queryAdminReport);
    if (dailyResp.length > 0 || adminResp.length > 0) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const getTimeReportsByUserId = (id: string) => {
  return `
  query {
    listTimeReports(limit: 1000000, filter: {
      operatorTimeReportsId: {
        eq: "${id}"
      }
    }) {
      items {
        id
        operator {
          name
        }
        operatorTimeReportsId
      }
    }
  }
  `;
};

export const pagToken = async (
  tableName: string,
  file: string,
  id: string,
  dateReport: string,
  items: string,
  token: string = ""
) => {
  const query = `query {
    ${tableName}(filter: {
      ${file}: {
        contains: "${id}"
      }, and: {
        dateReport: {
          eq: "${dateReport}"
        }
      }
    }, limit: 100000 ${token.length > 0 ? `,nextToken: "${token}"` : ""}) {
      items {
        ${items}
      }
      nextToken
    }
  }`;
  const resp: any = await queryPaginationConsult(query);
  if (resp?.items?.length < 1 && resp?.nextToken) {
    await pagToken(tableName, file, id, dateReport, items, resp?.nextToken);
  } else {
    return resp?.items;
  }
};
