import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { useAppDispatch } from "../redux/hooks";
import { changeAuthStatus } from "../redux/features/operative";
import { Auth } from "aws-amplify";
import AWS from "aws-sdk";
import styles from "../styles/login.module.css";
import {
  globalAlert,
  inputEmailRecoverPas,
  newPassword,
  queryConsult,
} from "../utils/commonFuntions/commonFuntions";
import { listOperatorByEmail } from "../../src/utils/specifictQueries";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [registerShow, setRegisterShow] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [dataUserValue, setdataUserValue] = useState({
    email: "",
    password: "",
  });
  const [emailToSendCode, setEmailToSendCode] = useState("") as [
    String,
    CallableFunction
  ];
  const UserPoolId = `${process.env.REACT_APP_POOL_ID}`;

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    region: process.env.REACT_APP_REGION,
  });
  const cognito = new AWS.CognitoIdentityServiceProvider();

  const [validation, setValidation] = useState(false);
  const { email, password } = dataUserValue;

  const setDataUser = ({ target }: any) => {
    setdataUserValue({
      ...dataUserValue,
      [target.name]: target.value,
    });
  };

  const signIn = async () => {
    try {
      setSpinner(true);
      const appsyncEmail = await queryConsult(listOperatorByEmail(email));
      if (appsyncEmail[0]?.status === "Inactive") {
        globalAlert(
          "error",
          "Usuario",
          "Este usuario fue deshabilitado por el administrador"
        );
      } else {
        const cognitoCredential: any = await Auth.signIn({
          username: email,
          password: password,
        });
        if (cognitoCredential.attributes.email && appsyncEmail.length > 0) {
          dispatch(changeAuthStatus(true));
          navigate(`/${appsyncEmail[0].rol}`);
        }
      }
      setSpinner(false);
    } catch (error: any) {
      console.error("signIn: ", error);
      if (error?.name == "UserNotFoundException") {
        globalAlert("error", "Error de credenciales", "Este usuario no existe");
        setSpinner(false);
      } else {
        globalAlert(
          "error",
          "Error de credenciales",
          "Usuario o contraseña incorrecto"
        );
        setSpinner(false);
      }
    }
  };

  const signUp = async () => {
    try {
      setSpinner(true);
      const appsyncEmail = await queryConsult(listOperatorByEmail(email));
      if (appsyncEmail.length > 0) {
        const cognitoCredential: any = await Auth.signUp({
          username: email,
          password: password,
        });
        if (cognitoCredential?.userConfirmed) {
          globalAlert(
            "success",
            "¡Bienvenido!",
            "Usuario registrado con éxito."
          );
          setRegisterShow(false);
          setSpinner(false);
          setdataUserValue({
            email: "",
            password: "",
          });
        }
      } else {
        globalAlert(
          "error",
          "Error de credenciales",
          "Este correo no existe, por favor valide con su jefe."
        );
        setSpinner(false);
      }
    } catch (error: any) {
      console.error("signUp: ", error);
      if (error?.name == "UsernameExistsException") {
        globalAlert(
          "error",
          "Error de usuario",
          `El usuario con el email: ${email} ya se encuentra registrado`
        );
      } else {
        globalAlert(
          "error",
          "Error de credenciales",
          "La contraseña debe tener minúsculas, mayúsculas y números"
        );
      }
      setSpinner(false);
    }
  };

  const getCode = async () => {
    const email: String = await inputEmailRecoverPas();
    if (email) setEmailToSendCode(email);
    try {
      const getIdCognitoUser: any = await cognito
        .listUsers({
          UserPoolId: UserPoolId,
          AttributesToGet: ["email", "sub"],
          Filter: `email = \"${email}\"`,
        })
        .promise();
      if (getIdCognitoUser?.Users?.length > 0) {
        const code: any = await cognito
          .forgotPassword({
            ClientId: `${process.env.REACT_APP_CLIENT_ID}`,
            Username: getIdCognitoUser?.Users[0]?.Username,
          })
          .promise();
        const dataToUpdatePass: any = await newPassword();
        const confirmPass: any = await cognito
          .confirmForgotPassword({
            ClientId: `${process.env.REACT_APP_CLIENT_ID}`,
            Username: getIdCognitoUser?.Users[0]?.Username,
            Password: dataToUpdatePass?.password,
            ConfirmationCode: dataToUpdatePass?.valCode,
          })
          .promise();
        globalAlert(
          "success",
          "Contraseña",
          `La contraseña fue actualizada con exito`
        );
      } else {
        globalAlert(
          "error",
          "Email",
          `${email} no esta registrado en el sistema`
        );
      }
    } catch (error: any) {
      if (error?.code == "LimitExceededException")
        globalAlert(
          "error",
          "Contraseña",
          "Excedió el limite de intentos, prueba mas tarde"
        );
    }
  };

  useEffect(() => {
    const getSession = async () => {
      const tokens = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      if (user && tokens) {
        const appsyncEmail: Array<any> = await queryConsult(
          listOperatorByEmail(user?.attributes?.email)
        );
        if (appsyncEmail.length > 0) {
          dispatch(changeAuthStatus(true));
          navigate(`/${appsyncEmail[0].rol}`);
          setValidation(true);
        } else {
          globalAlert(
            "error",
            "Error de credenciales",
            "Usuario no encontrado"
          );
          setValidation(true);
        }
      } else {
        setValidation(true);
      }
    };
    getSession();
  }, [validation]);

  return (
    <>
      <div className={`container-fluid ${styles.backgroundImage}`}>
        <div className="row justify-content-center vh-100">
          <div className="col-md-4 mt-5">
            <p className={`text-center text-light ${styles.sinidHeader}`}>
              DataBono$
            </p>
            <p className={`text-center text-light ${styles.sinidPar}`}>
              Sistema Integrado de Información de Datalog
            </p>
            <div className="border border-light p-1">
              <div className="border border-light p-3">
                <div className="card">
                  <div className="row justify-content-center px-2 px-md-5">
                    <div className="col-12">
                      <p className={`fs-3 text-center ${styles.ingresoColor}`}>
                        {registerShow ? "Registro" : "Ingreso"}
                      </p>
                      <form className="py-1">
                        <div className="mb-2">
                          <label>Correo electrónico</label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            id="email"
                            onChange={(e) => setDataUser(e)}
                            value={email}
                          />
                        </div>
                        <div className="mb-2">
                          <label>Contraseña</label>
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            id="password"
                            onChange={setDataUser}
                            value={password}
                          />
                        </div>
                        <div className="d-flex justify-content-between">
                          <button
                            type="button"
                            className={`col-5 col-md-5 ${styles.enviarBoton}`}
                            disabled={
                              email.length < 1 || password.length < 1
                                ? true
                                : false
                            }
                            onClick={() => (registerShow ? signUp() : signIn())}
                          >
                            <span hidden={spinner ? true : false}>Enviar</span>
                            <div
                              hidden={spinner ? false : true}
                              className="spinner-border spinner-border-sm"
                              role="status"
                            />
                          </button>
                          <button
                            type="button"
                            className={`col-5 col-md-5 ${styles.enviarBoton}`}
                            onClick={() =>
                              registerShow
                                ? setRegisterShow(false)
                                : setRegisterShow(true)
                            }
                          >
                            <span>
                              {registerShow ? "Iniciar sesión" : "Registro"}
                            </span>
                          </button>
                        </div>
                        <div className="row">
                          <p
                            role="button"
                            style={{color: "#2a3587"}}
                            className="text-center"
                            onClick={getCode}
                          >
                            ¿Olvido su contraseña?
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
