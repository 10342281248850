import React, { useState, useEffect } from "react";
import { createJobNumber, updateJobNumber } from "../../../graphql/mutations";
import { getJobNumber } from "../../../graphql/queries";
import { countJobNumber, jobNumberIdOfEdit } from "../../../redux/features/operative";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  mutationQuery,
  globalAlert,
  getById,
} from "../../../utils/commonFuntions/commonFuntions";

export default function AddUpdateJobNumber() {
  const dispatch = useAppDispatch();
  const { newJobNumber, jobNumberIdEdit }: any = useAppSelector((state) => state.operatives);
  const [dataJobNumberForm, setDataJobNumberForm] = useState({
    name: "",
    customerName: "",
  });
  const { name, customerName } = dataJobNumberForm;

  const setDataJobNumber = ({ target }: any) => {
    setDataJobNumberForm({
      ...dataJobNumberForm,
      [target.name]: target.value,
    });
  };

  const addJobNumber = async () => {
    const resp: any = await mutationQuery(createJobNumber, dataJobNumberForm);
    if (resp.id) {
      globalAlert("success", "Job number", "Job number creado con éxito.");
      dispatch(countJobNumber(newJobNumber + 1));
      clearForm();
    }
  };

  const updateJobNumberData = async() => {
    let tempObj: any = dataJobNumberForm;
    tempObj.id = jobNumberIdEdit;
    const resp: any = await mutationQuery(updateJobNumber, tempObj);
    if(resp.id) {
      globalAlert("success", "Job number", "Job number actualizado con éxito");
      dispatch(countJobNumber(0));
      dispatch(jobNumberIdOfEdit(""));
      clearForm();
    }
  };

  const clearForm = () => {
    setDataJobNumberForm({
      ...dataJobNumberForm,
      name: "",
      customerName: "",
    });
    dispatch(jobNumberIdOfEdit(""))
  };

  useEffect(() => {
    const getJobNumberData = async() => {
      const resp: any = await getById(getJobNumber, jobNumberIdEdit);
      setDataJobNumberForm({
        ...dataJobNumberForm,
        name: resp.name,
        customerName: resp.customerName,
      });
    };
    if(jobNumberIdEdit !== "") {
      getJobNumberData();
    }
  }, [jobNumberIdEdit])

  return (
    <>
      <div className="row col-md-12 justify-content-between">
        <div className="col-6">
          <label className="form-label">Job number</label>
          <input
            id="name"
            name="name"
            type="text"
            className="form-control"
            onChange={(e) => setDataJobNumber(e)}
            value={name}
          />
        </div>
        <div className="col-6">
          <label className="form-label">Nombre de la compañía</label>
          <input
            id="customerName"
            name="customerName"
            type="text"
            className="form-control"
            onChange={(e) => setDataJobNumber(e)}
            value={customerName}
          />
        </div>
      </div>
      <div className="row col-12 pt-2 justify-content-around">
        <button type="button" className="col-5 btn btn-outline-danger" onClick={() => clearForm()}>
          Limpiar formulario
        </button>
        <button
          type="button"
          disabled={name == "" || customerName == ""}
          className={`col-5 btn btn-outline-${
            jobNumberIdEdit ? "warning" : "success"
          }`}
          onClick={() => {
            jobNumberIdEdit ? updateJobNumberData() : addJobNumber();
          }}
        >
          {jobNumberIdEdit ? "Actualizar" : "Agregar"}
        </button>
      </div>
    </>
  );
}
