import React, { useEffect, useState } from "react";
import { deleteBonus } from "../../../graphql/mutations";
import { listBonuses } from "../../../graphql/queries";
import {
  bonusIdOfEdit,
  changeBonusShowComponent,
  getBonusArray,
  getListBonus,
} from "../../../redux/features/operative";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  confirmDelete,
  globalAlert,
  mutationQuery,
  queryConsult,
} from "../../../utils/commonFuntions/commonFuntions";
import AddUpdateBonus from "./AddUpdateBonus";
import { BONUSTYPE } from "../../../utils/enums/enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { valPositionByBonus } from "../../../utils/specifictQueries";
import styles from "../../../styles/common.module.css";

export default function Bonus() {
  const dispatch = useAppDispatch();
  const { newBonus }: any = useAppSelector((state) => state.operatives);
  const [listBonus, setListBonus] = useState([]) as [
    Array<any>,
    CallableFunction
  ];

  const listAllBonus = async () => {
    let bonosArray = [];
    const resp: Array<any> = await queryConsult(listBonuses);
    if (resp.length > 0) {
      resp.sort(function(a: any, b: any) {
        const nombreCompletoA = `${a?.name}`;
        const nombreCompletoB = `${b?.name}`;
        return nombreCompletoA.localeCompare(nombreCompletoB, "es", { sensitivity: "accent" });
      });
      setListBonus(resp);
      dispatch(getListBonus(resp));
      const ob = resp.filter((bono: any) => bono.type == "OB");
      const uob = resp.filter((bono: any) => bono.type == "UOB");
      const t = resp.filter((bono: any) => bono.type == "T");
      if (ob.length > 0) {
        bonosArray.push(BONUSTYPE.OB);
      }
      if (uob.length > 0) {
        bonosArray.push(BONUSTYPE.UOB);
      }
      if (t.length > 0) {
        bonosArray.push(BONUSTYPE.T);
      }
      dispatch(getBonusArray(bonosArray));
    }
  };

  const deleteBono = async (id: string) => {
    const confirmation = await confirmDelete();
    if (confirmation) {
      const valBonus: Array<any> = await queryConsult(valPositionByBonus(id));
      if (valBonus.length > 0) {
        globalAlert(
          "error",
          "Bono",
          "El bono no se puede eliminar, ya que esta en uso."
        );
      } else {
        const resp: any = await mutationQuery(deleteBonus, { id: id });
        if (resp.id) {
          globalAlert(
            "success",
            "Bono eliminado",
            "El bono fue eliminado con éxito."
          );
        }
        listAllBonus();
      }
    }
  };

  useEffect(() => {
    listAllBonus();
  }, [newBonus]);

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between mt-3 mx-1">
          <div className="col-md-8">
            <div className="d-flex justify-content-around">
              <button
                className="col-md-4 btn btn-outline-success"
                onClick={() => dispatch(changeBonusShowComponent(true))}
                disabled={newBonus}
              >
                Agregar bono
              </button>
            </div>
            {listBonus.length > 0 ? (
              <div className="table-responsive py-3">
                <table className="table table-hover">
                  <thead>
                    <tr className="text-center table-dark">
                      <th scope="col">Nombre</th>
                      <th scope="col">Tipo de bono</th>
                      <th scope="col">Valor</th>
                      <th scope="col">Valor estándar o porcentual</th>
                      <th scope="col">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listBonus.map((pos: any, index: number) => {
                      const type = Object.keys(BONUSTYPE).indexOf(
                        pos.type as unknown as BONUSTYPE
                      );
                      return (
                        <tr key={index} className="text-center">
                          <th className={`${styles.tbody}`}>{pos.name}</th>
                          <th className={`${styles.tbody}`}>
                            {Object.values(BONUSTYPE)[type].toLocaleUpperCase()}
                          </th>
                          <th className={`${styles.tbody}`}>{pos.value}</th>
                          <th className={`${styles.tbody}`}>{pos.isPercentage ? "%" : "$"}</th>
                          <th className="d-flex justify-content-around align-items-center">
                            <button
                              id={pos.id}
                              className="btn btn-warning btn-sm"
                              onClick={() => {
                                dispatch(bonusIdOfEdit(pos.id));
                                dispatch(changeBonusShowComponent(true));
                              }}
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>
                            <button
                              id={pos.id}
                              className="btn btn-danger btn-sm"
                              onClick={() => deleteBono(pos.id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <h1>No hay bonus por el momento.</h1>
            )}
          </div>
          <div className="col-md-4">
            <div hidden={!newBonus}>
              <AddUpdateBonus />
            </div>
            <div className="alert alert-primary" role="alert" hidden={newBonus}>
              Seleccione agregar bono
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
