import { Dayjs } from "dayjs";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { deleteAdminReport, updateAdminReport } from "../../graphql/mutations";
import { getAdminReport, listWells } from "../../graphql/queries";
import {
  calculateDays,
  confirmDelete,
  currentDateFormatYMD,
  getById,
  globalAlert,
  mutationQuery,
  queryConsult,
  queryPaginationConsult,
} from "../../utils/commonFuntions/commonFuntions";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DUTY } from "../../utils/enums/enum";
import {
  adminDailyReportListById,
  getNameById,
  valAdminReport,
} from "../../utils/specifictQueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import styles from "../../styles/common.module.css";
import fetchApi from "../../lambda";
import { Auth } from "aws-amplify";

export default function Reports() {
  const navigate = useNavigate();
  const { idUser } = useParams();
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const dutyList = Object.values(DUTY);
  const [jobNumber, setJobNumber] = useState("");
  const [range, setRange] = useState(true);
  const [entry, setEntry] = useState(false);
  const [out, setOut] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [spinnerMassive, setSpinnerMassive] = useState(false);
  const [loadDataSpinner, setLoadDataSpinner] = useState(false);
  const [wellName, setWellName] = useState("Pozo");
  const [alertStatus, setAlertStatus] = useState(true);
  const [day, setDay] = React.useState<Dayjs | null>(null);
  const [initialRange, setInitialRange] = React.useState<Dayjs | null>(null);
  const [finalRange, setFinaleRange] = React.useState<Dayjs | null>(null);
  const [wellList, setWellList] = useState([]) as [
    Array<any>,
    CallableFunction
  ];
  const [dailyWorkReports, setDailyWorkReports] = useState([]) as [
    Array<any>,
    CallableFunction
  ];
  const [dataToDelete, setDataToDelete]: Array<any> = useState([]) as [
    Array<any>,
    CallableFunction
  ];
  const [initialDate, setInitialDate] = useState("");
  const [dataOperatorForm, setDataOperatorForm] = useState({
    dateReport: "",
    duty: "Duty",
    operatorAdminWorkReportsId: idUser,
    adminReportWellId: "",
    entry: false,
    out: false,
  });
  const { dateReport, duty, adminReportWellId } = dataOperatorForm;

  const listReports = async () => {
    let dailyReport: Array<any> = [];
    let token = "";
    setLoadDataSpinner(true);
    setDataOperatorForm({
      ...dataOperatorForm,
      operatorAdminWorkReportsId: idUser,
    });
    const currentDate = currentDateFormatYMD(new Date());

    let report = await queryPaginationConsult(
      adminDailyReportListById(
        idUser,
        token,
        `${currentDate.substring(0, 8)}26`,
        calculateDays(30, `${currentDate.substring(0, 8)}26`, true)
      )
    );

    if (report?.items?.length > 0)
      dailyReport = [...dailyReport, ...report?.items];
    if (report?.nextToken) token = report?.nextToken;
    while (token?.length > 0) {
      report = await queryPaginationConsult(
        adminDailyReportListById(
          idUser,
          token,
          `${currentDate.substring(0, 8)}26`,
          calculateDays(30, `${currentDate.substring(0, 8)}26`, true)
        )
      );
      dailyReport = [...dailyReport, ...report?.items];
      token = report?.nextToken ? report?.nextToken : "";
    }
    if (dailyReport?.length > 0) {
      dailyReport.sort(function (a: any, b: any) {
        return (
          new Date(a.dateReport).getTime() - new Date(b.dateReport).getTime()
        );
      });
      setDailyWorkReports(dailyReport);
    } else {
      setAlertStatus(false);
      setDailyWorkReports([]);
    }
    setLoadDataSpinner(false);
  };

  const dataById = async (id: string) => {
    setId(id);
    const resp: any = await getById(getAdminReport, id);
    setInitialRange(resp.dateReport);
    setWellName(resp.well.name);
    setJobNumber(resp?.well?.jobNumber?.name);
    setDataOperatorForm({
      ...dataOperatorForm,
      duty: Object.values(DUTY)[
        Object.keys(DUTY).findIndex((e: any) => e === resp.duty)
      ],
      dateReport: resp.dateReport,
      operatorAdminWorkReportsId: resp.operatorAdminWorkReportsId,
      adminReportWellId: resp.adminReportWellId,
    });
    setInitialDate(resp.dateReport);
    setJobNumber(resp?.well?.jobNumber?.name);
  };

  const addReport = async () => {
    if (!valData()) {
      try {
        setSpinner(true);
        const arrayDates: Array<any> = [];
        const token = await Auth.currentSession().then((data) =>
          data.getIdToken().getJwtToken()
        );
        let init: any = initialRange;
        let fin: any = finalRange;
        const startDate = new Date(init);
        const endDate = new Date(fin);
        let currentDate = new Date(startDate);

        if (!range) {
          while (currentDate.getTime() <= endDate.getTime()) {
            const dateStr = currentDate.toISOString().split("T")[0];
            arrayDates.push({
              date: dateStr,
              wellId: dataOperatorForm.adminReportWellId,
              duty: dataOperatorForm.duty,
              entry: false,
              out: false,
            });
            currentDate.setDate(currentDate.getDate() + 1);
          }
          const { data }: any = await fetchApi(
            `dailyReports/${idUser}`,
            "POST",
            token,
            { role: "Operations", dailyReports: arrayDates }
          );
          const success: Array<any> = data?.data?.filter(
            (pos: any) => pos?.inserted === true
          );
          const fail: Array<any> = data?.data?.filter(
            (pos: any) => pos?.inserted === false
          );
          globalAlert(
            "success",
            "Data insertada",
            `Se insertaron ${success.length} y fallaron ${fail.length} al insertarse`
          );
        } else {
          const { data }: any = await fetchApi(
            `dailyReports/${idUser}`,
            "POST",
            token,
            {
              role: "Operations",
              dailyReports: [
                {
                  date: new Date(init).toISOString().split("T")[0],
                  wellId: dataOperatorForm.adminReportWellId,
                  duty: dataOperatorForm.duty,
                  entry: false,
                  out: false,
                },
              ],
            }
          );
          const success: Array<any> = data?.data?.filter(
            (pos: any) => pos?.inserted === true
          );
          const fail: Array<any> = data?.data?.filter(
            (pos: any) => pos?.inserted === false
          );
          globalAlert(
            "success",
            "Data insertada",
            `Se insertaron ${success.length} y fallaron ${fail.length} al insertarse`
          );
        }
        setSpinner(false);
        listReports();
        clearForm();
      } catch (error: any) {
        console.error("Error adminReport: ", error?.data?.message);
        setSpinner(false);
      }
    }
  };

  const updateReport = async () => {
    let tempObj: any = dataOperatorForm;
    tempObj.id = id;
    const valDateReport: Array<any> = await valAdminReport(
      idUser,
      tempObj?.dateReport
    );
    if (
      valDateReport?.length > 0 &&
      valDateReport[0]?.dateReport !== initialDate
    ) {
      globalAlert("error", "Reporte", "No pueden haber fechas repetidas.");
      clearForm();
    } else {
      if (Object.values(DUTY).findIndex((e: any) => e === tempObj.duty) >= 0)
        delete tempObj.duty;
      const resp: any = await mutationQuery(updateAdminReport, tempObj);
      if (resp.id) {
        globalAlert(
          "success",
          "Reporte actualizado",
          "El reporte fue actualizado con éxito."
        );
        listReports();
        clearForm();
      }
    }
  };

  const clearForm = () => {
    setDataOperatorForm({
      ...dataOperatorForm,
      dateReport: "",
      duty: "Duty",
      operatorAdminWorkReportsId: idUser,
      adminReportWellId: "",
      entry: false,
      out: false,
    });
    setInitialRange(day);
    setFinaleRange(day);
    setJobNumber("");
    setEntry(false);
    setRange(true);
    setOut(false);
    setId("");
  };

  const valData = () => {
    if (dateReport === "") {
      globalAlert(
        "error",
        "Fecha del reporte",
        "Por favor seleccione una fecha."
      );
      return true;
    } else if (duty === "") {
      globalAlert("error", "Duty", "Por favor seleccione un Duty.");
      return true;
    } else if (adminReportWellId === "") {
      globalAlert("error", "Pozo", "Por favor seleccione un pozo.");
      return true;
    } else return false;
  };

  const deleteMassive = async (id: any) => {
    if (dataToDelete.includes(id)) {
      setDataToDelete(dataToDelete.filter((e: any) => e !== id));
    } else {
      setDataToDelete([...dataToDelete, id]);
    }
  };

  const deleteReport = async (id: any) => {
    const confirmation = await confirmDelete();
    if (confirmation) {
      const resp: any = await mutationQuery(deleteAdminReport, {
        id: id,
      });
      if (resp.id) {
        globalAlert(
          "success",
          "Reporte eliminado",
          "El reporte fue eliminado con éxito."
        );
      }
      listReports();
    }
  };

  const deleteItemsChecked = async () => {
    const confirmation = await confirmDelete();
    if (confirmation) {
      try {
        await dataToDelete.map(async (pos: any) => {
          await mutationQuery(deleteAdminReport, { id: pos });
        });
        globalAlert(
          "success",
          "Reportes eliminados",
          "Los reportes seleccionados fueron eliminados con éxito."
        );
      } catch (error) {
        console.error("Error: ", error);
        globalAlert(
          "error",
          "Error al eliminar",
          "Por favor pruebe de nuevo, sino contactese con el tecnico."
        );
      }
      setTimeout(() => {
        listReports();
      }, 2500);
      setDataToDelete([]);
      setSpinnerMassive(false);
    }
  };

  useEffect(() => {
    (async () => {
      const resp: Array<any> = await queryConsult(listWells);
      resp.sort(function (a: any, b: any) {
        if (`${a?.name}` < `${b?.name}`) {
          return -1;
        }
        if (`${b?.name}` < `${a?.name}`) {
          return 1;
        }
        return 0;
      });
      setWellList(resp);
      const { name, surname }: any = await queryPaginationConsult(
        getNameById(idUser)
      );
      setName(`${name} ${surname}`);
      listReports();
    })();
  }, []);

  return (
    <div className="container-fluid">
      <div className="col-12 row justify-content-between p-2">
        <button
          type="button"
          className="col-3 col-md-1 d-flex justify-content-around align-items-center btn btn-primary"
          onClick={() => navigate(`/Operations`)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <p className="ps-md-1 p-0 m-0">Volver</p>
        </button>
        <h3 className="col-11 fs-6 fs-md-1 d-flex justify-content-center align-items-center">
          REPORTE DE TIEMPO TRABAJADO CAMPO / OFICINA DE: {name}
        </h3>
      </div>
      <div className="row justify-content-between">
        <div className="col-12 col-md-6">
          <button
            type="button"
            className="col-12 btn btn-danger"
            hidden={dataToDelete?.length <= 0}
            onClick={() => {
              setSpinnerMassive(true);
              deleteItemsChecked();
            }}
          >
            {spinnerMassive ? (
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              />
            ) : (
              <>Eliminar los reportes seleccionados</>
            )}
          </button>
          <div className="col-12 row justify-content-center ps-4 p-md-2">
            <button
              type="button"
              className="btn btn-success col-12 col-md-3"
              onClick={() => navigate(`/Operations/validate/${idUser}`)}
            >
              Validar reporte
            </button>
          </div>
          {loadDataSpinner ? (
            <div className="row justify-content-center">
              <div className="spinner-border text-success" role="status" />
            </div>
          ) : (
            <>
              {dailyWorkReports?.length > 0 ? (
                <div className="table-responsive py-3">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Fecha</th>
                        <th>Duty</th>
                        <th>Nombre del pozo</th>
                        <th>Entrada/Salida</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dailyWorkReports &&
                        dailyWorkReports.map((pos: any, index: number) => {
                          return (
                            <tr key={index}>
                              <td style={{ fontSize: 13 }}>{index + 1}</td>
                              <td style={{ fontSize: 13 }}>
                                {pos?.dateReport}
                              </td>
                              <td style={{ fontSize: 13 }}>{pos?.duty}</td>
                              <td style={{ fontSize: 13 }}>
                                {pos?.well?.name}
                              </td>
                              {pos?.entry ? (
                                <td style={{ fontSize: 13 }}>Entrada</td>
                              ) : pos?.out ? (
                                <td style={{ fontSize: 13 }}>Salida</td>
                              ) : (
                                <td></td>
                              )}
                              <td className="d-flex justify-content-around align-items-center">
                                <button
                                  type="button"
                                  id={pos?.id}
                                  className="btn btn-warning btn-sm"
                                  onClick={() => dataById(pos?.id)}
                                >
                                  <FontAwesomeIcon icon={faPen} />
                                </button>
                                <button
                                  type="button"
                                  id={pos?.id}
                                  className="btn btn-danger btn-sm"
                                  hidden={dataToDelete?.length > 0}
                                  onClick={() => deleteReport(pos?.id)}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    id="flexCheckDefault"
                                    className="form-check-input p-2"
                                    onChange={() => deleteMassive(pos?.id)}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div
                  className="alert alert-primary"
                  role="alert"
                  hidden={alertStatus}
                >
                  No hay reportes por el momento!
                </div>
              )}
            </>
          )}
        </div>
        <div className="col-12 col-md-6">
          <div className="row justify-content-start">
            <div className="col-12 col-md-4">
              <label className="form-label">Duty</label>
              <div className="col-12 btn-group">
                <button
                  type="button"
                  className="btn btn-outline-primary dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {duty}
                </button>
                <ul className="col-md-12 dropdown-menu">
                  {dutyList.map((pos: any, index: number) => {
                    const tempDuty = Object.keys(DUTY);
                    return (
                      <li key={index}>
                        <button
                          id="duty"
                          name="duty"
                          type="button"
                          className="col-12 btn btn-light"
                          onClick={() =>
                            setDataOperatorForm({
                              ...dataOperatorForm,
                              duty: tempDuty[index],
                            })
                          }
                          value={pos}
                        >
                          {pos}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <label className="form-label">Nombre del pozo</label>
              <div className="col-12 btn-group">
                <button
                  type="button"
                  className="btn btn-outline-primary dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {wellName}
                </button>
                <ul
                  className={`col-md-12 dropdown-menu ${styles.dropdownContainer}`}
                >
                  {wellList.map((pos: any, index: number) => {
                    return (
                      <li key={index} className="col-12">
                        <button
                          id="adminReportWellId"
                          name="adminReportWellId"
                          type="button"
                          className="col-12 btn btn-light"
                          onClick={() => {
                            setDataOperatorForm({
                              ...dataOperatorForm,
                              adminReportWellId: pos.id,
                            });
                            setJobNumber(pos?.jobNumber?.name);
                            setWellName(pos?.name);
                          }}
                          value={pos?.name}
                        >
                          {pos?.name}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <label className="form-label">Job number</label>
              <input
                type="text"
                className="form-control"
                id="jobNumber"
                name="jobNumber"
                value={jobNumber}
                disabled={true}
              />
            </div>
          </div>
          <div className="row justify-content-start pt-2">
            <div className="col-12 col-md-6 pt-2 pt-md-0">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="col-12"
                  label={range ? "Seleccione una fecha" : "Fecha inicial"}
                  value={initialRange}
                  inputFormat="DD-MM-YYYY"
                  onChange={(newValue: any) => {
                    setInitialRange(newValue);
                    setDataOperatorForm({
                      ...dataOperatorForm,
                      dateReport: new Date(newValue.$d)
                        .toISOString()
                        .split("T")[0],
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="col-12 col-md-6 pt-2 pt-md-0">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="col-12"
                  disabled={range}
                  label={range ? "Seleccione una fecha" : "Fecha final"}
                  value={finalRange}
                  inputFormat="DD-MM-YYYY"
                  onChange={(newValue) => {
                    setFinaleRange(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="d-flex">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                disabled={!range}
                checked={entry}
                onChange={(e) => {
                  setOut(false);
                  setEntry(e.target.checked);
                  if (e.target.checked) {
                    setDataOperatorForm({
                      ...dataOperatorForm,
                      entry: true,
                      out: false,
                    });
                  } else {
                    setDataOperatorForm({
                      ...dataOperatorForm,
                      entry: false,
                      out: false,
                    });
                  }
                }}
              />
              <label className="form-check-label">
                Marque este check para señalar su entrada
              </label>
            </div>
            <div className="form-check ms-2">
              <input
                type="checkbox"
                className="form-check-input"
                disabled={!range}
                checked={out}
                onChange={(e) => {
                  setEntry(false);
                  setOut(e.target.checked);
                  if (e.target.checked) {
                    setDataOperatorForm({
                      ...dataOperatorForm,
                      entry: false,
                      out: true,
                    });
                  } else {
                    setDataOperatorForm({
                      ...dataOperatorForm,
                      entry: false,
                      out: false,
                    });
                  }
                }}
              />
              <label className="form-check-label">
                Marque este check para señalar su salida
              </label>
            </div>
          </div>
          <div className="row justify-content-center justify-content-md-start pt-2">
            <div className="col-6 col-md-2">
              <button
                type="button"
                className="col-12 btn btn-outline-secondary"
                onClick={() => {
                  setEntry(false);
                  setOut(false);
                  setRange(!range);
                }}
              >
                {range ? "Día" : "Rango"}
              </button>
            </div>
          </div>
          <div className="row justify-content-between pt-2">
            <div className="col-6 d-flex justify-content-center">
              <button
                type="button"
                className="col-12 col-md-5 btn btn-danger"
                onClick={() => clearForm()}
              >
                Limpiar
              </button>
            </div>
            <div className="col-6 d-flex justify-content-center">
              {spinner ? (
                <button
                  type="button"
                  className={`col-12 col-md-5 btn btn-${
                    id ? "warning" : "success"
                  }`}
                >
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                  />
                </button>
              ) : (
                <button
                  type="button"
                  className={`col-12 col-md-5 btn btn-${
                    id ? "warning" : "success"
                  }`}
                  onClick={() => {
                    id ? updateReport() : addReport();
                  }}
                  disabled={duty == "Duty" || wellName == "Pozo"}
                >
                  <p className="p-0 m-0">{id ? "Actualizar" : "Agregar"}</p>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
