import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

export default function HeaderNominee() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState();

  const SingOut = async () => {
    await Auth.signOut();
    navigate("/login");
  };

  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        setUserName(user.attributes.email);
      }
    })();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <nav className="navbar navbar-expand-lg bg-light p-0 m-0">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className="collapse navbar-collapse py-2"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <div>
                      <h3 className="fw-ligth text-center">{userName}</h3>
                    </div>
                  </li>
                </ul>
                <form>
                  <button
                    className="col-12 btn btn-outline-danger"
                    type="button"
                    onClick={() => SingOut()}
                  >
                    Cerrar sesión
                  </button>
                </form>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
