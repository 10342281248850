import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { useAppSelector } from "../redux/hooks";

export default function Home() {
  const { email }: any = useAppSelector((state) => state.operatives);
  const navigate = useNavigate();
  const loginRe = () => {
    navigate("/");
  };

  return (
    <div>
      <h1>Email: {email}</h1>
      <button
        type="button"
        onClick={loginRe}
        className="btn btn-outline-primary"
      >
        Login
      </button>
    </div>
  );
}
