export enum TYPEDOCUMENT {
  CC = "CC",
  CE = "CE",
  PPT = "PPT",
  PSP = "PSP",
  PEP = "PEP",
  PA = "PA",
}

export enum RH {
  Ap = "A+",
  An = "A-",
  Bp = "B+",
  Bn = "B-",
  ABp = "AB+",
  ABn = "AB-",
  Op = "O+",
  On = "O-",
}

export enum GENDER {
  HOMBRE = "Hombre",
  MUJER = "Mujer",
  PREFIERO_NO_DECIR = "Otro",
}

export enum STATUS {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}

export enum ROL {
  Admin = "Administrador",
  Operator = "Trabajador",
  Operations = "Operaciones",
  Nominee = "Financiera",
}

export enum BONUSTYPE {
  B = "Incidencia salarial",
  FB = "Bono de alimentación",
  EFB = "Bono de alimentación extra legal",
  OB = "Sobre bono", //Este solo puede existir una vez
  UOB = "Bono de unidad ultra básica", //Este solo puede existir una vez
  T = "Viáticos", //Este solo puede existir una vez
  O = "Otro",
}

export enum DUTY {
  LOGGING_DAY = "Logging day",
  LOGGING_NIGHT = "Logging night",
  STAND_BY = "Stand by",
  TRAVEL = "Travel",
  RIG_UP = "Rig up",
  RIG_DOWN = "Rig down",
  OFFICE = "Office",
}

export enum MONTHS {
  ENERO = "ENERO",
  FEBRERO = "FEBRERO",
  MARZO = "MARZO",
  ABRIL = "ABRIL",
  MAYO = "MAYO",
  JUNIO = "JUNIO",
  JULIO = "JULIO",
  AGOSTO = "AGOSTO",
  SEPTIEMBRE = "SEPTIEMBRE",
  OCTUBRE = "OCTUBRE",
  NOVIEMBRE = "NOVIEMBRE",
  DICIEMBRE = "DICIEMBRE"
}

export enum RANGES {
  DIC_ENE = "1",
  ENE_FEB = "2",
  FEB_MAR = "3",
  MAR_ABR = "4",
  ABR_MAY = "5",
  MAY_JUN = "6",
  JUN_JUL = "7",
  JUL_AGO = "8",
  AGO_SEP = "9",
  SEP_OCT = "10",
  OCT_NOV = "11",
  NOV_DIC = "12"
}