import React, { useState, useEffect } from "react";
import {
  createPosition,
  createPositionBonus,
  deletePositionBonus,
  updatePosition,
} from "../../../graphql/mutations";
import {
  changePositionShowComponent,
  positionIdOfEdit,
} from "../../../redux/features/operative";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  queryConsult,
  mutationQuery,
  globalAlert,
  getById,
} from "../../../utils/commonFuntions/commonFuntions";
import {
  getBonusByPosition,
  getPositionById,
  listBonusByPosition,
  valPositionName,
} from "../../../utils/specifictQueries";
import styles from "../../../styles/common.module.css";

export default function AddUpdatePositions() {
  const dispatch = useAppDispatch();
  const { newPosition, positionIdEdit }: any = useAppSelector(
    (state) => state.operatives
  );
  const [listBonus, setListBonus] = useState([]) as [
    Array<any>,
    CallableFunction
  ];
  const [dataPositionForm, setDataPositionForm]: any = useState({
    name: "",
    salary: 0,
    bonuses: [],
  });
  const { name, salary, bonuses } = dataPositionForm;

  const listAllBonus = async () => {
    const resp: Array<any> = await queryConsult(listBonusByPosition);
    resp.sort(function(a: any, b: any) {
      const nombreCompletoA = `${a?.surname} ${a?.name}`;
      const nombreCompletoB = `${b?.surname} ${b?.name}`;
      return nombreCompletoA.localeCompare(nombreCompletoB, "es", { sensitivity: "accent" });
    });    
    resp.map((pos: any) => {
      pos.status = false;
    });
    setListBonus(resp);
  };

  const bonusSelected = async (position: number) => {
    let dataToInsert = {
      bonus: {
        id: listBonus[position].id,
        name: listBonus[position].name,
        type: listBonus[position].type,
        value: listBonus[position].value,
        isCreated: listBonus[position].isCreated,
        isPercentage: listBonus[position].isPercentage,
      },
    };
    let tempArray: Array<any> = await dataPositionForm?.bonuses;
    const pos = tempArray
      ?.map((e) => e?.bonus?.name)
      .indexOf(dataToInsert?.bonus?.name);
    if (pos == -1) {
      let statusArray: Array<any> = listBonus;
      statusArray[position].status = true;
      setListBonus(statusArray);
      tempArray.push(dataToInsert);
    } else {
      let statusArray: Array<any> = listBonus;
      statusArray[position].status = false;
      setListBonus(statusArray);
      const newArray = tempArray.filter(
        (bonusPos) => bonusPos?.bonus?.name !== dataToInsert?.bonus?.name
      );
      tempArray = newArray;
    }
    setDataPositionForm({
      ...dataPositionForm,
      bonuses: tempArray,
    });
  };

  const setDataPosition = ({ target }: any) => {
    setDataPositionForm({
      ...dataPositionForm,
      [target.name]: target.value,
    });
  };

  const clearForm = () => {
    dispatch(positionIdOfEdit(""));
    setDataPositionForm({
      name: "",
      salary: 0,
      bonuses: [],
    });
    listAllBonus();
  };

  const addPosition = async () => {
    let tempBonusesArray: Array<any> = dataPositionForm.bonuses;
    const valName: Array<any> = await queryConsult(valPositionName(name));
    if (valName.length > 0) {
      globalAlert("error", "Cargo", "Ya existe un cargo con este nombre");
    } else {
      if (
        tempBonusesArray.filter(
          (bono: any) =>
            bono?.bonus?.type == "FB" && bono?.bonus?.isCreated == false
        ).length > 1
      ) {
        globalAlert(
          "error",
          "Error",
          "No se puede asociar mas de un bono de alimentación al cargo"
        );
      } else if (
        tempBonusesArray.filter(
          (bono: any) =>
            bono?.bonus?.type == "EFB" && bono?.bonus?.isCreated == false
        ).length > 1
      ) {
        globalAlert(
          "error",
          "Error",
          "No se puede asociar mas de un bono de alimentación extra legal al cargo"
        );
      } else if (
        tempBonusesArray.filter(
          (bono: any) =>
            bono?.bonus?.type == "B" && bono?.bonus?.isCreated == false
        ).length > 1
      ) {
        globalAlert(
          "error",
          "Error",
          "No se puede asociar mas de un bono de incidencia salarial al cargo"
        );
      } else if (
        tempBonusesArray.filter(
          (bono: any) =>
            bono?.bonus?.type == "FB" && bono?.bonus?.isCreated == false
        ).length > 0 &&
        tempBonusesArray.filter(
          (bono: any) =>
            bono?.bonus?.type == "EFB" && bono?.bonus?.isCreated == false
        ).length > 0
      ) {
        globalAlert(
          "error",
          "Error",
          "No se puede asociar un bono de alimentación extra legal y un bono de alimentación al mismo cargo"
        );
      } else {
        const resp = await mutationQuery(createPosition, {
          name: dataPositionForm?.name,
          salary: dataPositionForm?.salary,
        });
        if (resp.id) {
          tempBonusesArray.map(async (pos) => {
            await mutationQuery(createPositionBonus, {
              positionID: resp?.id,
              bonusID: pos?.bonus?.id,
            });
          });
          globalAlert("success", "Nuevo cargo", "Cargo agregado con éxito.");
          clearForm();
          dispatch(changePositionShowComponent(newPosition + 1));
        }
      }
    }
  };

  const updatePositions = async () => {
    let tempBonusesArray: Array<any> = dataPositionForm.bonuses;
    const response: Array<any> = await queryConsult(
      getBonusByPosition(positionIdEdit)
    );
    if (
      tempBonusesArray.filter(
        (bono: any) =>
          bono?.bonus?.type == "FB" && bono?.bonus?.isCreated == false
      ).length > 1
    ) {
      globalAlert(
        "error",
        "Error",
        "No se puede asociar mas de un bono de alimentación al cargo"
      );
    } else if (
      tempBonusesArray.filter(
        (bono: any) =>
          bono?.bonus?.type == "EFB" && bono?.bonus?.isCreated == true
      ).length > 1
    ) {
      globalAlert(
        "error",
        "Error",
        "No se puede asociar mas de un bono de alimentación extra legal al cargo"
      );
    } else if (
      tempBonusesArray.filter(
        (bono: any) =>
          bono?.bonus?.type == "B" && bono?.bonus?.isCreated !== true
      ).length > 1
    ) {
      globalAlert(
        "error",
        "Error",
        "No se puede asociar mas de un bono de incidencia salarial al cargo"
      );
    } else if (
      tempBonusesArray.filter(
        (bono: any) =>
          bono?.bonus?.type == "FB" && bono?.bonus?.isCreated == true
      ).length > 0 &&
      tempBonusesArray.filter(
        (bono: any) =>
          bono?.bonus?.type == "EFB" && bono?.bonus?.isCreated == true
      ).length > 0
    ) {
      globalAlert(
        "error",
        "Error",
        "No se puede asociar un bono de alimentación extra legal y un bono de alimentación al mismo cargo"
      );
    } else {
      response.map(async (pos: any) => {
        await mutationQuery(deletePositionBonus, { id: pos?.id });
      });
      tempBonusesArray.map(async (pos) => {
        await mutationQuery(createPositionBonus, {
          positionID: positionIdEdit,
          bonusID: pos?.bonus?.id,
        });
      });
      await mutationQuery(updatePosition, {
        id: positionIdEdit,
        name: name,
        salary: salary,
      });
      globalAlert(
        "success",
        "Cargo actualizado",
        "Cargo actualizado con éxito."
      );
      clearForm();
      dispatch(changePositionShowComponent(0));
      dispatch(positionIdOfEdit(""));
    }
  };

  useEffect(() => {
    let statusArray: Array<any> = listBonus;
    (async () => {
      await listAllBonus();
    })();
    if (positionIdEdit !== "") {
      (async () => {
        statusArray.map((pos: any) => {
          pos.status = false;
        });
        const resp: any = await queryConsult(getPositionById(positionIdEdit));
        resp[0]?.bonuses?.items.map((pos: any) => {
          let existData = listBonus
            .map((e) => e.name)
            .indexOf(pos?.bonus?.name);
          statusArray[existData].status = true;
        });
        setDataPositionForm({
          name: resp[0]?.name,
          salary: resp[0]?.salary,
          bonuses: resp[0]?.bonuses?.items,
        });
        setListBonus(statusArray);
      })();
    }
  }, [positionIdEdit]);

  return (
    <>
      <form>
        <div className="row justify-content-around pt-2">
          <button
            type="button"
            className="col-md-5 btn btn-outline-danger"
            onClick={() => clearForm()}
          >
            Limpiar formulario
          </button>
          <button
            type="button"
            className={`col-md-5 btn btn-outline-${
              positionIdEdit !== "" ? "warning" : "success"
            }`}
            onClick={() => {
              positionIdEdit !== "" ? updatePositions() : addPosition();
            }}
            disabled={name.length < 1 || salary < 1}
          >
            {positionIdEdit !== "" ? "Actualizar posición" : "Agregar posición"}
          </button>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">Nombre del cargo</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              onChange={(e) => setDataPosition(e)}
              value={name}
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Salario del cargo</label>
            <input
              type="number"
              className="form-control"
              id="salary"
              name="salary"
              onChange={(e) => setDataPosition(e)}
              value={salary}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label className="form-label">Seleccionar bonus</label>
            <div className="col-md-12 btn-group">
              <button
                type="button"
                className="btn btn-outline-primary dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Seleccione los bonos necesarios
              </button>
              <ul
                className={`col-md-12 dropdown-menu ${styles.dropdownContainer}`}
              >
                <table className="table table-hover">
                  <thead>
                    <tr className="text-start table-dark">
                      <th scope="col">Nombre</th>
                      <th scope="col">Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listBonus.map((pos: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td className="col-md-8">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                id="flexCheckDefault"
                                className="form-check-input"
                                checked={pos?.status}
                                onChange={() => bonusSelected(index)}
                              />
                              <label className="form-check-label">
                                {pos?.name}
                              </label>
                            </div>
                          </td>
                          <td className="col-md-4">
                            {pos?.isPercentage ? "% " : "$ "}
                            {pos?.value}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </ul>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
