import React, { useEffect, useState } from "react";
import { createArea, updateArea } from "../../../graphql/mutations";
import { getArea } from "../../../graphql/queries";
import {
  areaIdOfEdit,
  changeAreaShowComponent,
} from "../../../redux/features/operative";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getById,
  globalAlert,
  mutationQuery,
} from "../../../utils/commonFuntions/commonFuntions";

export default function AddUpdateArea() {
  const dispatch = useAppDispatch();
  const { newArea, areaIdEdit }: any = useAppSelector(
    (state) => state.operatives
  );
  const [dataAreaForm, setDataAreaForm] = useState({
    name: "",
  });
  const { name } = dataAreaForm;

  const setDataAreas = ({ target }: any) => {
    setDataAreaForm({
      ...dataAreaForm,
      [target.name]: target.value,
    });
  };

  const addArea = async () => {
    const resp: any = await mutationQuery(createArea, dataAreaForm);
    if (resp.id) {
      globalAlert("success", "Nueva área", "Area agregada con éxito.");
      dispatch(changeAreaShowComponent(newArea + 1));
      clearForm();
    }
  };

  const updateAreas = async () => {
    let tempObj: any = dataAreaForm;
    tempObj.id = areaIdEdit;
    const resp: any = await mutationQuery(updateArea, tempObj);
    if (resp.id) {
      globalAlert("success", "Área actualizada", "Área actualizada con éxito.");
      dispatch(changeAreaShowComponent(0));
      dispatch(areaIdOfEdit(""));
      clearForm();
    }
  };

  const clearForm = () => {
    setDataAreaForm({
      ...dataAreaForm,
      name: "",
    });
  };

  useEffect(() => {
    const getAreaData = async () => {
      const resp: any = await getById(getArea, areaIdEdit);
      setDataAreaForm({
        ...dataAreaForm,
        name: resp.name,
      });
    };
    if (areaIdEdit !== "") {
      getAreaData();
    }
  }, [areaIdEdit]);

  return (
    <>
      <form>
        <div className="col-md-12">
          <label className="form-label">Nombre del área</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            onChange={(e) => setDataAreas(e)}
            value={name}
          />
        </div>
        <div className="d-flex pt-2 justify-content-around">
          <button type="button" className="col-5 btn btn-outline-danger">
            Limpiar formulario
          </button>
          <button
            type="button"
            className={`col-5 btn btn-outline-${
              areaIdEdit ? "warning" : "success"
            }`}
            onClick={() => {
              areaIdEdit ? updateAreas() : addArea();
            }}
          >
            {areaIdEdit ? "Actualizar" : "Agregar"}
          </button>
        </div>
      </form>
    </>
  );
}
