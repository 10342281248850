import { Auth } from "aws-amplify";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import jobnumber from "../../assets/jobnumber.png";
import wells from "../../assets/wells.png";
import styles from "../../styles/header.module.css";

export default function HeaderOperations() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState();

  const SingOut = async () => {
    await Auth.signOut();
    navigate("/login");
  };

  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        setUserName(user.attributes.email);
      }
    })();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <nav className="navbar navbar-expand-lg bg-light p-0 m-0">
            <div className="container-fluid">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className="collapse navbar-collapse py-2"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <div>
                      <h5 className="pt-2 fw-ligth text-center">{userName}</h5>
                    </div>
                  </li>
                  <li className="nav-item ps-0 ps-md-3">
                    <div>
                      <button type="button" className="col-12 btn btn-sm d-flex justify-content-center align-items-center" onClick={() => navigate('/Operations/JobNumber')}>
                        <img
                          src={wells}
                          className={`py-1 ps-1 ${styles.icon}`}
                        />
                        <p className="p-0 m-0 fs-6 fw-ligth">Job Number</p>
                      </button>
                    </div>
                  </li>
                  <li className="nav-item ps-0 ps-md-3">
                    <div>
                      <button type="button" className="col-12 btn btn-sm d-flex justify-content-center align-items-center" onClick={() => navigate('/Operations/Wells')}>
                        <img
                          src={jobnumber}
                          className={`py-1 ps-1 ${styles.icon}`}
                        />
                        <p className="p-0 m-0 fs-6 fw-ligth">Pozo</p>
                      </button>
                    </div>
                  </li>
                </ul>
                <form>
                  <button
                    className="col-12 btn btn-outline-danger"
                    type="button"
                    onClick={() => SingOut()}
                  >
                    Cerrar sesión
                  </button>
                </form>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
