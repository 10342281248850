import React from "react";
import { useLocation } from "react-router-dom";
import HeaderAdmin from "./admin/HeaderAdmin";
import HeaderOperations from "./operations/HeaderOperations";
import HeaderOperator from "./operator/HeaderOperator";

export default function Layout({ children }: any) {
  const router = useLocation();
  const { pathname } = router;
  switch (pathname) {
    case "/Admin":
      return (
        <div>
          <HeaderAdmin />
          <main>{children}</main>
        </div>
      );
    case "/Operator":
      return (
        <div>
          <HeaderOperator />
          <main>{children}</main>
        </div>
      );
    case "/Operations":
      return (
        <div>
          <HeaderOperations />
          <main>{children}</main>
        </div>
      );
    case "/login":
      return (
        <div>
          <main>{children}</main>
        </div>
      );
    default:
      return (
        <div>
          <main>{children}</main>
        </div>
      );
  }
}
