import React, { useState, useEffect } from "react";
import { listPositions } from "../../../graphql/queries";
import {
  confirmDelete,
  globalAlert,
  mutationQuery,
  queryConsult,
} from "../../../utils/commonFuntions/commonFuntions";
import AddUpdatePositions from "./AddUpdatePositions";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { BONUSTYPE } from "../../../utils/enums/enum";
import {
  changePositionShowComponent,
  positionIdOfEdit,
} from "../../../redux/features/operative";
import { deletePosition } from "../../../graphql/mutations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  listAllPosition,
  validateOperatorByPosition,
} from "../../../utils/specifictQueries";

export default function Positions() {
  const dispatch = useAppDispatch();
  const { newPosition }: any = useAppSelector((state) => state.operatives);
  const [listPosition, setListPosition] = useState([]) as [
    Array<any>,
    CallableFunction
  ];
  const listAllPositions = async () => {
    const resp: Array<any> = await queryConsult(listAllPosition);
    if (resp.length > 0) {
      resp.sort(function(a: any, b: any) {
        const nombreCompletoA = `${a?.name}`;
        const nombreCompletoB = `${b?.name}`;
        return nombreCompletoA.localeCompare(nombreCompletoB, "es", { sensitivity: "accent" });
      });
      dispatch(changePositionShowComponent(resp.length));
      setListPosition(resp);
    }
  };

  const deletePositions = async (id: any) => {
    const confirmation = await confirmDelete();
    if (confirmation) {
      const resp: Array<any> = await queryConsult(
        validateOperatorByPosition(id)
      );
      if (resp.length > 0) {
        globalAlert(
          "error",
          "Posición",
          "La posición no se puede eliminar ya que un usuario la tiene asignada."
        );
      } else {
        const resp: any = await mutationQuery(deletePosition, { id: id });
        if (resp.id && resp.id !== "") {
          globalAlert(
            "success",
            "Posición eliminada",
            "Posición eliminada con éxito."
          );
          dispatch(changePositionShowComponent(resp.length - 1));
        }
      }
    }
  };

  useEffect(() => {
    listAllPositions();
  }, [newPosition]);

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between mt-3 mx-1">
          <div className="col-md-8">
            {listPosition.length > 0 ? (
              listPosition.map((pos: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="accordion"
                    id={`accordion${index}`}
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id={`heading${index}`}>
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${index}`}
                          aria-expanded="false"
                          aria-controls={`collapse${index}`}
                        >
                          Nombre del cargo : {pos.name}
                        </button>
                      </h2>
                      <div
                        id={`collapse${index}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading${index}`}
                        data-bs-parent={`#accordion${index}`}
                      >
                        <div className="accordion-body">
                          <div className="row">
                            <div className="col-md-10">
                              <strong>Salario del cargo: {pos.salary}</strong>
                            </div>
                            <div className="col-md-2 d-flex justify-content-around">
                              <button
                                id={pos.id}
                                className="col-5 btn btn-warning btn-sm"
                                onClick={() => {
                                  dispatch(positionIdOfEdit(pos.id));
                                }}
                              >
                                <FontAwesomeIcon icon={faPen} />
                              </button>
                              <button
                                id={pos.id}
                                className="col-5 btn btn-danger btn-sm"
                                onClick={() => deletePositions(pos.id)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </div>
                          </div>
                          <div className="row pt-2">
                            {pos?.bonuses?.items?.length > 0 ? (
                              <div className="table-responsive">
                                <table className="table table-hover">
                                  <thead>
                                    <tr className="text-center table-dark">
                                      <th scope="col">Nombre</th>
                                      <th scope="col">Valor</th>
                                      <th scope="col">Tipo de bono</th>
                                      <th scope="col">¿Es porcentual?</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {pos?.bonuses?.items?.map(
                                      (bonus: any, index: number) => {
                                        const type = Object.keys(
                                          BONUSTYPE
                                        ).indexOf(
                                          bonus?.bonus
                                            ?.type as unknown as BONUSTYPE
                                        );
                                        return (
                                          <tr
                                            key={index}
                                            className="text-center"
                                          >
                                            <th>{bonus?.bonus?.name}</th>
                                            <th>{bonus?.bonus?.value}</th>
                                            <th>
                                              {Object.values(BONUSTYPE)[type]}
                                            </th>
                                            <th>
                                              {bonus?.bonus?.isPercentage
                                                ? "Es porcentual"
                                                : "Es un valor estático"}
                                            </th>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              <h4>
                                Este cargo no tiene bonos asociados por el
                                momento.
                              </h4>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <h1>No hay cargos por el momento.</h1>
            )}
          </div>
          <div className="col-md-4">
            <div>
              <AddUpdatePositions />
            </div>
            {/* <div
              className="alert alert-primary"
              role="alert"
              hidden={newPosition}
            >
              Seleccione agregar cargo
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
