import React, { useState, useEffect } from "react";
import { listJobNumbers } from "../../../graphql/queries";
import {
  globalAlert,
  queryConsult,
  mutationQuery,
  confirmDelete,
} from "../../../utils/commonFuntions/commonFuntions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AddUpdateJobNumber from "./AddUpdateJobNumber";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  countJobNumber,
  jobNumberIdOfEdit,
} from "../../../redux/features/operative";
import { useNavigate } from "react-router-dom";
import {
  listWeellByJobNUmberId,
} from "../../../utils/specifictQueries";
import { deleteJobNumber } from "../../../graphql/mutations";

export default function JobNumber() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { newJobNumber }: any = useAppSelector((state) => state.operatives);
  const [jobNumberList, setJobNumberList] = useState([]) as [
    Array<any>,
    CallableFunction
  ];

  const listAllJobNumbers = async () => {
    const resp: Array<any> = await queryConsult(listJobNumbers);
    if (resp.length > 0) {
      resp.sort(function (a, b) {
        return a?.name - b?.name;
      });
      setJobNumberList(resp);
      dispatch(countJobNumber(resp.length));
    } else {
      setJobNumberList([]);
    }
  };

  const deleteJobNumbers = async (id: string) => {
    const confirmation = await confirmDelete();
    if (confirmation) {
      const validateUse: Array<any> = await queryConsult(
        listWeellByJobNUmberId(id)
      );
      if (validateUse.length > 0) {
        globalAlert(
          "error",
          "Job number a eliminar",
          "El job number no se puede eliminar ya que esta en uso."
        );
      } else {
        const resp: any = await mutationQuery(deleteJobNumber, { id: id });
        if (resp.id && resp.id !== "") {
          listAllJobNumbers();
          globalAlert(
            "success",
            "Job number",
            "El job number fue eliminado con éxito."
          );
        }
      }
    }
  };

  useEffect(() => {
    listAllJobNumbers();
  }, [newJobNumber]);

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between mt-3 mx-1">
          <div className="row col-12 justify-content-between p-2">
            <button
              type="button"
              className="col-1 d-flex justify-content-around align-items-center btn btn-primary"
              onClick={() => navigate(`/Operations`)}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              <p className="ps-md-1 p-0 m-0">Volver</p>
            </button>
          </div>
          <div className="col-md-7">
            {jobNumberList.length > 0 ? (
              <div className="table-responsive py-3">
                <table className="table table-hover">
                  <thead>
                    <tr className="text-center table-dark">
                      <th scope="col">Job number</th>
                      <th scope="col">Nombre de la compañía</th>
                      <th scope="col">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobNumberList.map((pos: any, index: number) => {
                      return (
                        <tr key={index} className="text-center">
                          <th>{pos.name}</th>
                          <th>{pos.customerName}</th>
                          <th className="d-flex justify-content-around">
                            <button
                              id={pos.id}
                              className="col-4 btn btn-warning btn-sm"
                              onClick={() => {
                                dispatch(jobNumberIdOfEdit(pos.id));
                              }}
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>
                            <button
                              id={pos.id}
                              className="col-4 btn btn-danger btn-sm"
                              onClick={() => deleteJobNumbers(pos.id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <h1>No hay job numbers por el momento.</h1>
            )}
          </div>
          <div className="col-md-5">
            <div>
              <AddUpdateJobNumber />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
