/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPosition = /* GraphQL */ `
  mutation CreatePosition(
    $input: CreatePositionInput!
    $condition: ModelPositionConditionInput
  ) {
    createPosition(input: $input, condition: $condition) {
      id
      name
      salary
      bonuses {
        items {
          id
          positionID
          bonusID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePosition = /* GraphQL */ `
  mutation UpdatePosition(
    $input: UpdatePositionInput!
    $condition: ModelPositionConditionInput
  ) {
    updatePosition(input: $input, condition: $condition) {
      id
      name
      salary
      bonuses {
        items {
          id
          positionID
          bonusID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePosition = /* GraphQL */ `
  mutation DeletePosition(
    $input: DeletePositionInput!
    $condition: ModelPositionConditionInput
  ) {
    deletePosition(input: $input, condition: $condition) {
      id
      name
      salary
      bonuses {
        items {
          id
          positionID
          bonusID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBonus = /* GraphQL */ `
  mutation CreateBonus(
    $input: CreateBonusInput!
    $condition: ModelBonusConditionInput
  ) {
    createBonus(input: $input, condition: $condition) {
      id
      name
      type
      value
      isPercentage
      isCreated
      position {
        items {
          id
          positionID
          bonusID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBonus = /* GraphQL */ `
  mutation UpdateBonus(
    $input: UpdateBonusInput!
    $condition: ModelBonusConditionInput
  ) {
    updateBonus(input: $input, condition: $condition) {
      id
      name
      type
      value
      isPercentage
      isCreated
      position {
        items {
          id
          positionID
          bonusID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBonus = /* GraphQL */ `
  mutation DeleteBonus(
    $input: DeleteBonusInput!
    $condition: ModelBonusConditionInput
  ) {
    deleteBonus(input: $input, condition: $condition) {
      id
      name
      type
      value
      isPercentage
      isCreated
      position {
        items {
          id
          positionID
          bonusID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createArea = /* GraphQL */ `
  mutation CreateArea(
    $input: CreateAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    createArea(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateArea = /* GraphQL */ `
  mutation UpdateArea(
    $input: UpdateAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    updateArea(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteArea = /* GraphQL */ `
  mutation DeleteArea(
    $input: DeleteAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    deleteArea(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createEps = /* GraphQL */ `
  mutation CreateEps(
    $input: CreateEpsInput!
    $condition: ModelEpsConditionInput
  ) {
    createEps(input: $input, condition: $condition) {
      id
      name
      epsCode
      createdAt
      updatedAt
    }
  }
`;
export const updateEps = /* GraphQL */ `
  mutation UpdateEps(
    $input: UpdateEpsInput!
    $condition: ModelEpsConditionInput
  ) {
    updateEps(input: $input, condition: $condition) {
      id
      name
      epsCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteEps = /* GraphQL */ `
  mutation DeleteEps(
    $input: DeleteEpsInput!
    $condition: ModelEpsConditionInput
  ) {
    deleteEps(input: $input, condition: $condition) {
      id
      name
      epsCode
      createdAt
      updatedAt
    }
  }
`;
export const createAfp = /* GraphQL */ `
  mutation CreateAfp(
    $input: CreateAfpInput!
    $condition: ModelAfpConditionInput
  ) {
    createAfp(input: $input, condition: $condition) {
      id
      name
      afpCode
      createdAt
      updatedAt
    }
  }
`;
export const updateAfp = /* GraphQL */ `
  mutation UpdateAfp(
    $input: UpdateAfpInput!
    $condition: ModelAfpConditionInput
  ) {
    updateAfp(input: $input, condition: $condition) {
      id
      name
      afpCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteAfp = /* GraphQL */ `
  mutation DeleteAfp(
    $input: DeleteAfpInput!
    $condition: ModelAfpConditionInput
  ) {
    deleteAfp(input: $input, condition: $condition) {
      id
      name
      afpCode
      createdAt
      updatedAt
    }
  }
`;
export const createOperator = /* GraphQL */ `
  mutation CreateOperator(
    $input: CreateOperatorInput!
    $condition: ModelOperatorConditionInput
  ) {
    createOperator(input: $input, condition: $condition) {
      id
      docType
      docNumber
      docDateIssuance
      docPlaceIssuance
      docPlaceIssuanceCity
      name
      surname
      location
      reportTo
      contractType
      entryDate
      birthDate
      birthCity
      birthDepart
      rhesus
      gender
      title
      institution
      gradeDate
      professionalCard
      epsAffiliationDate
      afpAffiliationDate
      mainAddress
      mainNeighborhood
      mainCity
      mainDepartment
      secondaryAddress
      secondaryNeighborhood
      secondaryCity
      secondaryDepartment
      mainPhone
      secondaryPhone
      landline
      corporateEmail
      personalEmail
      arl
      cashRegisterAffiliation
      emergencyContactName
      emergencyContactPhone
      emergencyContactAddress
      status
      rol
      area {
        id
        name
        createdAt
        updatedAt
      }
      position {
        id
        name
        salary
        bonuses {
          nextToken
        }
        createdAt
        updatedAt
      }
      eps {
        id
        name
        epsCode
        createdAt
        updatedAt
      }
      afp {
        id
        name
        afpCode
        createdAt
        updatedAt
      }
      dailyWorkReports {
        items {
          id
          dateReport
          duty
          entry
          out
          createdAt
          updatedAt
          operatorDailyWorkReportsId
          dailyWorkReportWellId
        }
        nextToken
      }
      adminWorkReports {
        items {
          id
          dateReport
          duty
          entry
          out
          createdAt
          updatedAt
          operatorAdminWorkReportsId
          adminReportWellId
        }
        nextToken
      }
      timeReports {
        items {
          id
          startDate
          endDate
          fieldDays
          overBonusDays
          travelDays
          createdAt
          updatedAt
          operatorTimeReportsId
          timeReportWellId
        }
        nextToken
      }
      calculatedPayroll
      createdAt
      updatedAt
      operatorAreaId
      operatorPositionId
      operatorEpsId
      operatorAfpId
    }
  }
`;
export const updateOperator = /* GraphQL */ `
  mutation UpdateOperator(
    $input: UpdateOperatorInput!
    $condition: ModelOperatorConditionInput
  ) {
    updateOperator(input: $input, condition: $condition) {
      id
      docType
      docNumber
      docDateIssuance
      docPlaceIssuance
      docPlaceIssuanceCity
      name
      surname
      location
      reportTo
      contractType
      entryDate
      birthDate
      birthCity
      birthDepart
      rhesus
      gender
      title
      institution
      gradeDate
      professionalCard
      epsAffiliationDate
      afpAffiliationDate
      mainAddress
      mainNeighborhood
      mainCity
      mainDepartment
      secondaryAddress
      secondaryNeighborhood
      secondaryCity
      secondaryDepartment
      mainPhone
      secondaryPhone
      landline
      corporateEmail
      personalEmail
      arl
      cashRegisterAffiliation
      emergencyContactName
      emergencyContactPhone
      emergencyContactAddress
      status
      rol
      area {
        id
        name
        createdAt
        updatedAt
      }
      position {
        id
        name
        salary
        bonuses {
          nextToken
        }
        createdAt
        updatedAt
      }
      eps {
        id
        name
        epsCode
        createdAt
        updatedAt
      }
      afp {
        id
        name
        afpCode
        createdAt
        updatedAt
      }
      dailyWorkReports {
        items {
          id
          dateReport
          duty
          entry
          out
          createdAt
          updatedAt
          operatorDailyWorkReportsId
          dailyWorkReportWellId
        }
        nextToken
      }
      adminWorkReports {
        items {
          id
          dateReport
          duty
          entry
          out
          createdAt
          updatedAt
          operatorAdminWorkReportsId
          adminReportWellId
        }
        nextToken
      }
      timeReports {
        items {
          id
          startDate
          endDate
          fieldDays
          overBonusDays
          travelDays
          createdAt
          updatedAt
          operatorTimeReportsId
          timeReportWellId
        }
        nextToken
      }
      calculatedPayroll
      createdAt
      updatedAt
      operatorAreaId
      operatorPositionId
      operatorEpsId
      operatorAfpId
    }
  }
`;
export const deleteOperator = /* GraphQL */ `
  mutation DeleteOperator(
    $input: DeleteOperatorInput!
    $condition: ModelOperatorConditionInput
  ) {
    deleteOperator(input: $input, condition: $condition) {
      id
      docType
      docNumber
      docDateIssuance
      docPlaceIssuance
      docPlaceIssuanceCity
      name
      surname
      location
      reportTo
      contractType
      entryDate
      birthDate
      birthCity
      birthDepart
      rhesus
      gender
      title
      institution
      gradeDate
      professionalCard
      epsAffiliationDate
      afpAffiliationDate
      mainAddress
      mainNeighborhood
      mainCity
      mainDepartment
      secondaryAddress
      secondaryNeighborhood
      secondaryCity
      secondaryDepartment
      mainPhone
      secondaryPhone
      landline
      corporateEmail
      personalEmail
      arl
      cashRegisterAffiliation
      emergencyContactName
      emergencyContactPhone
      emergencyContactAddress
      status
      rol
      area {
        id
        name
        createdAt
        updatedAt
      }
      position {
        id
        name
        salary
        bonuses {
          nextToken
        }
        createdAt
        updatedAt
      }
      eps {
        id
        name
        epsCode
        createdAt
        updatedAt
      }
      afp {
        id
        name
        afpCode
        createdAt
        updatedAt
      }
      dailyWorkReports {
        items {
          id
          dateReport
          duty
          entry
          out
          createdAt
          updatedAt
          operatorDailyWorkReportsId
          dailyWorkReportWellId
        }
        nextToken
      }
      adminWorkReports {
        items {
          id
          dateReport
          duty
          entry
          out
          createdAt
          updatedAt
          operatorAdminWorkReportsId
          adminReportWellId
        }
        nextToken
      }
      timeReports {
        items {
          id
          startDate
          endDate
          fieldDays
          overBonusDays
          travelDays
          createdAt
          updatedAt
          operatorTimeReportsId
          timeReportWellId
        }
        nextToken
      }
      calculatedPayroll
      createdAt
      updatedAt
      operatorAreaId
      operatorPositionId
      operatorEpsId
      operatorAfpId
    }
  }
`;
export const createWell = /* GraphQL */ `
  mutation CreateWell(
    $input: CreateWellInput!
    $condition: ModelWellConditionInput
  ) {
    createWell(input: $input, condition: $condition) {
      id
      name
      jobNumber {
        id
        name
        customerName
        wells {
          nextToken
        }
        createdAt
        updatedAt
      }
      isUltrabasic
      createdAt
      updatedAt
      jobNumberWellsId
    }
  }
`;
export const updateWell = /* GraphQL */ `
  mutation UpdateWell(
    $input: UpdateWellInput!
    $condition: ModelWellConditionInput
  ) {
    updateWell(input: $input, condition: $condition) {
      id
      name
      jobNumber {
        id
        name
        customerName
        wells {
          nextToken
        }
        createdAt
        updatedAt
      }
      isUltrabasic
      createdAt
      updatedAt
      jobNumberWellsId
    }
  }
`;
export const deleteWell = /* GraphQL */ `
  mutation DeleteWell(
    $input: DeleteWellInput!
    $condition: ModelWellConditionInput
  ) {
    deleteWell(input: $input, condition: $condition) {
      id
      name
      jobNumber {
        id
        name
        customerName
        wells {
          nextToken
        }
        createdAt
        updatedAt
      }
      isUltrabasic
      createdAt
      updatedAt
      jobNumberWellsId
    }
  }
`;
export const createJobNumber = /* GraphQL */ `
  mutation CreateJobNumber(
    $input: CreateJobNumberInput!
    $condition: ModelJobNumberConditionInput
  ) {
    createJobNumber(input: $input, condition: $condition) {
      id
      name
      customerName
      wells {
        items {
          id
          name
          isUltrabasic
          createdAt
          updatedAt
          jobNumberWellsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateJobNumber = /* GraphQL */ `
  mutation UpdateJobNumber(
    $input: UpdateJobNumberInput!
    $condition: ModelJobNumberConditionInput
  ) {
    updateJobNumber(input: $input, condition: $condition) {
      id
      name
      customerName
      wells {
        items {
          id
          name
          isUltrabasic
          createdAt
          updatedAt
          jobNumberWellsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteJobNumber = /* GraphQL */ `
  mutation DeleteJobNumber(
    $input: DeleteJobNumberInput!
    $condition: ModelJobNumberConditionInput
  ) {
    deleteJobNumber(input: $input, condition: $condition) {
      id
      name
      customerName
      wells {
        items {
          id
          name
          isUltrabasic
          createdAt
          updatedAt
          jobNumberWellsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDailyWorkReport = /* GraphQL */ `
  mutation CreateDailyWorkReport(
    $input: CreateDailyWorkReportInput!
    $condition: ModelDailyWorkReportConditionInput
  ) {
    createDailyWorkReport(input: $input, condition: $condition) {
      id
      dateReport
      duty
      well {
        id
        name
        jobNumber {
          id
          name
          customerName
          createdAt
          updatedAt
        }
        isUltrabasic
        createdAt
        updatedAt
        jobNumberWellsId
      }
      operator {
        id
        docType
        docNumber
        docDateIssuance
        docPlaceIssuance
        docPlaceIssuanceCity
        name
        surname
        location
        reportTo
        contractType
        entryDate
        birthDate
        birthCity
        birthDepart
        rhesus
        gender
        title
        institution
        gradeDate
        professionalCard
        epsAffiliationDate
        afpAffiliationDate
        mainAddress
        mainNeighborhood
        mainCity
        mainDepartment
        secondaryAddress
        secondaryNeighborhood
        secondaryCity
        secondaryDepartment
        mainPhone
        secondaryPhone
        landline
        corporateEmail
        personalEmail
        arl
        cashRegisterAffiliation
        emergencyContactName
        emergencyContactPhone
        emergencyContactAddress
        status
        rol
        area {
          id
          name
          createdAt
          updatedAt
        }
        position {
          id
          name
          salary
          createdAt
          updatedAt
        }
        eps {
          id
          name
          epsCode
          createdAt
          updatedAt
        }
        afp {
          id
          name
          afpCode
          createdAt
          updatedAt
        }
        dailyWorkReports {
          nextToken
        }
        adminWorkReports {
          nextToken
        }
        timeReports {
          nextToken
        }
        calculatedPayroll
        createdAt
        updatedAt
        operatorAreaId
        operatorPositionId
        operatorEpsId
        operatorAfpId
      }
      entry
      out
      createdAt
      updatedAt
      operatorDailyWorkReportsId
      dailyWorkReportWellId
    }
  }
`;
export const updateDailyWorkReport = /* GraphQL */ `
  mutation UpdateDailyWorkReport(
    $input: UpdateDailyWorkReportInput!
    $condition: ModelDailyWorkReportConditionInput
  ) {
    updateDailyWorkReport(input: $input, condition: $condition) {
      id
      dateReport
      duty
      well {
        id
        name
        jobNumber {
          id
          name
          customerName
          createdAt
          updatedAt
        }
        isUltrabasic
        createdAt
        updatedAt
        jobNumberWellsId
      }
      operator {
        id
        docType
        docNumber
        docDateIssuance
        docPlaceIssuance
        docPlaceIssuanceCity
        name
        surname
        location
        reportTo
        contractType
        entryDate
        birthDate
        birthCity
        birthDepart
        rhesus
        gender
        title
        institution
        gradeDate
        professionalCard
        epsAffiliationDate
        afpAffiliationDate
        mainAddress
        mainNeighborhood
        mainCity
        mainDepartment
        secondaryAddress
        secondaryNeighborhood
        secondaryCity
        secondaryDepartment
        mainPhone
        secondaryPhone
        landline
        corporateEmail
        personalEmail
        arl
        cashRegisterAffiliation
        emergencyContactName
        emergencyContactPhone
        emergencyContactAddress
        status
        rol
        area {
          id
          name
          createdAt
          updatedAt
        }
        position {
          id
          name
          salary
          createdAt
          updatedAt
        }
        eps {
          id
          name
          epsCode
          createdAt
          updatedAt
        }
        afp {
          id
          name
          afpCode
          createdAt
          updatedAt
        }
        dailyWorkReports {
          nextToken
        }
        adminWorkReports {
          nextToken
        }
        timeReports {
          nextToken
        }
        calculatedPayroll
        createdAt
        updatedAt
        operatorAreaId
        operatorPositionId
        operatorEpsId
        operatorAfpId
      }
      entry
      out
      createdAt
      updatedAt
      operatorDailyWorkReportsId
      dailyWorkReportWellId
    }
  }
`;
export const deleteDailyWorkReport = /* GraphQL */ `
  mutation DeleteDailyWorkReport(
    $input: DeleteDailyWorkReportInput!
    $condition: ModelDailyWorkReportConditionInput
  ) {
    deleteDailyWorkReport(input: $input, condition: $condition) {
      id
      dateReport
      duty
      well {
        id
        name
        jobNumber {
          id
          name
          customerName
          createdAt
          updatedAt
        }
        isUltrabasic
        createdAt
        updatedAt
        jobNumberWellsId
      }
      operator {
        id
        docType
        docNumber
        docDateIssuance
        docPlaceIssuance
        docPlaceIssuanceCity
        name
        surname
        location
        reportTo
        contractType
        entryDate
        birthDate
        birthCity
        birthDepart
        rhesus
        gender
        title
        institution
        gradeDate
        professionalCard
        epsAffiliationDate
        afpAffiliationDate
        mainAddress
        mainNeighborhood
        mainCity
        mainDepartment
        secondaryAddress
        secondaryNeighborhood
        secondaryCity
        secondaryDepartment
        mainPhone
        secondaryPhone
        landline
        corporateEmail
        personalEmail
        arl
        cashRegisterAffiliation
        emergencyContactName
        emergencyContactPhone
        emergencyContactAddress
        status
        rol
        area {
          id
          name
          createdAt
          updatedAt
        }
        position {
          id
          name
          salary
          createdAt
          updatedAt
        }
        eps {
          id
          name
          epsCode
          createdAt
          updatedAt
        }
        afp {
          id
          name
          afpCode
          createdAt
          updatedAt
        }
        dailyWorkReports {
          nextToken
        }
        adminWorkReports {
          nextToken
        }
        timeReports {
          nextToken
        }
        calculatedPayroll
        createdAt
        updatedAt
        operatorAreaId
        operatorPositionId
        operatorEpsId
        operatorAfpId
      }
      entry
      out
      createdAt
      updatedAt
      operatorDailyWorkReportsId
      dailyWorkReportWellId
    }
  }
`;
export const createAdminReport = /* GraphQL */ `
  mutation CreateAdminReport(
    $input: CreateAdminReportInput!
    $condition: ModelAdminReportConditionInput
  ) {
    createAdminReport(input: $input, condition: $condition) {
      id
      dateReport
      duty
      well {
        id
        name
        jobNumber {
          id
          name
          customerName
          createdAt
          updatedAt
        }
        isUltrabasic
        createdAt
        updatedAt
        jobNumberWellsId
      }
      operator {
        id
        docType
        docNumber
        docDateIssuance
        docPlaceIssuance
        docPlaceIssuanceCity
        name
        surname
        location
        reportTo
        contractType
        entryDate
        birthDate
        birthCity
        birthDepart
        rhesus
        gender
        title
        institution
        gradeDate
        professionalCard
        epsAffiliationDate
        afpAffiliationDate
        mainAddress
        mainNeighborhood
        mainCity
        mainDepartment
        secondaryAddress
        secondaryNeighborhood
        secondaryCity
        secondaryDepartment
        mainPhone
        secondaryPhone
        landline
        corporateEmail
        personalEmail
        arl
        cashRegisterAffiliation
        emergencyContactName
        emergencyContactPhone
        emergencyContactAddress
        status
        rol
        area {
          id
          name
          createdAt
          updatedAt
        }
        position {
          id
          name
          salary
          createdAt
          updatedAt
        }
        eps {
          id
          name
          epsCode
          createdAt
          updatedAt
        }
        afp {
          id
          name
          afpCode
          createdAt
          updatedAt
        }
        dailyWorkReports {
          nextToken
        }
        adminWorkReports {
          nextToken
        }
        timeReports {
          nextToken
        }
        calculatedPayroll
        createdAt
        updatedAt
        operatorAreaId
        operatorPositionId
        operatorEpsId
        operatorAfpId
      }
      entry
      out
      createdAt
      updatedAt
      operatorAdminWorkReportsId
      adminReportWellId
    }
  }
`;
export const updateAdminReport = /* GraphQL */ `
  mutation UpdateAdminReport(
    $input: UpdateAdminReportInput!
    $condition: ModelAdminReportConditionInput
  ) {
    updateAdminReport(input: $input, condition: $condition) {
      id
      dateReport
      duty
      well {
        id
        name
        jobNumber {
          id
          name
          customerName
          createdAt
          updatedAt
        }
        isUltrabasic
        createdAt
        updatedAt
        jobNumberWellsId
      }
      operator {
        id
        docType
        docNumber
        docDateIssuance
        docPlaceIssuance
        docPlaceIssuanceCity
        name
        surname
        location
        reportTo
        contractType
        entryDate
        birthDate
        birthCity
        birthDepart
        rhesus
        gender
        title
        institution
        gradeDate
        professionalCard
        epsAffiliationDate
        afpAffiliationDate
        mainAddress
        mainNeighborhood
        mainCity
        mainDepartment
        secondaryAddress
        secondaryNeighborhood
        secondaryCity
        secondaryDepartment
        mainPhone
        secondaryPhone
        landline
        corporateEmail
        personalEmail
        arl
        cashRegisterAffiliation
        emergencyContactName
        emergencyContactPhone
        emergencyContactAddress
        status
        rol
        area {
          id
          name
          createdAt
          updatedAt
        }
        position {
          id
          name
          salary
          createdAt
          updatedAt
        }
        eps {
          id
          name
          epsCode
          createdAt
          updatedAt
        }
        afp {
          id
          name
          afpCode
          createdAt
          updatedAt
        }
        dailyWorkReports {
          nextToken
        }
        adminWorkReports {
          nextToken
        }
        timeReports {
          nextToken
        }
        calculatedPayroll
        createdAt
        updatedAt
        operatorAreaId
        operatorPositionId
        operatorEpsId
        operatorAfpId
      }
      entry
      out
      createdAt
      updatedAt
      operatorAdminWorkReportsId
      adminReportWellId
    }
  }
`;
export const deleteAdminReport = /* GraphQL */ `
  mutation DeleteAdminReport(
    $input: DeleteAdminReportInput!
    $condition: ModelAdminReportConditionInput
  ) {
    deleteAdminReport(input: $input, condition: $condition) {
      id
      dateReport
      duty
      well {
        id
        name
        jobNumber {
          id
          name
          customerName
          createdAt
          updatedAt
        }
        isUltrabasic
        createdAt
        updatedAt
        jobNumberWellsId
      }
      operator {
        id
        docType
        docNumber
        docDateIssuance
        docPlaceIssuance
        docPlaceIssuanceCity
        name
        surname
        location
        reportTo
        contractType
        entryDate
        birthDate
        birthCity
        birthDepart
        rhesus
        gender
        title
        institution
        gradeDate
        professionalCard
        epsAffiliationDate
        afpAffiliationDate
        mainAddress
        mainNeighborhood
        mainCity
        mainDepartment
        secondaryAddress
        secondaryNeighborhood
        secondaryCity
        secondaryDepartment
        mainPhone
        secondaryPhone
        landline
        corporateEmail
        personalEmail
        arl
        cashRegisterAffiliation
        emergencyContactName
        emergencyContactPhone
        emergencyContactAddress
        status
        rol
        area {
          id
          name
          createdAt
          updatedAt
        }
        position {
          id
          name
          salary
          createdAt
          updatedAt
        }
        eps {
          id
          name
          epsCode
          createdAt
          updatedAt
        }
        afp {
          id
          name
          afpCode
          createdAt
          updatedAt
        }
        dailyWorkReports {
          nextToken
        }
        adminWorkReports {
          nextToken
        }
        timeReports {
          nextToken
        }
        calculatedPayroll
        createdAt
        updatedAt
        operatorAreaId
        operatorPositionId
        operatorEpsId
        operatorAfpId
      }
      entry
      out
      createdAt
      updatedAt
      operatorAdminWorkReportsId
      adminReportWellId
    }
  }
`;
export const createTimeReport = /* GraphQL */ `
  mutation CreateTimeReport(
    $input: CreateTimeReportInput!
    $condition: ModelTimeReportConditionInput
  ) {
    createTimeReport(input: $input, condition: $condition) {
      id
      operator {
        id
        docType
        docNumber
        docDateIssuance
        docPlaceIssuance
        docPlaceIssuanceCity
        name
        surname
        location
        reportTo
        contractType
        entryDate
        birthDate
        birthCity
        birthDepart
        rhesus
        gender
        title
        institution
        gradeDate
        professionalCard
        epsAffiliationDate
        afpAffiliationDate
        mainAddress
        mainNeighborhood
        mainCity
        mainDepartment
        secondaryAddress
        secondaryNeighborhood
        secondaryCity
        secondaryDepartment
        mainPhone
        secondaryPhone
        landline
        corporateEmail
        personalEmail
        arl
        cashRegisterAffiliation
        emergencyContactName
        emergencyContactPhone
        emergencyContactAddress
        status
        rol
        area {
          id
          name
          createdAt
          updatedAt
        }
        position {
          id
          name
          salary
          createdAt
          updatedAt
        }
        eps {
          id
          name
          epsCode
          createdAt
          updatedAt
        }
        afp {
          id
          name
          afpCode
          createdAt
          updatedAt
        }
        dailyWorkReports {
          nextToken
        }
        adminWorkReports {
          nextToken
        }
        timeReports {
          nextToken
        }
        calculatedPayroll
        createdAt
        updatedAt
        operatorAreaId
        operatorPositionId
        operatorEpsId
        operatorAfpId
      }
      startDate
      endDate
      well {
        id
        name
        jobNumber {
          id
          name
          customerName
          createdAt
          updatedAt
        }
        isUltrabasic
        createdAt
        updatedAt
        jobNumberWellsId
      }
      fieldDays
      overBonusDays
      travelDays
      calculatedBonus {
        id
        name
        calculated
      }
      createdAt
      updatedAt
      operatorTimeReportsId
      timeReportWellId
    }
  }
`;
export const updateTimeReport = /* GraphQL */ `
  mutation UpdateTimeReport(
    $input: UpdateTimeReportInput!
    $condition: ModelTimeReportConditionInput
  ) {
    updateTimeReport(input: $input, condition: $condition) {
      id
      operator {
        id
        docType
        docNumber
        docDateIssuance
        docPlaceIssuance
        docPlaceIssuanceCity
        name
        surname
        location
        reportTo
        contractType
        entryDate
        birthDate
        birthCity
        birthDepart
        rhesus
        gender
        title
        institution
        gradeDate
        professionalCard
        epsAffiliationDate
        afpAffiliationDate
        mainAddress
        mainNeighborhood
        mainCity
        mainDepartment
        secondaryAddress
        secondaryNeighborhood
        secondaryCity
        secondaryDepartment
        mainPhone
        secondaryPhone
        landline
        corporateEmail
        personalEmail
        arl
        cashRegisterAffiliation
        emergencyContactName
        emergencyContactPhone
        emergencyContactAddress
        status
        rol
        area {
          id
          name
          createdAt
          updatedAt
        }
        position {
          id
          name
          salary
          createdAt
          updatedAt
        }
        eps {
          id
          name
          epsCode
          createdAt
          updatedAt
        }
        afp {
          id
          name
          afpCode
          createdAt
          updatedAt
        }
        dailyWorkReports {
          nextToken
        }
        adminWorkReports {
          nextToken
        }
        timeReports {
          nextToken
        }
        calculatedPayroll
        createdAt
        updatedAt
        operatorAreaId
        operatorPositionId
        operatorEpsId
        operatorAfpId
      }
      startDate
      endDate
      well {
        id
        name
        jobNumber {
          id
          name
          customerName
          createdAt
          updatedAt
        }
        isUltrabasic
        createdAt
        updatedAt
        jobNumberWellsId
      }
      fieldDays
      overBonusDays
      travelDays
      calculatedBonus {
        id
        name
        calculated
      }
      createdAt
      updatedAt
      operatorTimeReportsId
      timeReportWellId
    }
  }
`;
export const deleteTimeReport = /* GraphQL */ `
  mutation DeleteTimeReport(
    $input: DeleteTimeReportInput!
    $condition: ModelTimeReportConditionInput
  ) {
    deleteTimeReport(input: $input, condition: $condition) {
      id
      operator {
        id
        docType
        docNumber
        docDateIssuance
        docPlaceIssuance
        docPlaceIssuanceCity
        name
        surname
        location
        reportTo
        contractType
        entryDate
        birthDate
        birthCity
        birthDepart
        rhesus
        gender
        title
        institution
        gradeDate
        professionalCard
        epsAffiliationDate
        afpAffiliationDate
        mainAddress
        mainNeighborhood
        mainCity
        mainDepartment
        secondaryAddress
        secondaryNeighborhood
        secondaryCity
        secondaryDepartment
        mainPhone
        secondaryPhone
        landline
        corporateEmail
        personalEmail
        arl
        cashRegisterAffiliation
        emergencyContactName
        emergencyContactPhone
        emergencyContactAddress
        status
        rol
        area {
          id
          name
          createdAt
          updatedAt
        }
        position {
          id
          name
          salary
          createdAt
          updatedAt
        }
        eps {
          id
          name
          epsCode
          createdAt
          updatedAt
        }
        afp {
          id
          name
          afpCode
          createdAt
          updatedAt
        }
        dailyWorkReports {
          nextToken
        }
        adminWorkReports {
          nextToken
        }
        timeReports {
          nextToken
        }
        calculatedPayroll
        createdAt
        updatedAt
        operatorAreaId
        operatorPositionId
        operatorEpsId
        operatorAfpId
      }
      startDate
      endDate
      well {
        id
        name
        jobNumber {
          id
          name
          customerName
          createdAt
          updatedAt
        }
        isUltrabasic
        createdAt
        updatedAt
        jobNumberWellsId
      }
      fieldDays
      overBonusDays
      travelDays
      calculatedBonus {
        id
        name
        calculated
      }
      createdAt
      updatedAt
      operatorTimeReportsId
      timeReportWellId
    }
  }
`;
export const createPositionBonus = /* GraphQL */ `
  mutation CreatePositionBonus(
    $input: CreatePositionBonusInput!
    $condition: ModelPositionBonusConditionInput
  ) {
    createPositionBonus(input: $input, condition: $condition) {
      id
      positionID
      bonusID
      position {
        id
        name
        salary
        bonuses {
          nextToken
        }
        createdAt
        updatedAt
      }
      bonus {
        id
        name
        type
        value
        isPercentage
        isCreated
        position {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePositionBonus = /* GraphQL */ `
  mutation UpdatePositionBonus(
    $input: UpdatePositionBonusInput!
    $condition: ModelPositionBonusConditionInput
  ) {
    updatePositionBonus(input: $input, condition: $condition) {
      id
      positionID
      bonusID
      position {
        id
        name
        salary
        bonuses {
          nextToken
        }
        createdAt
        updatedAt
      }
      bonus {
        id
        name
        type
        value
        isPercentage
        isCreated
        position {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePositionBonus = /* GraphQL */ `
  mutation DeletePositionBonus(
    $input: DeletePositionBonusInput!
    $condition: ModelPositionBonusConditionInput
  ) {
    deletePositionBonus(input: $input, condition: $condition) {
      id
      positionID
      bonusID
      position {
        id
        name
        salary
        bonuses {
          nextToken
        }
        createdAt
        updatedAt
      }
      bonus {
        id
        name
        type
        value
        isPercentage
        isCreated
        position {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
