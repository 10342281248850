import React, { useEffect, useState } from "react";
import { deleteArea } from "../../../graphql/mutations";
import { listAreas } from "../../../graphql/queries";
import {
  confirmDelete,
  globalAlert,
  mutationQuery,
  queryConsult,
} from "../../../utils/commonFuntions/commonFuntions";
import AddUpdateArea from "./AddUpdateArea";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  areaIdOfEdit,
  changeAreaShowComponent,
} from "../../../redux/features/operative";
import { listOperatorByAreaId } from "../../../utils/specifictQueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

export default function Area() {
  const dispatch = useAppDispatch();
  const { newArea }: any = useAppSelector((state) => state.operatives);
  const [listArea, setListAreas] = useState([]) as [
    Array<any>,
    CallableFunction
  ];
  const listAllAreas = async () => {
    const resp: Array<any> = await queryConsult(listAreas);
    if (resp.length > 0) {
      resp.sort(function (a, b) {
        return b?.name?.length - a?.name?.length;
      });
      setListAreas(resp);
      dispatch(changeAreaShowComponent(resp.length));
    }
  };

  const deleteAreas = async (id: string) => {
    const confirmation = await confirmDelete();
    if (confirmation) {
      const validateUse: Array<any> = await queryConsult(
        listOperatorByAreaId(id)
      );
      if (validateUse.length > 0) {
        globalAlert(
          "error",
          "Área a eliminar",
          "El área no se puede eliminar ya que esta en uso."
        );
      } else {
        const resp: any = await mutationQuery(deleteArea, { id: id });
        if (resp.id && resp.id !== "") {
          globalAlert("success", "Área eliminada", "Área eliminada con éxito.");
          dispatch(changeAreaShowComponent(newArea - 1));
        }
      }
    }
  };

  useEffect(() => {
    listAllAreas();
  }, [newArea]);

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between mt-3 mx-1">
          <div className="col-md-8">
            {listArea.length > 0 ? (
              <div className="table-responsive py-3">
                <table className="table table-hover">
                  <thead>
                    <tr className="text-center table-dark">
                      <th scope="col">Nombre</th>
                      <th scope="col">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listArea.map((pos: any, index: number) => {
                      return (
                        <tr key={index} className="text-center">
                          <th>{pos.name}</th>
                          <th className="d-flex justify-content-around">
                            <button
                              id={pos.id}
                              className="col-4 btn btn-warning btn-sm"
                              onClick={() => {
                                dispatch(areaIdOfEdit(pos.id));
                              }}
                            >
                              <FontAwesomeIcon icon={faPen} />
                            </button>
                            <button
                              id={pos.id}
                              className="col-4 btn btn-danger btn-sm"
                              onClick={() => deleteAreas(pos.id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <h1>No hay áreas por el momento.</h1>
            )}
          </div>
          <div className="col-md-4">
            <div>
              <AddUpdateArea />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
