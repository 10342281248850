import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { globalAlert, queryConsult, currentDateFormatYMD } from '../../utils/commonFuntions/commonFuntions';
import { getIdByEmail } from "../../utils/specifictQueries";
import fetchApi from "../../lambda";
import styles from "../../styles/reports.module.css";
import { MONTHS } from "../../utils/enums/enum";

export default function Reports() {
  const [userName, setUserName] = useState();
  const [id, setID] = useState();
  const currentDay = parseInt(currentDateFormatYMD(new Date).substring(8,10));
  const currentMonth = parseInt(currentDateFormatYMD(new Date).substring(5,7));
  const [spinner, setSpinner] = useState(false);
  const [year, setYear] = useState("Seleccione un año");
  const [month, setMonth] = useState("Seleccione un mes");
  const [monthPosition, setMonthPosition] = useState(-1);
  const [years, setYears] = useState([]) as [Array<any>, CallableFunction];

  const generateArrayOfYears = () => {
    const yearCreation = new Date("11-12-2022");
    const getYear = yearCreation.getFullYear();
    const currentDate = new Date();
    const getCurrentYear = currentDate.getFullYear();
    const year = Array();
    for (let i = getYear; i <= getCurrentYear; i++) year.push(i);
    if (currentDay >= 26 && currentMonth === 12) year.push(getCurrentYear+1)
    return setYears(year);
  };

  const getReport = async () => {
    try {
      setSpinner(true);
      const token = await Auth.currentSession().then((data) =>
        data.getIdToken().getJwtToken()
      );
      const resp: any = await fetchApi(
        `payrollExport/${id}?year=${year}&range=${monthPosition}&role=Operator`,
        "GET",
        token
      );
      if (resp?.data?.fileUrl) {
        window.open(resp?.data?.fileUrl, "_self", "noopener,noreferrer");
        setSpinner(false);
      } else {
        globalAlert("error", "Reporte", `${resp?.data?.message}`)
        setSpinner(false);
      }
    } catch (error: any) {
      console.error("error: ", error);
      if(error?.response?.data?.message) {
        globalAlert("error", "Excel", `${error?.response?.data?.message}`)
      } else {
        globalAlert("error", "Excel", `${error?.message}`)
      }
      setSpinner(false);
    }
  };

  useEffect(() => {
    generateArrayOfYears();
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        setUserName(user.attributes.email);
        const resp: any = await queryConsult(getIdByEmail(user.attributes.email));
        setID(resp[0]?.id);
      }
    })();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <h1 className="text-center">Lista de reportes</h1>
      </div>
      <div className="row justify-content-around pt-2">
        <div className="col-md-3">
          <label className="form-label d-flex justify-content-start p-0 m-0">
            Mes
          </label>
          <div className="col-md-12 btn-group">
            <button
              type="button"
              className="btn btn-outline-primary dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              value={month}
            >
              {month}
            </button>
            <ul className={`col-md-12 dropdown-menu ${styles.dropdownContainer}`}>
              {Object.keys(MONTHS).map((pos: any, positionIndex: number) => {
                return (
                  <li key={positionIndex}>
                    <button
                      id="position"
                      name="position"
                      type="button"
                      className="col-md-12 btn btn-light"
                      value={pos}
                      onClick={() => {
                        setMonth(pos);
                        setMonthPosition(positionIndex + 1);
                      }}
                    >
                      {pos}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="col-md-3">
          <label className="form-label d-flex justify-content-start p-0 m-0">
            Año
          </label>
          <div className="col-md-12 btn-group">
            <button
              type="button"
              className="btn btn-outline-primary dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              value={year}
            >
              {year}
            </button>
            <ul className={`col-md-12 dropdown-menu ${styles.dropdownContainer}`}>
              {years.map((pos: any, positionIndex: number) => {
                return (
                  <li key={positionIndex}>
                    <button
                      id="position"
                      name="position"
                      type="button"
                      className="col-md-12 btn btn-light"
                      value={pos}
                      onClick={() => setYear(pos)}
                    >
                      {pos}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="col-12 col-md-3 pt-4">
          <button
            type="button"
            className="btn btn-success"
            disabled={
              year == "Seleccione un año" || month == "Seleccione un mes"
            }
            onClick={() => getReport()}
          >
            {spinner ? (
              <div className="spinner-border text-light" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              "Generar reporte"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
