import React, { useState, useEffect } from "react";
import {
  faPlusCircle,
  faNewspaper,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  queryConsult,
  globalAlert,
} from "../../utils/commonFuntions/commonFuntions";
import { listOperatorByRol } from "../../utils/specifictQueries";
import fetchApi from "../../lambda";
import { Auth } from "aws-amplify";
import styles from "../../styles/common.module.css";

export default function Operations() {
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [allOperator, setAllOperatos] = useState([]) as [
    Array<any>,
    CallableFunction
  ];

  const getAllOperatos = async () => {
    const resp: Array<any> = await queryConsult(listOperatorByRol);
    if (resp.length > 0) {
      resp.sort(function(a: any, b: any) {
        const nombreCompletoA = `${a?.surname} ${a?.name}`;
        const nombreCompletoB = `${b?.surname} ${b?.name}`;
        return nombreCompletoA.localeCompare(nombreCompletoB, "es", { sensitivity: "accent" });
      });
      setAllOperatos(resp);
    }
  };

  const calculateNominee = async (id: string) => {
    try {
      const token = await Auth.currentSession().then((data) =>
        data.getIdToken().getJwtToken()
      );
      const resp: any = await fetchApi(
        `payrollCalculation/${id}`,
        "GET",
        token
      );
      if (resp?.status) {
        globalAlert("success", "Nómina", "Nómina calculada con éxito.");
        setId("");
      }
    } catch (error: any) {
      console.error("ERRROR: ", error);
      globalAlert("error", "Nómina", `${error?.response?.data?.message}`);
      setId("");
    }
  };

  useEffect(() => {
    getAllOperatos();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row justify-content-center mt-3">
        <div className="col-md-12">
          {allOperator.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr className="text-center table-dark">
                    <th scope="col">Identificación</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Cargo</th>
                    <th scope="col">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {allOperator.map((pos: any, index: number) => {
                    return (
                      <tr key={index} className="text-center">
                        <th className={`pt-md-3 ${styles.tbody}`}>
                          {pos.docNumber}
                        </th>
                        <th className={`pt-md-3 ${styles.tbody}`}>
                        {pos.surname} {pos.name}
                        </th>
                        <th className={`pt-md-3 ${styles.tbody}`}>
                          {pos?.position?.name}
                        </th>
                        <th className="d-flex justify-content-around">
                          <div className="col-12 col-md-4">
                            <button
                              id={pos.id}
                              type="button"
                              className="col-12 col-md-11 d-flex justify-content-center align-items-center btn btn-outline-success"
                              onClick={() => navigate(`/Operations/${pos.id}`)}
                            >
                              <FontAwesomeIcon icon={faPlusCircle} />
                              <p className={`ps-md-1 p-0 m-0 ${styles.tbody}`}>
                                Añadir reporte
                              </p>
                            </button>
                          </div>
                          <div className="col-12 col-md-4">
                            <button
                              id={pos.id}
                              type="button"
                              className="col-12 col-md-11 d-flex justify-content-center align-items-center btn btn-outline-success"
                              onClick={() =>
                                navigate(`/Operations/report/${pos.id}`)
                              }
                            >
                              <FontAwesomeIcon icon={faList} />
                              <p className={`ps-md-1 p-0 m-0 ${styles.tbody}`}>
                                Seleccionar reporte
                              </p>
                            </button>
                          </div>
                          <div className="col-12 col-md-4">
                            <button
                              id={pos.id}
                              type="button"
                              onClick={() => {
                                setId(pos.id);
                                calculateNominee(pos.id);
                              }}
                              className="col-12 col-md-11 d-flex justify-content-center align-items-center btn btn-outline-success"
                            >
                              {pos?.id === id ? (
                                <div>
                                  <span
                                    className={`spinner-border spinner-border-sm ${styles.tbody}`}
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  Calculando...
                                </div>
                              ) : (
                                <>
                                  <FontAwesomeIcon icon={faNewspaper} />
                                  <p
                                    className={`ps-md-1 p-0 m-0 ${styles.tbody}`}
                                  >
                                    {`${
                                      pos?.calculatedPayroll
                                        ? "Re calcular"
                                        : "Calcular"
                                    } nómina`}
                                  </p>
                                </>
                              )}
                            </button>
                          </div>
                        </th>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <h1>No hay trabajadores por el momento.</h1>
          )}
        </div>
      </div>
    </div>
  );
}
