import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface operative {
  email: string;
  isAuth: boolean;
  adminComponent: string;
  userShowComponent: boolean;
  newBonus: boolean;
  newPosition: number;
  newArea: number;
  newJobNumber: number;
  newWell: number;
  userIdEdit: string;
  bonusIdEdit: string;
  positionIdEdit: string;
  areaIdEdit: string;
  jobNumberIdEdit: string;
  wellIdEdit: string;
  listBonusState: Array<any>;
  bonusArrayValidate: Array<any>;
}

const initPref: operative = {
  email: "",
  isAuth: false,
  adminComponent: "users",
  userShowComponent: true,
  newBonus: false,
  newPosition: 0,
  newArea: 0,
  newJobNumber: 0,
  newWell: 0,
  userIdEdit: "",
  bonusIdEdit: "",
  positionIdEdit: "",
  areaIdEdit: "",
  jobNumberIdEdit: "",
  wellIdEdit: "",
  listBonusState: [],
  bonusArrayValidate: [],
};

const operativeSlice: any = createSlice({
  name: "operatives",
  initialState: initPref,
  reducers: {
    getName: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    changeAuthStatus: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    getComponentNameAdmin: (state, action: PayloadAction<string>) => {
      state.adminComponent = action.payload;
    },
    changeUserShowComponent: (state, action: PayloadAction<boolean>) => {
      state.userShowComponent = action.payload;
    },
    changeBonusShowComponent: (state, action: PayloadAction<boolean>) => {
      state.newBonus = action.payload;
    },
    changePositionShowComponent: (state, action: PayloadAction<number>) => {
      state.newPosition = action.payload;
    },
    changeAreaShowComponent: (state, action: PayloadAction<number>) => {
      state.newArea = action.payload;
    },
    userIdOfEdit: (state, action: PayloadAction<string>) => {
      state.userIdEdit = action.payload;
    },
    bonusIdOfEdit: (state, action: PayloadAction<string>) => {
      state.bonusIdEdit = action.payload;
    },
    jobNumberIdOfEdit: (state, action: PayloadAction<string>) => {
      state.jobNumberIdEdit = action.payload;
    },
    wellIdOfEdit: (state, action: PayloadAction<string>) => {
      state.wellIdEdit = action.payload;
    },
    positionIdOfEdit: (state, action: PayloadAction<string>) => {
      state.positionIdEdit = action.payload;
    },
    areaIdOfEdit: (state, action: PayloadAction<string>) => {
      state.areaIdEdit = action.payload;
    },
    getListBonus: (state, action: PayloadAction<Array<any>>) => {
      state.listBonusState = action.payload;
    },
    getBonusArray: (state, action: PayloadAction<Array<any>>) => {
      state.bonusArrayValidate = action.payload;
    },
    countJobNumber: (state, action: PayloadAction<number>) => {
      state.newJobNumber = action.payload;
    },
    countWell: (state, action: PayloadAction<number>) => {
      state.newWell = action.payload;
    },
  },
});

export const {
  getName,
  changeAuthStatus,
  getComponentNameAdmin,
  changeUserShowComponent,
  changePositionShowComponent,
  changeBonusShowComponent,
  changeAreaShowComponent,
  userIdOfEdit,
  bonusIdOfEdit,
  jobNumberIdOfEdit,
  positionIdOfEdit,
  areaIdOfEdit,
  wellIdOfEdit,
  getListBonus,
  getBonusArray,
  countJobNumber,
  countWell
} = operativeSlice.actions;
export default operativeSlice.reducer;
